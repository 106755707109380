.modal{
    top: 30%; 
}  
@media (max-width: 767px){ 
    .modal{
        top: 20%; 
    }   
}
.modal-body{
    padding: 0 7%;
}
.intro {
    .modal {
        width: 50% !important;
        left: 25% !important;
    }
} 
.first-login{
    h5{
        font-weight: 600;
        font-size: 14px;
    }
    @media (max-width: 767px){
        p{
            font-size: 11px;
        }
        .sign-up{
            font-size: 10px;
            padding: 6px 18px;
        }
    }
}
.display-inline-md{
    @media (min-width: 768px){
       display: inline-block !important; 
    }
}
.max-width-250{
    @media(max-width: 767px){
        max-width: 250px;
    }
}
.modal-first-login{
    @media (min-width: 768px){
        left: 5% !important;
        width: 90% !important;
    }
    @media (min-width: 992px){
        left: 10% !important;
        width: 80% !important;
    }
    @media (min-width: 1140px){
        left: 20% !important;
        width: 60% !important;
    }
}

