.AdvancedFiltersMobile{
    .reduse-padding{
        @media (max-width: 375px) {
            padding: 0 5px; 
        }
    }
    .grey-text{
        font-size: 10px;
        color: #8f939d;
        line-height: 1.8;
        margin-left: 5px;
    }
    
    .search-filer-block{
        justify-content: center;
        align-items: center;
        border-right: 1px solid #e2e6ee;
        .search-text{
            margin-left: 10px;
        }
    }
    .margin-horizontal{
        margin-top: auto;
        margin-bottom: auto;
    }
    .width-100{
        width: 100%
    }
    .filer-name{
        font-size: 14px;
    }
    .side-padding-15{
        padding-left: 15px;
        padding-right:15px;
    }
    .text-right{
        text-align: end;
    }

    .hide{
        display:none;
    }

    .add-line{
        .DateRangePickerInput_arrow{
            display: block;
        }
    }

    .tag {
        margin-bottom: 5px;
    }

    .DateInput__small{
        min-width: 150px;
    }
    .CustomCheckBox{
        margin-right: 12px;
    }
    .CheckBoxSelect{
        .CustomCheckBox{
            margin-right: 16px;
        }
    }
    .top-panel{
        display: flex;
        position: relative;
        height: 121px;
        margin-bottom: 32px;
        background-color: #1161be;
        background-image: url('/images/events-search-pattern.svg');
        align-items: center;
    }    
    .search-box {
        border-radius: 4px;
        box-shadow: 0 5px 10px 0 rgba(9, 52, 103, 0.13);
        height: 40px;
        .input-group {
            position: relative;
            .search-icon{
                width: 100%;
            }            
            input[type=search] {
                border: none;
                width: 100%;
                border-radius: 0.25rem;
                height: 40px;
                margin-left: 0px;
                padding-left: 40px;
                padding-right: 140px;
            }
            .input-group-prepend {
                position: absolute;
                right: 0px;
                margin: auto;
                z-index:9;
                .btn{
                    border-radius: 0.25rem;
                }
            }
        }        
        
        .search {
            color: white;
            border-radius: 4px;
            background-color: #1b7beb;
            margin: 3px;
            padding: 0 26px;
            height: 34px;
        }
    
        input, input:focus {
            margin-left: 30px;
            border: none;
            box-shadow: none;
            width: 100%;
        }
    
        .image-wrapper {
            width: 100%;
        }
    
        .image-wrapper::before {
            content: url('/images/zoom.svg');
            position: absolute;
            top: 12px;
            left: 24px;
        }
    
        .button-wrapper {
            text-align: right;
            padding-right: 0px;
        }
        .btn .search{
            cursor: pointer;
        }
    }

    .post-event{
        display: inline-block;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        height: 41px;
        border-radius: 6px;
        background-color: #f5a623;
        width: 144px;
    }

    .calendar-sidebar{
        border-radius: 6px;
        border: solid 1px #e2e6ee;
        background-color: #ffffff;
        border-radius: 6px;
        border: solid 1px #e2e6ee;
        background-color: #ffffff;
        .sidebar-header{
            display: flex;
            align-items: center;
            border-bottom: solid 1px #e2e6ee;
            .img-wrapper{
                display: flex;
                justify-content: center;
                width: 48px;
                height: 48px;
                border-radius: 6px 0 0 0;
                background-color: #f5a623;
                img{
                    width: 100%;
                    max-width: 24px;
                }
                
            }
            .title{
                font-family: Roboto;
                font-size: 16px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 1;
                letter-spacing: normal;
                color: #1f273c;
                padding: 0 16px;
            }

        }
        .event{
            display: flex;
            border-bottom: solid 1px #e2e6ee;
            padding: 17px 0;
            .img-wrapper{
                display: block;
                max-width: 48px;
                min-width: 48px;
                text-align: center;
                margin-top: 7px;
                img{
                    width: 24px;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .event-info{
              padding: 0 17px;
            }
            .event-title{
                font-family: Roboto;
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.14;
                letter-spacing: normal;
                color: #1f273c;
                margin-bottom: 8px;
            }
            .event-date{
                font-family: Roboto;
                font-size: 12px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.33;
                letter-spacing: normal;
                color: #3f4d6e;
            }
            &:last-child{
                border-bottom: 0px;
            }
        }
    }

    .righter{
        .ad{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 24px;
            height: 250px;
            border-radius: 6px;
            background-color: #efeff5;

        }

    }
    .subscribe {
        height: 317px;
        background-color: #1161be;
        background-image: url('/images/subscribe.svg');
        text-align: center;
        margin: 0px;
        h2 {
          font-size: 34px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.18;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
          margin-bottom: 18px;
        }
  
        p {
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 2;
          letter-spacing: normal;
          text-align: center;
          color: #cee1f7;
        }
  
        .btn {
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.29;
          letter-spacing: normal;
          color: #263049;
          border-radius: 4px;
          box-shadow: 0 5px 10px 0 rgba(9, 52, 103, 0.13);
          background-color: #ffffff;
          padding: 8px 52px;
          margin-top: 40px;
          color: #263049;
          
        }
    }

    .CalendarDay__blocked_out_of_range {
        opacity: .3;
    }
    .results-count {
        padding: 0px;
    }
    @media(min-width: 992px) {
        .search-icon::before{
            top: 11px;
            left: 11px;
            position: absolute;
            content: url('/images/zoom.svg');
        }
    }
    
        .top-panel {
            height: auto;
            padding: 24px;
            margin-bottom: 0px;
        }
        .search-box {
            .input-group {
                input[type=search] {
                    padding-left: 10px;
                    padding-right: 55px;
                }
                .input-group-prepend {
                    .btn{
                        background-image: url('/images/zoom-2.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                        padding: 0px 20px;
                    }
                }
            }
        }

        .event-btn{
            width: 100%;
            .post-event{
                width: 100%;
            }
        }
        .filter-block {
            padding: 0px 15px;
            a {
                color: #1f273c;
                text-decoration: none;
            }
            a:active{
                text-decoration: none;
            }
            .badge{
                color: #fff;
                background-color: #1b7beb;
                font-weight: 500;
                padding: 0.4em;
                border-radius: 0.85em;
                margin-left: 8px;
                vertical-align: bottom;
                width: 1.9em;
                height: 1.75em;
            }
        }
        .mobile-filter{
            padding: 16px 0px 16px;
            background: #fff;
            margin-bottom: 24px;
            border-bottom: 1px solid #e2e6ee;
        }
        .index-1048{
            z-index: 1048;
        }
        .index-1049{
            z-index: 1049;
        }
        .top-screen{
            top: 164px;
            background-color: white;
            
            width: 100%;
            border-bottom: 1px solid #e2e6ee;
            .both-filter-block{
                .col-4:first-child{
                    display: flex;
                    border-right: 1px solid #e2e6ee;
                    .close-search-block{
                        display: flex;
                        align-items: center;
                        margin-right: auto;
                        margin-left: auto; 
                        .search{
                            padding-left: 10px;
                        }
                    }
                }
                .col:first-child{
                    display: flex;
                    border-right: 1px solid #e2e6ee;
                    .close-search-block{
                        display: flex;
                        align-items: center;
                        margin-right: auto;
                        margin-left: auto; 
                        .search{
                            padding-left: 10px;
                        }
                    }
                }
            }
            .pointer{
                cursor:pointer;
            }
            .row-pad {
                height: 55px;
            }
            .divider{
                height: 1px;
                background: #e2e6ee;
            }
            .custom-control{
                padding: 0px;
                min-height: auto;
                width: 100%;
            }
            .custom-control-label {
                width: 100%;
            }
            .custom-control-label:before{
                left: 95%;
                width: 22px;
                height: 22px;
                border: solid 1px #e2e6ee;
                background-color: #fbfbfc;
                display: inline-block;
                background-color: transparent;
                background-image: none;
                top: 0px;
                border-radius: 2px;
                display:none;
                
            }
            .custom-checkbox .custom-control-input:checked~.custom-control-label::before{
                display:none;
                left: 95%;
                width: 22px;
                height: 22px;
                background-color: #ffffff;
                border: solid 1px #e2e6ee;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center right;
                top: 0px;
                border-radius: 2px;
            }
            .custom-checkbox .custom-control-input:checked~.custom-control-label::after{
                display: none;
                left: 95%;
                background-image:url('/images/check.svg');
                top: 0px;
                width: 22px;
                height: 22px;
            }
            .location-filter, .date-filter {
                height: 100vh;
                margin-left: -15px;
                margin-right: -15px;
            }
            .date-filter .col-md-4{
                padding: 0px;
                
            }
            .pc-calendar-select{
              .small-date .DayPicker_weekHeader {
                left: -9px !important;
                }
                .CalendarMonth {
                    padding: 0 0 100px 0px !important;
                }  
            }
            
        }
        .open-top-screen{
            position: fixed;
            top: 0px;
            min-height: 100vh;
            height: 100%;
            overflow-y: auto;
        }
        .calendar-view {
            padding-top: 24px;
        }
    
}