 
.contacts{
    .padding-top-bottom-2{
        padding-bottom: 2px;
        padding-top: 2px;
    }
    .padding-top-bottom-0{
        padding-bottom: 0px;
        padding-top: 0px;
    }
    .padding-b-16{
        padding-bottom: 16px;
    }
    .contacts-btn{
        height: 57px;
        button{
            margin: auto;
            a{
                color: white;
                text-decoration: none;
            }
        }
    }
    table{
        width:100%;
        table-layout: fixed;
        border: unset;
        thead{

            tr:first-child{
                border-top:0!important;
            }
            tr{
                min-height:72px;
                padding-top: 16px;
              }
        }
        tr{
            border-top: 1px solid #e2e6ee!important; 
            td{
                word-wrap: break-word;
                font-size: 14px;
                font-weight: 400;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.71;
                letter-spacing: normal;
                color: #1f273c;
                padding-left: 24px;
            }  
        }
        tr:last-child{
            border-bottom: 1px solid #e2e6ee!important;
        }
    }
    .rectangle{
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        
        border: 1px solid #e4e6ed;
        .single-02-3 {
            padding: 12px 14px 12px 12px;
            object-fit: contain;
          }
    }

}
