.react-select-container{
    .react-select__control{
    background: url(/images/select.svg) 96.5%/0.5em no-repeat #eee;
    height: 40px;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(101, 114, 148, 0.08);
    border: solid 1px #e3e7ef;
    background-color: #ffffff;
    padding: 0 16px;
    }
    .react-select__control--menu-is-open{
    background: url(/images/select-active.svg) 96.5%/0.5em no-repeat #eee;
    background-color: #ffffff;
    border-radius: 4px 4px 0 0;
    }
    .react-select__value-container, .react-select__single-value{
        padding: 0;
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: #1f273c;
    }
    .react-select__placeholder{
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #3f4d6e;
    }
    .react-select__menu{
        width: 100%;
        margin-top: 0;
        border-radius: 0 0 4px 4px;
        box-shadow: 0 1px 2px 0 rgba(101, 114, 148, 0.08);
        border: solid 1px #e3e7ef;
        background-color: #ffffff;
        border-top:0
    }
    .option{
        padding: 7px 16px;
        cursor: pointer;
        .label{
            margin-bottom: 2px;
            font-family: Roboto;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.14;
            letter-spacing: normal;
            color: #1f273c
        }
        .long-label{
            opacity: 0.5;
            font-family: Roboto;
            font-size: 9px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.78;
            letter-spacing: normal;
            color: #1f273c;
        }
        &:hover, &:active{
            background-color: #f2f5fb;
        }

    }
    
}