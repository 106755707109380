.image-design {
  iframe {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  video {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px #e2e6ee solid;
  }
}
.fake-img {
  height: 258px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.main-image {
  height: 600px !important;
}
@media (max-width: 767px) {
  .main-image {
    height: 258px !important;
  }
}
.img-bootom-text {
  max-height: 18px;
  overflow: hidden;
  max-width: 90%;
}
@media (max-width: 767px) {
  .img-bootom-text {
    max-width: 100%;
  }
}
