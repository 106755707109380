.UseCaseList{
    @import './react-dates_style_overrides.scss'; 
    @import './react-select_styles_overrides.scss'; 
    .tag {
        margin-bottom: 5px;
    }
    .usecase-title-block{
        height: 24px;
        font-family: Archivo;
        font-size: 24px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        margin: 0 auto;
    }

    .DateInput__small{
        min-width: 150px;
    }
    .CustomCheckBox{
        margin-right: 12px;
    }
    .CheckBoxSelect{
        .CustomCheckBox{
            margin-right: 16px;
        }
    }
    .top-panel{
        display: flex;
        position: relative;
        height: 121px;
        background-color: #1161be;
        background-image: url('/images/events-search-pattern.svg');
        align-items: center;
    }
    @media(max-width: 767px) {
        .top-panel {
            height: auto;
            padding: 24px;
            margin-bottom: 0px;
        }
    }
    .search-box {
        border-radius: 4px;
        box-shadow: 0 5px 10px 0 rgba(9, 52, 103, 0.13);
        height: 40px;
        .search {
            color: white;
            border-radius: 4px;
            background-color: #1b7beb;
            margin: 3px;
            padding: 0 24px;
            height: 34px;
        }
    
        input, input:focus {
            margin-left: 30px;
            border: none;
            box-shadow: none;
            width: 100%;
        }
    
        .image-wrapper {
            width: 100%;
        }
    
        .image-wrapper::before {
            content: url('/images/zoom.svg');
            position: absolute;
            top: 12px;
            left: 24px;
        }
    
        .button-wrapper {
            text-align: right;
            padding-right: 0px;
        }
        .btn .search{
            cursor: pointer;
        }
    }

    .post-event{
        display: inline-block;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        height: 41px;
        border-radius: 6px;
        background-color: #f5a623;
        width: 144px;
    }
    .calendar-sidebar{
        border-radius: 6px;
        border: solid 1px #e2e6ee;
        background-color: #ffffff;
        border-radius: 6px;
        border: solid 1px #e2e6ee;
        background-color: #ffffff;
        .sidebar-header{
            display: flex;
            align-items: center;
            border-bottom: solid 1px #e2e6ee;
            .img-wrapper{
                display: flex;
                justify-content: center;
                align-content: center;
                width: 48px;
                height: 48px;
                border-radius: 6px 0 0 0;
                background-color: #f5a623;
                img{
                    width: 100%;
                    max-width: 24px;
                }
                
            }
            .title{
                font-family: Roboto;
                font-size: 16px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 1;
                letter-spacing: normal;
                color: #1f273c;
                padding: 0 16px;
            }

        }
        .event{
            display: flex;
            border-bottom: solid 1px #e2e6ee;
            padding: 17px 0;
            .img-wrapper{
                display: block;
                max-width: 48px;
                min-width: 48px;
                text-align: center;
                margin-top: 7px;
                img{
                    width: 24px;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .event-info{
              padding: 0 17px;
            }
            .event-title{
                font-family: Roboto;
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.14;
                letter-spacing: normal;
                color: #1f273c;
                margin-bottom: 8px;
            }
            .event-date{
                font-family: Roboto;
                font-size: 12px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.33;
                letter-spacing: normal;
                color: #3f4d6e;
            }
            &:last-child{
                border-bottom: 0px;
            }
        }
    }

    .righter{
        .ad{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 24px;
            height: 250px;
            border-radius: 6px;
            background-color: #efeff5;

        }
        .now-listing{
            padding: 19px;
            border-radius: 6px;
            border: solid 1px #e2e6ee;
            background-color: #ffffff;
            font-family: Roboto;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.81;
            letter-spacing: normal;
            text-align: center;
            color: #3f4d6e;
            margin-bottom: 24px;
            .amount{
                font-size: 20px;
                font-weight: 900;
                color: #1b7beb;
            }
        }

    }
    .subscribe {
        height: 317px;
        background-color: #1161be;
        background-image: url('/images/subscribe.svg');
        text-align: center;
        margin: 0px;
        h2 {
          font-size: 34px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.18;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
          margin-bottom: 18px;
        }
  
        p {
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 2;
          letter-spacing: normal;
          text-align: center;
          color: #cee1f7;
        }
  
        .btn {
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.29;
          letter-spacing: normal;
          color: #263049;
          border-radius: 4px;
          box-shadow: 0 5px 10px 0 rgba(9, 52, 103, 0.13);
          background-color: #ffffff;
          padding: 8px 52px;
          margin-top: 40px;
          color: #263049;
          
        }
    }

    .CalendarDay__blocked_out_of_range {
        opacity: .3;
    }
  

}