.Corp-Eco-Stats{
    .info-half-mark{
        padding-top: 15px;
        .info{
            margin-left: 5px;
        }
    }
    .half-mark{
        width: 16px;
        height: 16px;
        border-radius: 50%;
    }
    .selected-visitor-mark{
        background: linear-gradient( -90deg, white 50%, #01b869 50% );
        border: 1px solid #01b869;
    }
    .selected-registered-mark{
        background: linear-gradient( -90deg, white 50%, #ffb24e 50% );
        border: 1px solid #ffb24e;
    }
    .selected-pro-mark{
        background: linear-gradient( -90deg, white 50%, #ff6d01 50% );
        border: 1px solid #ff6d01;
    }
    .selected-enterprise-mark{
        background: linear-gradient( -90deg, white 50%, #5f31d8 50% );
        border: 1px solid #5f31d8;
    }

    .m-t-20{
        margin-top:20px;
    }
    .m-b-40{
        margin-bottom: 40px;
    }
    .left-block{
        padding-left: 0px;
    }
    .right-block{
        padding-right: 0px;
    }
    .no-padding{
        padding: 0px;
    }
    a{
       text-decoration: none; 
    }
    .contact-us{
        display: flex;
        button{
            margin: 0 auto;
            width: 170px;
            height: 48px;
            border-radius: 2px;
            border: none;
            background-color: #1b7beb;
            font-family: 'Roboto';
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 2.29;
            letter-spacing: normal;
            color: #ffffff;
            
        }  
    }
}
