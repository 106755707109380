.breadcrumb {
    padding: 10px 0px 10px 0px;
    background-color: transparent;
    margin:0px;
    
}
.breadcrumb-item + .breadcrumb-item::before {
    content: url("/images/small-down-copy-11.svg");
}
.breadcrumb-item.active {
    color: #1f273c;
}
a, .breadcrumb a {
    text-decoration: none;
    color: #1b7beb;
}