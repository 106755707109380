.Login{
    .Rectangle {
        height: auto;
        border-radius: 6px;
        box-shadow: 0 5px 10px 0 rgba(41, 74, 111, 0.07);
        border: solid 1px #e2e6ee;
        background-color: #ffffff;
        margin-top: 80px;
        margin-bottom: 88px;
        .row:nth-child(2){
            padding: 88px 0px 80px;
        }
        .row{
            .col-6:first-child{
                padding-right: 0px;
            }
            .col-6:last-child{
                padding-left: 0px;
            }
        }
        .login-btn {
            height: 49px;
            border-radius: 4px 0px 0px 0px;
            background-color: #1161be;
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: normal;
            color: #ffffff;
            display: flex;
        }
        .signup-btn {
            height: 49px;
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: center;
            color: #1161be;
            display: flex;
            border-bottom: 1px solid #e2e6ee;
        }
        a:hover{
            text-decoration: none;
        }
        .btn.btn-primary{
            padding: 8px 52px;
            font-size: 14px;
            font-weight: 500;
        }
    }
    @media(max-width:414px) {
        .Rectangle{
            margin-top: 40px;
            margin-bottom: 40px;
            .row:nth-child(2) {
                padding: 40px 16px;
            }
            .btn.btn-primary{
                width: 100%;
            }
        }
    }
}