.Description{
    margin-bottom: 31px;
    .description-text-block{
        background-color: white;
        border-left: solid 1px #e2e6ee;
        border-right: solid 1px #e2e6ee;
        border-bottom: solid 1px #e2e6ee;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        .description-title{
            padding: 24px 24px 15px 24px;
            line-height: 1.71;
        }
        .description-text{
            padding: 0px 48px 17px 24px;
            line-height: 1.71;
            

        }
    }
}