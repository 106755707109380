.EventsItem,
.EventId{
    .label{
        min-width: 75px;
        align-self: flex-end;
        font-family: Roboto;
        padding: 4px 11px;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: center;
        color: #20cf8c;
        border-radius: 4px;
        border: solid 1px #20cf8c;
        background-color: rgba(32, 207, 140, 0.05);
        
        margin: 0 0 0 auto;
        text-transform: capitalize;
        span {
            margin: auto;
        }
        &.conference{
            color: #22c0c2;
            border: solid 1px #22c0c2;
            background-color: rgba(34, 192, 194, 0.05);
        }
        &.exhibition{
            color: #a486f8;
            border: solid 1px #a486f8;
            background-color: rgba(164, 134, 248, 0.05);
        }
        &.panels{
            color: #20cf8c;
            border: solid 1px #20cf8c;
            background-color: rgba(32, 207, 140, 0.05);
        }
        &.webinar{
            color: #419BF9;
            border: solid 1px #419BF9;
            background-color: rgba(65, 155, 249, 0.05);

        }
        &.networking, &.hackathon{
            color: #d73899;
            border: solid 1px #d73899;
            background-color: rgba(215, 56, 153, 0.05);   
        }
        
        &.course{
            color: #EDA643;
            border: solid 1px #EDA643;
            background-color: rgba(237, 166, 67, 0.05);
        }
        &.fair{
            color: #FF4848;
            border: solid 1px #FF4848;
            background-color: rgba(255, 72, 72, 0.05); 
        }

        &.startup{
            color: #7B47FF;
            border: solid 1px #7B47FF;
            background-color: rgba(123, 71, 255, 0.05); 
        }
    }
}