.Sidemenu{
    
    .contacts{
        margin-bottom: 20px;
        .contacts-title{
            padding: 10px 20px;
            border: 1px solid #e2e2ee;
        }
        .contacts-body{
            padding: 10px 20px;
            border-bottom: 1px solid #e2e2ee;
            border-left: 1px solid #e2e2ee;
            border-right: 1px solid #e2e2ee;
        }
    }
    .download-suggest-btn{
        width: 100%;
        border-radius: 4px;
        background-color: #1b7beb;
        border: none;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 2.29;
        letter-spacing: normal;
        color: #ffffff;
        margin-bottom: 8px;
        @media(max-width: 991px){
            margin-bottom: 24px;
        }
    }
    .download-suggest-btn-grey{
        width: 100%;
        border-radius: 4px;
        background-color: #6c757d;
        border: none;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 2.29;
        letter-spacing: normal;
        color: #ffffff;
        margin-bottom: 8px;
        @media(max-width: 991px){
            margin-bottom: 24px;
        }
    }
    .main-image{
        width: 100%;
        height: 153px;
        object-fit: cover;
        object-position: center;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    .main{
        background-color: white;
        border: 1px solid #e2e2ee;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-bottom: 16px;
        .title{
            font-family: Roboto;
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: #1f273c;
            margin-left: auto;
            margin-right: auto;
            padding: 23px 0px 27px 0;
        }
        .btn-element{
            border-top:  1px solid #e2e2ee;
            padding: 10px;
        }   
    }
    .side-btn:hover { 
        border-left: 2px solid;
    }

    .side-btn.description-link-left:hover {
        p{
            color: #90DFE0;
        }  
    }
    .side-btn.tags-link-left:hover {
        color: #46A8ED;
    }
    .side-btn.companies-link-left:hover {
        color: #D42891;
    }
    .side-btn.situation-link-left:hover {
        color: #6DD43A;
    }
    .side-btn.obstacles-link-left:hover {
        color: #EDA643;
    }
    .side-btn.approach-link-left:hover {
        color: #A485F3;
    }
    .side-btn.outcome-link-left:hover {
        color: #20CF8C;
    }
    .side-btn.iGallery-link-left:hover {
        color: #FF4848;
    }
    .side-btn.contacts-link-left:hover {
        color: #db6cdc;
    }

    .side-btn.description-link-left {
        border-left-color: #90DFE0;
    }
    .side-btn.tags-link-left {
        border-left-color: #46A8ED;
    }
    .side-btn.companies-link-left {
        border-left-color: #D42891;
    }
    .side-btn.situation-link-left {
        border-left-color: #6DD43A;
    }
    .side-btn.obstacles-link-left {
        border-left-color: #EDA643;
    }
    .side-btn.approach-link-left {
        border-left-color: #A485F3;
    }
    .side-btn.outcome-link-left {
        border-left-color: #20CF8C;
    }
    .side-btn.iGallery-link-left {
        border-left-color: #FF4848;
    }
    .side-btn.contacts-link-left {
        border-left-color: #db6cdc;
    }
}