.Blog{
    @media(min-width: 992px){
        min-height: 100vh;
    }
    .top-panel{
        display: flex;
        position: relative;
        height: 121px;
        background-color: #1161be;
        background-image: url('/images/events-search-pattern.svg');
        align-items: center;
        .title{
            font-family: Archivo;
            font-size: 44px;
            font-weight: 500;
            line-height: 1.09;
            text-align: center;
            color: #ffffff;
        }
    }
    @media(max-width: 767.98px) {
        .top-panel {
            height: 72px;
            .title{
                font-size: 24px;
                line-height: 1;  
            }
        }
    }
    .top-selector{
        position: relative;
        height: 384px;
        margin-bottom: 224px;
        background-image: url(/images/pattern-2-8.svg);
        align-items: center;
        .title{
            font-family: Archivo;
            font-size: 44px;
            font-weight: 500;
            line-height: 1.23;
            color: #ffffff;
            margin-top: 73px;
            margin-bottom: 40px;
        }
        .header-block{
            width: 100%;
            height: 393px;
            background-color: white;
            .margin-l-0{
                margin-left: 0px;
            }
            .header-info-side{
                padding: 32px;
               .tag{
                    background-color: rgba(38, 210, 163, 0.1);
                    padding: 0px 9px;
                    // border: 1px solid #26d2a3;
                    border-radius: 4px;
                    width: min-content;
                    .text{
                        font-family: Archivo;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 2;
                        color: #26d2a3;
                    }
                }
                .header-title{
                    margin-top: 10px;
                    margin-bottom: 16px;
                    font-family: Archivo;
                    font-size: 26px;
                    font-weight: 500;
                    line-height: 1.3;
                }
                .header-description{
                    font-family: Roboto;
                    font-size: 1rem;
                    font-weight: normal;
                    line-height: 2;
                    margin-bottom: 16px;
                    height: 162px;
                    overflow-y: hidden;
                }
                .header-date{
                    font-family: Archivo;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 2;
                    text-transform: uppercase;
                } 
            }
            
            img{
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
                width: 100%;
                height: 393px;
                object-fit: cover;
            }
        }
    }
}
.BlogContainer{
    figure{
        display: none;
    }
    .popular-topics{
        font-family: Archivo;
        font-size: 24px;
        font-weight: 500;
        line-height: 1.33;
        padding: 24px 0;
    }
    @media(max-width: 767px){
        .popular-topics{
            font-size: 20px;
        }
        .p-lr-5{
            padding-left: 5px;
            padding-right: 5px;
        }
    }
    
    .Meniu{
        background-color: #ffffff;
        .col-6{
            text-align: center;
            padding: 16px 0;
        }  
        .SelectedMeniu{
            font-weight: 600;
            background-color: #e2e6ee;
        }
        .meniu-results{
            background-color: #fafafc;
            padding: 16px 0 40px 0;
            .image-block{
                height: 101px;
                width:  101px;
            }
            .info{
                margin-right: 18px;
                .btn{
                    padding: 0.175rem 0.75rem 0.375rem 0.45rem;
                }
                a:hover{
                   text-decoration: none; 
                }
                .title{
                    font-family: Archivo;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 1.43;
                }
                .date{
                    text-transform: uppercase;
                    width: 150px;
                    padding-top: 16px;
                    padding-bottom: 8px;
                    font-size: 12px;
                    opacity: 0.7;
                    background-color: #fafafc;
                }
            }
            .info-description{
                margin-top: 8px;
                margin-bottom: 24px;
                font-size: 12px;
                font-weight: normal;
                line-height: 1.5;
                margin-right: 80px;
                height: 68px;
                overflow-y: hidden;
                overflow-x: hidden;
            }
            @media(max-width: 767px){
                .info-description{
                    margin-right: 40px;
                    height: 40px;
                }
            }
        }     
    }
    @media (max-width: 374px){
        .transfer-from-col-4-to-col-3{
            flex: 0 0 25%;
        }
    }
}