.ClearButton-container{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}
.ClearButton{
    position: absolute;
    z-index:1;
    height: 100%;
    top: 0;
    right: 0;
    color: hsl(0,0%,80%);
    display: flex;
    padding: 8px;
    transition: color 150ms;
    box-sizing: border-box;
    align-items: center;
    &:hover{
      color:  hsl(0,0%,60%);
    }
    &.active{
        color: hsl(0,0%,40%);
        &:hover{
            color: hsl(0,0%,20%);
        }
    }

    &.hidden{
        visibility: hidden;
    }

    .clear-svg{
        display: inline-block;
        fill: currentColor;
        line-height: 1;
        stroke: currentColor;
        stroke-width: 0;
        overflow: hidden;
        vertical-align: middle;
    }
}