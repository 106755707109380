.sector_class{
        :global {
        button:focus{
            outline: unset;
        }
        .react-multiple-carousel__arrow--right{
            z-index: 800;
            top: 40px; 
        }
        .react-multiple-carousel__arrow--left{
            z-index: 800;
            top: 40px;  
        }

        .react-multi-carousel-list {
            height: calc(100% - 60px);
            padding: 20px 0 0 0;
        }
        .react-multi-carousel-track {
            height: 100%;
        }
        .react-multi-carousel-item{
            height: 100%;
        }
        .sector-carousel{
            border-left: 1px solid #e2e6ee;
            border-right: 1px solid #e2e6ee;
            border-bottom: 1px solid #e2e6ee;
        }
        .react-multi-carousel-dot-list{
            bottom: unset;
            top: 120px;
            .react-multi-carousel-dot{
                button{
                    background-color: white;
                }
            }
            .react-multi-carousel-dot--active{
                button{
                    background-color: #080808;
                }
            }
        }
    }
}