.SubscriptionPlans{
    border-radius: 6px;
    .row{
        padding: 0px;
        margin: 0;
        height: 57px;
    }
    .row:first-child{
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }
    .row:last-child{
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    .grey-background-color{
        background-color: #e2e6ee;
    }
    .col-2{
        height: 100%;
    }
    .col-3{
        height: 100%;
    }
    
    .block{
        height: 100%;
        display: flex;
        align-items: center; 
        
    }
    .center{
        justify-content: center;
        
    }
    .mark{
        width: 16px;
        height: 16px;
        border-radius: 50%;
    }
    .half-mark{
        width: 16px;
        height: 16px;
        border-radius: 50%;
    }
    .block.visitor{
        background-color: rgba(1, 184, 105, 0.07);
        .mark{
            background-color: #01b869;
        }
    }
    .block.registered{
        background-color: rgba(255, 178, 78, 0.07);
        .half-mark{
            background: linear-gradient( -90deg, white 50%, #ffb24e 50% );
            border: 1px solid #ffb24e;
        }
        .mark{
            background-color: #ffb24e;
        }
    }
    .block.pro{
        background-color: rgba(255, 109, 1, 0.07);
        .half-mark{
            background: linear-gradient( -90deg, white 50%, #ff6d01 50% );
            border: 1px solid #ff6d01;
        }
        .mark{
            background-color: #ff6d01;
        }
    }
    .block.enterprise{
        background-color: rgba(95, 49, 216, 0.07);
        .half-mark{
            background: linear-gradient( -90deg, white 50%, #5f31d8 50% );
            border: 1px solid #5f31d8;
        }
        .mark{
            background-color: #5f31d8;
        }
    }

}
.SubscriptionPlans{
    // border: 1px solid #01b869;
    // border: 1px solid #e2e6ee;
    .block.visitor{
        background-color: rgba(1, 184, 105, 0.06);
    }
}
.SubscriptionPlans{
    // border: 1px solid #ffb24e;
    .block.registered{
        background-color: rgba(255, 178, 78, 0.06);
    }
}
.SubscriptionPlans{
    // border: 1px solid #ff6d01;
    .block.pro{
        background-color: rgba(255, 109, 1, 0.06);
    }
}
.SubscriptionPlans{
    // border: 1px solid #33c2e2;
    .block.enterprise{
        background-color: rgba(96, 49, 216, 0.06);
    }
}

.blue-line {
    background-color: rgba(20, 104, 202, 0.3);
}