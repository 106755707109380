.Partners{
    @media(max-width: 767.98px) {
        .top-panel {
            height: 72px;
            .title{
                font-size: 24px;
                line-height: 1;  
            }
        }
    }
    .margin-b-50{
        margin-bottom: 50px;
    }
    .information-block{
        height: 112px;
        margin-top: 40px;
        margin-bottom: 30px;
        background-color: white;
        .information-image{
            height: 80px;
            width: 80px;
            margin-left: 24px;
            margin-right: 16px;
            svg{
                margin: auto;
                height: 40px;
                width: 40px;
            }
        }
        .info{
            margin-top: 30px;
            margin-bottom: 30px;
            .title{
                font-family: Archivo;
                font-size: 18px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                color: #263049;
            }
            @media(max-width: 767px){
                .title{
                    padding: 0 10px;
                    text-align: center;
                }
                .description{
                    text-align: center;
                }
            }
            .description{
                font-family: Roboto;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;
                color: #3f4d6e;
            }
            @media(max-width: 767px){
                .description{
                    padding: 0 10px;
                }
            }
        }
        .apply-btn{
            border-radius: 4px;
            background-color: #1b7beb;
            margin-top: 32px;
            margin-bottom: 32px;
            margin-right: 32px;
            margin-left: auto;
            padding: 8px 56px;
            width: 182px;
            height: 48px;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.29;
            letter-spacing: normal;
            color: #ffffff;
            text-align: center;
        }
    }
    @media (max-width: 767px){
        
        .information-block{
            height: unset;
            .apply-btn{
                margin-right: auto;
            }
        }
    }
    .meniu{
        background-color: white;
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: #1f273c;
        img{
            margin-right: 17px;
        }
        .fav-companies:hover{
            color: #22c0c2;
        }
        .fav-use-case, .fav-companies{
            width: 100%;
            padding: 16px 20px;
        } 
        .fav-use-case:hover{
            color: #eda643;
        } 
        .companies-selected{
            color: #22c0c2;
            border-left: 2px solid #22c0c2;
        }
        .use-case-selected{
            color: #eda643;
            border-left: 2px solid #eda643;
        }
    }
    .main-block{
        background-color: white;
        padding: 32px 25px 17px 25px;
        .Companies{
            border-radius: 4px;
            width: 300px;
            height: fit-content;

            // .image-block{
            //     background-color: #fbfbfc;
            // }
            .main-image{
                
                width: 100%;
                height: 110px;
                object-fit: contain;
            }

            .title{
                text-align: center;
                font-family: Archivo;
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 2;
                letter-spacing: normal;
                color: #263049;
            }
        }
    }

}