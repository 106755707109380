.Filter{
    @media(max-width: 767.98px) {
        .location-filter{
            padding: 16px 16px;
            border-bottom: 1px solid #e2e6ee;
        }
        .location-options{
            border-bottom: 1px solid #e2e6ee;
            padding: 16px 20px;
            
        }
    }
    .dark-blue{
        color: #1161be;
    }
    .update-mobile-checkbox{
        border: unset;
        margin: 0 -15px;
    }
    .filter{
        span{
            img{
                left: 76px;
            }
        }
    }
}