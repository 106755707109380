.Sector{

    .InvestmentsBlock{
        border-radius: 6px;
        border: solid 1px #e2e6ee;
        background-color: #ffffff;
        padding: 16px 24px;
        .img-border{
            border-radius: 4px;
            border: solid 1px #e2e6ee;
            height: 80px;
            width: 80px;
            display: flex;
            margin-right: 16px;
            img{
                height: 45px;
                width: 45px;
                margin: auto;
            }
        }
        .text{
            margin: auto 0;
            width: calc(100% - 80px - 190px);
            .title{
                font-family: Archivo;
                font-size: 18px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.33;
                letter-spacing: normal;
                color: #263049;
            }
            .description{
                color: #3f4d6e;
                line-height: 1.71;
            }
        }
            button{
                width: 170px;
                height: 48px;
                border-radius: 4px;
                background-color: #1b7beb;
                color: white;
                border: 1px solid #1b7beb;
            }

    }
    @media(max-width: 767px){
        .InvestmentsBlock{
            padding: 16px;
            .text{
                width: unset;
                @media(max-width: 767px){
                    .title{
                        text-align: center;
                    }
                    .description{
                        text-align: center;
                    }
                }
            }
        }
    }
}