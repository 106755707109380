.Company{
    .subs-top-30{
        .subscriber-content{
            top:30%;
        }
    }
    .company-tags {
        padding: 4px;
        display: inline-block;
    }
    .padding-lr-0-lg{
        @media(min-width: 992px){
            padding-left: 0px;
            padding-right: 0px;
        }
      }
      .margin-negative-r-10{
          margin-right: -10px;
      }
    .not-display{
        display: none;
    }
    @media (max-width: 375px){
        .no-padding{
            padding-left: 0px !important;
            padding-right: 0px !important;
        }  
        .no-border{
            border: unset;
        }
    }
    
    .iotIndustry-align{
        padding-bottom: 10px;
        .row{
            div{
                display: flex;
                align-items: center;
            }
        }
    } 
    .ecosystems-align{
        padding-bottom: 10px;
        .row{
            div{
                margin-top: auto;
                margin-bottom: auto;
            }
        }
    }
    .recharts-layer{
        width: 10px;
    }
    .tag-block-btn{
        border-radius: 4px;
        border: 1px solid #e2e2ee;
        background-color: #fbfbfc;
        padding: 8px 16px;
        margin-right: 8px;
        margin-bottom: 8px;
        display: inline-block;
        width: 100%;
    }
    .likes{
        margin: auto 0 auto auto ;
        svg{
            position: absolute;
            top: -12px;
            left: -5px;
        }
        @media(max-width: 991px){
            svg{
                position: absolute;
                top: -7px;
                left: -5px;
            }
        }
        .length{
           margin: auto 0;
           padding-right: 10px; 
        }
        button{
            padding: 0.1rem 0 0.2rem 0;
            margin: auto 0 ;
            display: flex;
        }
    }
    .side-menu {
        z-index: 997;
    }
    
    .navbar {  
        height: 64px;  
        box-shadow: 0 1px 2px 0 rgba(101, 114, 148, 0.06);  
        background-color: #ffffff;  
    }
    .nav-divider {  
        width: 100%;
        height: 1px;  
        background-color: #e2e6ee;  
    }
    
    .container .breadcrumb {
        padding: 16px 0px 16px 0px;
        background-color: transparent;
        margin-bottom: 0px;
        @media (min-width: 1024px) {
            position: fixed;
        }
        z-index: 996;
    }
    .container.content-container {
        @media (min-width: 1024px) {
            margin-top: 62.5px;
        }
        
    }
    .breadcrumb-item + .breadcrumb-item::before {
        content: url("/images/small-down-copy-11.svg");
    }
    .breadcrumb-item.active {
        color: #1f273c;
    }
    a, .breadcrumb a {
        text-decoration: none;
        color: #1b7beb;
    }
    a:hover {
        text-decoration: none;
    }
    
    .menu, .card {
        
        margin-bottom: 20px;
        border-radius: 6px;
        border: 1px solid #e2e2ee;
        background-color: #ffffff;
    }
    .col-3{
        .menu {
            width: 255px;
        }
    }
    
    .menu .logo {
        width: 73px;
        height: 48px;  
        object-fit: contain;
        margin-bottom: 20px;
    }
    .menu .company {
        text-align: center;  
        font-size: 16px;  
        font-weight: 600;
        padding: 28px 0px 3px;
    }
    .menu .data {
        padding: 22px 24px 24px;
    }
    .data p {
        display: inline-block;
        line-height: 1.14;
        color: #3f4d6e;
        margin-bottom: 8px;
    }
    .data .arrow {
        margin-left: 4px;
    }
    .data span {
        float: right;
    }
    .data .contacts-btn {
        display: inline-block;
        width: 206px;
        height: 40px;
        border-radius: 2px;
        border: 1px solid #e2e2ee;
        margin-top: 24px;
        margin-bottom: 8px;
        text-align: center;
        padding: 13px 0px;
        background-color: #fff;
        cursor: pointer;
        color: #1b7beb;
    }
    .contacts-btn img, .suggest-btn img {
        margin-right: 8px;
        margin-bottom: 3px;
    }
    .info-btn, .tag-btn, .activity-btn, .cases-btn, .finance-btn {
        padding: 15px 24px 13px;
        cursor: pointer;
    }
    .info-btn:hover {
        color: #22c0c2;
        border-left: 2px solid #22c0c2;
    }
    .tag-btn:hover {
        color: #43a7ed;
        border-left: 2px solid #43a7ed;
    }
    .activity-btn:hover {
        color: #65d12f;
        border-left: 2px solid #65d12f;
    }
    .cases-btn:hover {
        color: #eda643;
        border-left: 2px solid #eda643;
    }
    .finance-btn:hover {
        color: #ff4848;
        border-left: 2px solid #ff4848;
    }
    .info-btn img, .tag-btn img, .activity-btn img, .cases-btn img, .finance-btn img {
        margin-right: 16px;
        margin-bottom: 3px;
    }
    .menu-divider {
        display: block;
        position: relative;
        padding: 0;
        height: 0;
        width: 100%;
        font-size: 1px;
        line-height: 0;
        border-top: 1px solid #e2e6ee;
    }
    .mobile-menu-list{
        min-height: 75px;
        position: fixed;
        bottom: 0px;
        left: 0px;
        background-color: white;
        width: 100%;
        z-index: 999;
        border-top: 1px solid #e2e2ee;
        
        .closed-mobile-menu-link{
           @media (min-width: 375px){
                padding: 13.5px 15px 0 15px; 
                .icon-btn{
                    border: 1px solid #e2e2ee;
                    padding: 11px 12px !important;
                    margin-right: 9px;
                } 
            }
            @media (min-width: 450px){
                .company-info-mb-menu{
                    margin: auto 0 auto 5px;
                    img{
                        margin: unset;
                    }
                    p{
                        margin: auto 20px auto 0;
                    }
                } 
            }
            @media (min-width: 450px){
                .icon-btn{
                    padding: 15px 16px !important;
                }
            }
            @media (max-width: 374px){
                padding: 15px; 
                .icon-btn{
                    border: 1px solid #e2e2ee;
                    padding: 7px 8px !important;
                    margin-right: 5px;
                } 
            } 
            .company-link{
                margin-left: 5px;
            }
            .dropdown-list-button{
                display: flex;
                margin: auto 10px auto auto;
                height: 33px;
                width: 55px;
                img{
                    margin: auto 10px auto auto;
                    height: 10px;
                }
            }
        }
        .menu-list-top{
            
            background-color: white;
            border-bottom: 1px solid #e2e2ee;
            
            .row{
                margin: 0px;
                .icon-btn:first-child{
                    border-top:unset !important;
                }
                .icon-btn{
                    text-align: unset;
                    display: flex;
                    border-top: 1px solid #e2e2ee;
                    img{
                        margin-right: 10px;
                    }
                    svg{
                        margin-right: 10px;
                    }
                }
                .p-20-15{
                    padding: 20px 15px;
                }
            }
        }
        .mobile-open-menu-list{
            @media (min-width: 450px){
                .company-info-mb-menu{
                    margin: auto 0 auto 20px;
                    img{
                        margin: unset;
                    }
                    p{
                        margin: auto;
                    }
                } 
            }
            height: 75px;
            .dropdown-list-button{
                display: flex;
                height: 75px;
                width: 50%;
                @media(max-width: 767px){
                    width: 100%;
                }
                margin: auto 25px auto auto;
                img{
                    margin: auto 10px auto auto;
                    height: 10px;
                }
            }
        }
        
        
        
    }
    
    .suggest-btn {
        width: 255px;
        border: 1px solid #1b7beb;
        background-color: #1b7beb;
        color: #fff;
        padding: 9px 0px;
        text-align: center;
        cursor: pointer;
        border-radius: 4px;
        margin-top: 17px;
    }
    .suggest-btn-grey {
        width: 255px;
        border: 1px solid #6c757d;
        background-color: #6c757d;
        color: #fff;
        padding: 9px 0px;
        text-align: center;
        cursor: pointer;
        border-radius: 4px;
        margin-top: 17px;
        img{
            margin-right: 8px;
            margin-bottom: 3px;
        }
    }
    .card .row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .d-none .d-md-block .col-9{
        .card.info, .card.tags, .card.activity, .card.cases, .card.finance, .card.contacts, .card.twitter {
            width: 855px;
            margin-bottom: 32px;
            line-height: normal; 
        }  
    }
    .card-header {
        
        display: flex;
        align-items: center;
        border:0;
        font-size: 16px;  
        font-weight: 600;
        background-color:#fff;
        padding: 0;
        
    }
    .card-header .icon {  
        display: inline-block;
        border-radius: 6px 0px 0px 0px;
    }
    .title-holder{
        display: inline-block;
    }
    .card-header p {
        display: block;
        margin-left: 16px;
        margin-bottom: 0px;
        // &::before{
        //     display: block;
        //     content: " ";
        //     margin-top: calc( -67px - 24px );
        //     height: calc( 67px + 24px );
        //     visibility: hidden;
        // }
        
    }
    .card-header .icon.info {
        background-color: #22c0c2;
        padding: 14px 13px 14px 14px;
    }
    .card-header .icon.tags {
        background-color: #43a7ed;
        padding: 13px 13px 14px;
    }
    .card-header .icon.activity {
        background-color: #65d12f;
        padding: 13px 12px 14px 13px;
    }
    .card-header .icon.cases {
        background-color: #eda643;
        padding: 13px 14px 13px 15px;
    }
    .card-header .icon.finance {
        background-color: #ff4848;
        padding: 16px 13px 17px 14px;
    }
    .card-header .icon.contacts {
        background-color: #a485f3;
        padding: 14px 12px 16px 13px;
    }
    .card-header .icon.twitter {
        background-color: #1dcaff;
        padding: 14px 12px 16px 13px;
    }
    .card .corp {
        padding: 24px 27px 7px 24px; 
    }
    .corp p:first-child, .hq p:first-child, .links p {
        font-weight: 600;
    }
    .corp p:nth-child(2) {
        line-height: 1.71;
    }
    .corp .more {
        cursor: pointer;
        color: #1b7beb;
    }
    .more img {
        margin-left: 10px;
    }
    .links {
        padding: 23px 9px 25px;
    }
    .links .right-bord {
        padding-bottom: 10px;
    }
    .links span {
        margin-right: 16px;
    }
    .right-bord {
        border-right: 1px solid #e2e2ee;
    }
    .card .hq, .scores {
        padding: 24px 25px 24px 24px; 
    }
    .fin-data .col {
        padding: 0;
    }
    .hq .map {
        background-size: 100%;
        background-repeat: no-repeat;
        height: 163px;
        margin: 20px 0px;
    }
    .zero-pd {
        padding-left: 0px;
        padding-right: 0px;
    }
    .tags .category {
        padding: 32px 10px 16px 10px;
    }
    .category p {
        font-weight: 600;
        margin-top: 8px;
    }
    .category span {
        border-radius: 4px;
        border: 1px solid #e2e2ee;
        background-color: #fbfbfc;
        padding: 8px 16px;
        margin-right: 8px;
        margin-bottom: 8px;
        display: inline-block;
    }
    .row .scores{
        min-height: 220px;
    }
    .scores .first {
        min-height: 270px;
    }
    
    .inline.growth{
      font-family: Archivo;
      font-size: 24px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #22c0c2;
    }
    
    .first .text {
        width: 190px;
    }
    .scores .second {
        min-height: 270px;
        @media (max-width: 1200px) {
            .text{
                div{
                    text-align: left;
                }
            }
        }
        @media (max-width: 576px) {
            .text{
                div{
                    text-align: left;
                }
            }
        }
    }
    .content {
        padding: 19px 20px 20px 24px;
        border: 1px solid #e2e2ee;
        border-radius: 4px;
        background-color: #fbfbfc;
    }
    
    
    .content .text {
        display: inline-block;
        margin-left: 33px;    
    }
    .text .iot {
        font-weight: 600;
    }
    .content .extra {
        font-family: Archivo;
        font-size: 24px;
        font-weight: 600;
        color: #22c0c2;
        display: inline-block;
    }
    .divider {
        width: 1px;
        height: 12px;
        background-color: #e2e6ee;
        margin: 0px 8px;
    }
    .chart {
        border: 10px solid #22c0c2;
        border-radius: 50%;
        padding: 20px 14px;
        font-weight: 600;
        font-size: 25px;
        display: inline-block;
    }
    .inline {
        display: inline-block;
    }
    .second .text {
        width: 230px;
    }
    .second .text span {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 8px;
        margin-bottom: 2px;
    }
    .bubble1 {
        background-color: #65d12f;
    }
    .bubble2 {
        background-color: #FFA500;
    }
    .bubble3 {
        background-color: #ff4848;
    }
    .bubble4 {
        background-color: #43a7ed;
    }
    .scores-divider {
        height: 1px;
        background-color: #e2e6ee;
        margin: 0px 24px;
    }
    .ecosystems, .organizations{
        td{
            height: unset !important;
            border: unset;
        }
    }
    .organizations, .conferences, .ecosystems {
        tbody {
            td {   
                padding: 0.75rem;
                @media (min-width: 992px) and (max-width: 1199px){
                    padding: 0.3rem;
                }
            }
            
          }
        padding: 24px 25px 15px 12px;
        @media (max-width: 500px) {
            padding: 10px 25px 15px 12px;
            .w-100{
                width: 100%;
            }
            .table{
                display: block;
                thead{
                    display: block;
                }
            }
        }
    }
    .organizations .circle, .conferences .circle, .ecosystems .circle {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 59px;
        height: 59px;
        font-size: 24px;
        font-weight: 600;
    }
    .organization, .ecosystem {
        min-width: 100px;
        text-align: center;
    }
    .circle.green {
        border: 2px solid #65d12f;
        color: #65d12f;
      
    }
    .circle.blue {
        border: 2px solid #43a7ed;
        color: #43a7ed;
    }
    .circle.red {
        border: 2px solid #ff4848;
        color: #ff4848;
    }
    .organizations p, .conferences p, .ecosystems p {
        font-weight: 600;
        margin-top: 16px;
    }
    .d-none .d-md-block .col-9{
        .organizations table, .conferences table, .ecosystems table {
            margin-left: 35px;
            width: 625px;
        }
    }
    .organizations{
        
        table{
            border:0;
            td{
                border: unset !important;
            }
            tbody{
                .row{
                    border-top: 1px solid #e2e6ee; 
                    td{
                        min-height: unset;
                    }
                }
            }
            
            tr{
                border-top:0;
            }
            .mobile{
                border-top: 1px solid #e2e6ee; 
            }
        }  
         
    }
     
    .table td {
        cursor: pointer;
        vertical-align: middle;
        color: #1b7beb;
        margin-bottom: 0px;
        // min-height: 95px;
        @media (max-width: 500px) {
            border: unset;
            height: unset;
        }
    }
    .table thead th{
        border:0;
    }

    .gold {
        padding: 8px;
        border: 1px solid #eda543;
        border-radius: 2px;
        background-color: #fff3e2;
        color: #d28d2c;
        display: inline-block;
    }
    .silver {
        padding: 8px;
        border: 1px solid #c3c3c3;
        border-radius: 2px;
        background-image: linear-gradient(to bottom, #ffffff, #f7f7f7);
        color: #6b6b6b;
        display: inline-block;
    }
    .diamond {
        //padding: 8px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 58px;
        width: 108px;
        border: 1px solid #8dbcf2;
        border-radius: 2px;
        background-color: #e7f2ff;
        color: #77adea;
        span{
            text-align: center;
        }
        
    }
    .plain {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 58px;
        width: 108px;
        padding: 0.5em;
        border: 1px solid #8dbcf2;
        border-radius: 2px;
        background-color: #fff;
        color: #1b7beb;
        span{
            text-align: center;
        }
    }
    .examples {
        padding: 24px 12px 12px 12px;
    }
    .examples .case-img {
        background-image: url("/images/bitmap.jpg");
        height: 200px;
        margin-bottom: 30px;
    }
    .examples h5 {
        margin-bottom: 15px;
    }
    .examples p {
        line-height: 1.71;
    }
    .fin-data {
        padding: 24px;
    }
    .data-cards {
        border: 1px solid #e2e6ee;
        background-color: #fbfbfc;
        border-radius: 4px;
        width: 180px;
        margin-right: 16px;
        @media (max-width: 500px){
            margin: 0 auto 10px auto;
        }
        padding: 16px 0px 0px 16px;
    }
    .data-cards p:first-child {
        font-weight: 600;
    }
    .rank.up::after {
        content: url('/images/rank-up.svg');
        position: relative;
        top: -2px;
        right: -0.25em;
        display: inline-block;
      }
    
      .rank.down::after {
        content: url('/images/rank-down.svg');
        position: relative;
        top: -2px;
        right: -0.25em;
        display: inline-block;
      }
      .twitter-card{
          border-top: unset !important;
          border-left: unset !important;
          border-right: unset !important;
          border-radius: unset;
      }
      .twitter-link:last-child{
        .twitter-card{
            border-bottom: unset;
            margin-bottom: 0px;
        } 
      }
}