.InfoBlock{
    border: solid 1px #e3e7ef;
    border-radius: 6px;
    .block{
        background-color: white;
        padding: 20px;
        margin: 0;
        height: 250px;
        .img-block{
            border: solid 1px #e3e7ef;
            border-radius: 6px;
            justify-content: center;
            align-items: center;
            display: flex;
        }  
    }
    .col-7{
        padding-left: 30px;
        padding-right: 5px;
    }
    .title{
        font-family: Roboto;
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        padding-bottom: 10px;
        padding-top: 10px;
    }
    .description{
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-stretch: normal;
        line-height: 2;
        letter-spacing: normal;
        padding-bottom: 20px;
        padding-top: 10px; 
        height: 115px;
    }
    .get-started{
        width: 60%;
        padding: 10px 0px;
        border-radius: 4px;
        background-color: white;
        border: 1px solid #1b7beb;
        color: #1b7beb;
    }
}
.InfoBlock.m-b-40{
    margin-bottom: 40px !important; 
 }