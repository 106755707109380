.input_text {
    position: absolute;
    height: 39px;
    top: 0px;
    left: 11px;
    display: flex;
    :global {
        div {
            margin: auto;
        }
    }
}
.z_index_1 {
    z-index: 1;
}
