.close-button{
        position: absolute;
        top: 10px;
        right: 10px;
        svg{
            height: 2em;
            color: grey;
        }
    }
    .svg{
        color: white;
    }
    .EventId{
    .eventId-icon{
        height: 25px !important;
        width: 25px !important;
        margin: 4px;
    }
    .linkedin-icon{
        color:#007BB6;
        margin-left: 0px;
    }
    .twitter-icon{
        color: #2CAAE1;
    }
    .facebook-icon{
        color: #3B579D;
    }
    .youtube-icon{
        color: #E5281A;
        margin-right: 0px;
    }
    .padding-lr-md-7{
        @media (min-width: 768px) and (max-width: 991px){
            padding-left: 7px;
            padding-right: 7px;
        }
    }
    .square{
        @media (min-width: 992px){
            padding-top: calc(100% / 6);
        }
        @media (max-width: 991px){
            padding-top: calc(100% / 4);
        }
        @media (max-width: 767px){
            padding-top: calc(100% / 3);
        }
        
    }   
    .share-button{
        color: #1B7BEB;
        font-size: 14px;
    } 
    .share-block{
        position: absolute;
        right: -30px;
        top: 10px;
    }
        
    @media (min-width: 768px) and (max-width: 991px){
        .sticky-block{
            max-width: 469px;
        }
    }
    @media (min-width: 992px) and (max-width: 1199px){
        .md-padding-0{
            padding: 0px;
        }
    }
    
    .breadcrumb{
        margin: auto 0px;
    }
    button {
        border: none;
    }
    .middle {
        align-items: center;
        display: flex;
    }
    .sticky{
        padding: unset;
        top:0px;
        position: sticky;
        background-color: white;
       
        

        &.no-soc {
            margin-left: -64%;
            margin-left: -64px;
            padding: 0px 5px;
        }
    }
    .display-webkit{
        display:-webkit-box;
    }
    .moved-sticky{
        z-index: 2000;
        box-shadow: 0 8px 6px -6px #cacccd;
        background-color:white;
        display: -webkit-box;
        border: 1px solid #CCC;
        border-top: none;
        border-radius: 5px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        .middle-block{
            display: flex;
            /* justify-content: center; */
            /* align-items: center; */
            /* align-content: center; */
            
        }
        .col-md-4{
            transform: translateY(20%);
            min-width: 146px;
        }
        button{
            min-width: 146px;
        }
        @media(max-width: 991px){
            button{
                min-width: 124px;
            }
        }

        p{
            @media(max-width: 991px){
               font-size: 14px; 
            }
            @media(min-width: 992px){
                font-size: 18px; 
            }
            transform: translateY(10%);
            
        }
    }
    .AddtoCalendarDropdown {
        width: 100%;
    }
    
    span:hover{
        text-decoration: underline;
        color: blue;
    }
     .text-decoration{
        margin-top: 16px;
        color: #1b7beb;
     }
    .m-0{
        margin: 0px;
    }
    .min-w-168{
        min-width: 168px;
    }
    .text-center{
        text-align: center;
    }
    .p-16-42{
        padding: 16px 42px;
    }
    .p-0-42-16-42{
        padding: 16px 42px 0px 42px;
    }
    .p-16-32{
        padding: 16px 32px;
    }
    .p-0-32-16-32{
        padding: 16px 32px 0px 32px;
    }
    .pl-pr-8{
        padding-left: 8px;
        padding-right: 8px;
    }
    .float-l{
        float: left;
    }
    .float-r{
        float: right;
    }
    .likes{
        float: right;
        margin: auto 0px auto auto;
        button{
            padding: 0.375rem 0px 0.375rem 0.75rem;
            
        }
    }
    .likes svg{
        margin-bottom: 5px;
    }
    
    .pointer{
        cursor: pointer;
    }
    .orange-block{
        width: 48px;
        height: 48px;
        border-top-left-radius: 6px;
        background-color: #f5a623;
        img{
            width: 50%;
        }
    }
    .title-border{
        border: solid 1px #e2e6ee;
        background-color: #ffffff;
        border-left: 0px;
        border-top-right-radius: 6px;
    }
    .bold{
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #1f273c;
        }
    .side-block-border{
        background-color: #ffffff;
        border-left: solid 1px #e2e6ee;
        border-right: solid 1px #e2e6ee;
        border-bottom: solid 1px #e2e6ee;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        
    }
    .side-block-border-no-radius{
        background-color: #ffffff;
        border-left: solid 1px #e2e6ee;
        border-right: solid 1px #e2e6ee;
        border-bottom: solid 1px #e2e6ee;
        
        
    }
    .ml-mr-15{
        margin-right: -15px;
        margin-left: -15px;
    }
    .pb-16{
        padding-bottom: 16px;
    }
    .small-icon{
        width: 15px;
        height: 16px;
        object-fit: contain;
        opacity: 0.6;
    }
    // .small-icon-block{
    //     padding-left: 24px;
    //     padding-right: 15px;
    // }
    .button-block{
        width: 218px;
        height: 32px;
        border-radius: 2px;
        border: solid 1px #1b7beb;
        background-color: #ffffff;
    }
    .button-text{
        width: 99px;
        height: 16px;
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: #1b7beb;
    }
    .view{
        width: 100%;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        justify-content: center;
        height: 40px;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: center;
        color: #1b7beb;
        box-shadow: 0 5px 10px 0 rgba(9, 52, 103, 0.13);
        background-color: white;
        border: solid 1px #a1c0e4; 
        border-radius: 4px;
    }
    .view-blue{
        width: 100%;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        justify-content: center;
        height: 40px;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: center;
        color: white !important;
        box-shadow: 0 5px 10px 0 rgba(9, 52, 103, 0.13);
        background-color: #1b7beb;
        border: solid 1px #a1c0e4; 
        border-radius: 4px;
    }
    .main-image{
        object-fit: cover;
        object-position: center;
        width: 100%;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-left-radius: 0px;
        border: solid 1px #e2e6ee;
        border-bottom: none;
        height: 300px;
    }
    @media (max-width: 991px) {
        .main-image{
            height: 345px;
        }
    }
    @media (max-width: 767px) {
        .main-image{
            height: 250px;
        }
    }
    @media (max-width: 425px) {
        .main-image{
            height: 200px;
        }
    }
    @media (max-width: 375px) {
        .main-image{
            height: 150px;
        }
    }
    .title-middle-block{
        padding-top: 10px;
        padding-bottom: 26px;
        font-family: Roboto;
        font-size: 24px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #1f273c;
        margin-bottom: 0px;
        font-weight: 600;
    }
    
    .linkedin-icon{
        margin-left: 0px;
    }
    
    hr { 
        height: 1px;
        background-color: #e2e6ee;
        margin: 24px 0px;
        margin-bottom: 5px;
    }
    .website-btn{
        padding-right: 8px;
        border-radius: 4px;
        background-color: #682cff;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        width: 100%
    }
    .register-btn{
        padding-right: 8px;
        padding-left: 8px;
        border-radius: 4px;
        background-color: #1b7beb;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        width: 100%
    }
    .discount-btn{
        padding-left: 8px;
        border-radius: 4px;
        background-color: #f5a623;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        width: 100%;
        
    }
    .social-links{
        padding-bottom: 15px;
        min-height: 48px;
    }
    .toggle{
        p{
            max-height: 700px;
            transition: max-height 1s ease-in;
            overflow: hidden;
        }
         &.hidden{
             p{
                max-height: 48px;
                transition: max-height 1s ease-in;
                overflow: hidden;
             }
         }
     }
     
    .first {
        z-index: 998;
        border-bottom: none;
        border-bottom-left-radius: initial; 
        border-bottom-right-radius: initial; 
    }
    .second {
        padding-top: 0px;

        hr:first-child {
            margin-top: 0;
        }
    }
    .section-title {
        font-weight: 600;
        font-size: 14px;
        font-style: italic;
    }

    .label {
        align-self: flex-start;
        text-align: left;
        margin: 0;
        width: fit-content;
        font-size: 11px;
    }

    .stickyHeader {
        display: none;
        position: fixed;
        top: 0;
        left: 0%;
        height: 67px;
        width: 100%;
        z-index: 1050;
        background-color: #FAFAFC;

        &.visible {
            display: inherit;
        }
    }
    .position-fixed {
        margin-top: 1px;
        z-index: 1;
    }
    @media(max-width: 767px) {
        .mobile-mar{
            margin: 0px;
            .mobile-pad{
                padding: 0px;
            }
        }
        hr {
            margin-bottom: 24px;
        }
        .event-place{
            .container{
                padding: 0px;
            }
        }
        .datetime .date {
            padding: 10px;
        }
        .fixed-bottom{
            padding: 1.6em 1.5em 1.2em;
            box-shadow: 0 -5px 10px 0 rgba(41, 74, 111, 0.07);
            background-color: #fff;
            height: 79px;
        }

        .pr5 {
            padding-right: 5.5px;
        }
        .pl5 {
            padding-left: 5.5px;
        }
        .discount-btn {
            padding: 9px 20px;
        }
        .register-btn {
            padding: 9px 20px;
        }   
        // #speakers, #organizers, #sponsors {
        //     padding: 0px 24px;
        // }     
    }
    @media(max-width: 364px){
        .discount-btn {
            height: 41px;
            padding: 7px 18px;
            line-height: 1;
        }
    }
    @media(max-width: 320px) {
        .discount-btn {
            padding: 7px 15px;
        }
    }
        
}
.blooger-icon{
    color:#EDA643;
}
.text-style{
    font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
}
.grey-color{
    color: #5e6a85;
}
.pt-24{
    padding-top: 24px;
}

.align-center{
    align-items: center;
}
.justify-center{
    justify-content: center;
}
.mr-b-15{
    margin:15px;
}
.popover-block-discount{
    border-radius: 20px;
    width: 350px;
   
}
.eventIdPopover{
    top: 25px !important;
    left: 10px !important;
}
.eventIdPopover-l-0{
    top: 16px !important;
    left: 0px !important;
}
.eventIdSmallPopover{
    top: 25px !important;
    left: -3px !important;
}
.discount-code{
    width: 100%;
    justify-content: center;
    display: flex;
    border: black 1px solid;
    background-color: #dee2e6;
    padding: 10px 0px;
}
.mr-t-15{
    margin-top: 15px;
}



