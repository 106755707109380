.scores{
     div.first > div > div.recharts-wrapper > svg > g > text > tspan{
        font-family: Roboto;
        font-size: 24px;
        font-weight: 500;
    }
    
    tspan{
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
    }
}