.AddtoCalendarDropdown{
    .center-text{
        margin: auto 0;
    }
    button{
        background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    }
    .display-flex:hover{
        text-decoration: underline;
    }
    .link{
        color:#1b7beb;
        .display-flex{
            margin-left: 8px;
            .oval{
                border: 1px solid #1b7beb;
                background-color: white;
                border-radius: 50%;
                height: 24px;
                width: 24px;
                display: flex;
                .center{
                    margin: auto;
                }
            }
        }    
    }
    position: relative;
    .dropdown-container{
        background-color: #ffffff;
        position: absolute;
        left: -1px;
        right: -1px;
        top: calc(100% + 1px);
        z-index: 1;
        border-radius: 0 0 4px 4px;
        box-shadow: 0 1px 2px 0 rgba(101, 114, 148, 0.08);
        border: solid 1px #e3e7ef;
        border-top: 0;
    }
    .dropdown-item{
            padding: 0;
            a{
                display: flex;
                align-items: center;
                padding: 8px 16px;
            }
    }
}