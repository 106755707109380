.img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.img_block {
    height: 400px;
    width: 600px;
}
@media(max-width: 1199px){
    .img_block {
        width: 500px;
        height: 350px;
    }
}
@media(max-width: 991px){
    .img {
        height: 250px;
    }
    .img_block {
        width: 300px;
        height: 250px;
    }
}
@media(max-width: 768px){
    .img {
        height: fit-content;
    }
    .img_block {
        width: 80%;
        height: fit-content;
        min-height: 150px;
        padding: 20px;
    }

}