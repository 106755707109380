.DateRangePicker_picker{
  box-shadow: 0 1px 2px 0 rgba(101, 114, 148, 0.08);
  border:  1px solid #e3e7ef;
  border-radius:0 4px 4px 4px;
}
.DayPicker__withBorder {
  box-shadow: unset;
  border: unset;
  max-width: 435px;
  div{
    max-width: 435px;
  }
}

.DateRangePicker{
  width: 100%;
}

.DateRangePickerInput__withBorder{
  display: flex;
  align-items: center;
  background: url(/images/select.svg) 96.9%/0.5em no-repeat #fff;
  height: 40px;
  box-shadow: 0 1px 2px 0 rgba(101, 114, 148, 0.08);
  border:  1px solid #e3e7ef;
  background-color: #ffffff;
  padding: 0;
  border-radius: 4px;
  color: #3f4d6e;
}

  .DayPickerNavigation_button{
    position: relative;
    .prev{
      background-color: #ffffff;
      top: (21px - 5px);
      left: 16px;
      position: absolute;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: solid 1px #e2e6ee;
    }
    .next{
      background-color: #ffffff;
      top: (21px - 5px);
      position: absolute;
      right: 16px;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: solid 1px #e2e6ee;
    }
  } 

  .DateRangePickerInput_arrow{
    margin: 0 0.5em;
  }

  .DateInput_input__focused{
    border-bottom: 0;
  }
  .DateInput_input__small{
    height: 100%;
    &:hover{
     // background-color: rgba(17, 97, 190, 0.14);
    }
    &:first-child{
      padding: 8px 0 8px 16px;
    }
    &:last-child{
      padding: 8px 16px 8px 0;
    }
    //text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: #1f273c;
    border-bottom: 0;
    &::placeholder{
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.71;
      opacity: 1;
      letter-spacing: normal;
      color: #3f4d6e!important;
    }
  }
  .DateInput_fang{
    display: none;
  }

  .CalendarMonth_caption{
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      padding:20px;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #1f273c;
  }
  .DateRangePicker_picker__directionLeft{
    top:calc(100% - 0px)!important;
    right: 0px !important;
    left: unset !important;
  }
  .CalendarMonthGrid__horizontal{
    position: absolute;
    left:0;
  }

  .DayPicker_transitionContainer__horizontal{
    max-height: 250px;
  }


  .DayPicker_weekHeader{
    top: 57px;
  }

  .DayPicker_weekHeader_ul{
    margin: unset;
  }
  .DayPicker_weekHeader_li, small{
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f4d6e;
    width: 27.5px!important;
  }
  .DayPicker_weekHeader{
    padding: 0 8px 8px 8px!important;
    text-align: center;
    top: 57px;
    border-bottom: 1px solid #e2e6ee;
    &:last-child{
      left: 213px!important;
    }
  }
  .DayPickerKeyboardShortcuts_showSpan__bottomRight, .DayPickerKeyboardShortcuts_show__bottomRight{
    display: none;
  }
  .CalendarMonth{
      padding: 0 0 100px 16px!important;
  }
  .CalendarMonth_table{
        background-color: #ffffff;
        border: unset;
        border-radius: unset;
        margin-top: 32px;
        tbody tr td:first-child{
          padding: 0!important;
          margin: 0!important;
        }
        tbody tr td{
          padding: 0!important;
          margin: 0!important;
        }
        tbody tr{
          border: 0;
          min-height: 25px;
        }
        
        .CalendarDay__default{
          border: 0;
          padding: 0;
          margin: 0;
          font-family: Roboto;
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #3f4d6e;
          width: 27.5px!important;
          height: 25px!important;
        }
  }

  .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover{
    &.CalendarDay__default{
      width: 25px!important;
    }
    position: relative;
    color: #ffffff!important;
    background-color:#1161be!important;
    border-radius: 50%;
    z-index: 1;
    
    &.CalendarDay__selected_start{
      &::after{
        content: '';
        position: absolute;
        z-index: -1;
        top:0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(17, 97, 190, 0.14);
        border-radius: 50% 0 0 50%;
      }
      &.CalendarDay__selected_end::after{
        background-color: transparent;
      }
    }
    &.CalendarDay__selected_end{
      &::before{
        content: '';
        position: absolute;
        z-index: -1;
        top:0;
        left: 0;
        height: 100%;
        width: 100%;  
        background-color: rgba(17, 97, 190, 0.14);
        border-radius: 0 50% 50% 0;
      }
      &.CalendarDay__selected_start::before{
        background-color: transparent;
      }
    }
 
  }
  .CalendarDay__selected_span:active, .CalendarDay__selected_span:hover, .CalendarDay__selected_span, .CalendarDay__default:hover{
    background-color: rgba(17, 97, 190, 0.14);
    
  }

  .CalendarDay__hovered_span{
    background-color: rgba(17, 97, 190, 0.14);
    position: relative;
}

.initial-calendar{
  .DateRangePickerInput_arrow{
    display: none;
  }
 

  .DateInput{
    width: 100%;
    max-width: 100%;
    .DateInput_input__small{
      background: url(/images/select.svg) 96.9%/0.5em no-repeat #fff;
    }
  }

  &.active-calendar{
    .DateInput_input__small{
      background: url(/images/select-active.svg) 96.9%/0.5em no-repeat #fff;
    }
  }

}


.active-calendar{
  .DateRangePickerInput__withBorder{
    background: url(/images/select-active.svg) 96.9%/0.5em no-repeat #fff;
  }
  .DateRangePickerInput__withBorder{
    border-radius: 0;
  }
}