.root {
	:global {
		.mb-116{
            margin-bottom: 116px;
		}
		.green-tag {
			max-width: 95px;
			background-color: rgba(9, 211, 162, 0.1);
			color: #08cb9b;
		}
		.red-tag {
			max-width: 134px;
			background-color:rgba(237, 67, 112, 0.1);
			color: #ed4370;
		}
		.purpule-tag {
			max-width: 95px;
			background-color:rgba(174, 97, 249, 0.1);
			color: #ae61f9;
		}
	}
}
.main_img {
	object-fit: contain;
}
.suggestion_blocks {
	@media(max-width: 767px){
		background-image: url(/images/subscribe.svg);
    	background-color: #1161be;
	}
	@media(min-width: 768px){
		position: absolute;
		bottom: -30px;
	}
	.custom_color{
		background-color: rgb(224,245,241);
	}
}
.image_gallery {
    width: 200px;
}
.image_gallery_block {
	max-width: 700px;
}
.engagement_block {
	width: 200px;
	min-width: 200px;
}
.endorsment_block {
	max-width: 800px;
}
