.Campaign{
    .apply-img{
        height: 50px;
        width: 50px;
        margin: auto;
    }
    .p-t-64{
        padding-top: 64px;
    }
    @media(max-width: 767.98px) {
        .top-panel {
            height: 72px;
            .title{
                font-size: 24px;
                line-height: 1;  
            }
        }
    }
    .information-block{
        height: 112px;
        margin-top: 40px;
        margin-left: 0px;
        margin-right: 0px;
        background-color: #FFFFFF;
        .information-image{
            height: 80px;
            width: 80px;
            margin-left: 24px;
            margin-right: 16px;
            svg{
                margin: auto;
                height: 40px;
                width: 40px;
            }
        }
        .info{
            margin-top: 30px;
            margin-bottom: 30px;
            .title{
                font-family: Archivo;
                font-size: 18px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                color: #263049;
            }
            .description{
                font-family: Roboto;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;
                color: #3f4d6e;
            }
        }
        .apply-btn{
            border-radius: 4px;
            background-color: #1b7beb;
            margin-top: 32px;
            margin-bottom: 32px;
            margin-right: 32px;
            margin-left: auto;
            padding: 8px 56px;
            width: 182px;
            height: 48px;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.29;
            letter-spacing: normal;
            color: #ffffff;
            a{
                color: white;
                text-decoration: unset;
            }
        }
    }
    .featured-title{
        font-family: Archivo;
        font-size: 26px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: normal;
        color: #263049;
        margin-top: 64px;
        margin-bottom: 50px;
    }
    @media(min-width: 767px) and (max-width: 992px){
        .information-block{
            height: unset;
            .apply-btn{
                margin-right: auto;
            }
            .info{
                width: 335px;
            }
        }
        
    }
    @media(max-width: 767px){
        .information-block{
            height: unset;
            .apply-btn{
                margin-right: auto;
            }
            .info{
                margin-top: 16px;
                margin-bottom: 16px;
                padding: 0 16px;
                .title{
                    text-align: center;
                }
                .description{
                    text-align: center;
                }
            }
        }
        
    }
}