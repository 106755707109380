.profile {
    table {
        width: 100%;
    }
    .activation-code{
        margin: auto 20px auto auto;
    }
    .button-block a , button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        min-width: 170px;
        padding: 10px 15px;
        border-radius: 2px;
        border: none;
        background-color: #1b7beb;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 2.29;
        letter-spacing: normal;
        color: #ffffff;
        margin-right: 24px;
    }

    .button-block {
        padding: 15px;
        text-align: center;
        a:hover{
            text-decoration: none;
        }
    }
}