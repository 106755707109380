.CardsTitle{
    height: 48px;
    display: flex;
    .title{
        font-weight: 600;
        font-size: 16px;   
    }
    
    .title-image{
        display: flex;
        width: 48px;
        justify-content: center;
        border-top-left-radius: 4px;
        svg{
            color: white;
            height: 48px;
            width: 24px;
        }
    }
    
    .title-block{
        background-color: white;
        height: 46px;
        width: 100%;
        padding-left: 16px;
        border: solid 1px #e2e6ee;
        border-left: none;
        box-sizing: unset;
        display: flex;
        border-top-right-radius: 4px;
        .mobile-like{
            padding-right: 32px;
            @media (min-width: 768px){
                margin-right: -15px;
            }
        }
    }
    //select page 
    .title-image.cards-market-research-reports{
        background-color: #a485f3;
    }
    .title-image.cards-smart-cities-events{
        background-color: #65d12f;
    }
    .title-image.cards-smart-cities-news{
        background-color: #43a7ed;
    }
    .title-image.cards-featured-use-cases{
        background-color: #EDA643;
    }
    .title-image.cards-company-leaderboard{
        background-color: #22c0c2;
    }
    //usecase details page
    .title-image.cards-description{
        background-color: #22c0c2;
    }
    .title-image.cards-title{
        background-color: #46A8ED;
    }
    .title-image.cards-companies{
        background-color: #D42891;
    }
    .title-image.cards-situation{
        background-color: #6DD43A;
    }
    .title-image.cards-obstacles{
        background-color: #EDA643;
    }
    .title-image.cards-approach{
        background-color: #A485F3;
    }
    .title-image.cards-outcome{
        background-color: #20CF8C;
    }
    .title-image.cards-iGallery{
        background-color: #FF4848;
    }
    .title-image.cards-contacts{
        background-color: #A485F3;
    }
    .title-image.cards-ruc{
        background-color: #db6cdc;
    }
        
}