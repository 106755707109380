    .options-filter-search{
        text-align: center;
        // z-index: 1048;
        position: absolute;
        background-color: white;
        width: 100%;
        .search-filter-option{
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }
    .main-filter-block{
        position: absolute;
        // z-index: 1049;
        width: 100%;
    }
    .btn-search-filter{
        padding-top: 10px;
        padding-bottom: 10px;
        width: 100%;
        
        border-radius: unset;
        background-color: white;
        border-color: #1b7beb;
        border-left: unset;
        border-right: unset;
        color: #1b7beb;
        div{
            
            background-color: white;
        }
    }
    .btn-search-filter:hover{
        background-color: #1b7beb;
        color: white;
    }   
    .cluster{
        img{
           height: 56px;
            width: 56px;
        } 
    }
    
    .marker-block{
        width: 250px;
    }
    .address{
        margin: auto 0;
    }

    .center-text{
        text-align: center;
    }
   
    .justify-center-block{
        display: flex;
        justify-content: center;
    }
    .company-profile-btn{
        margin-bottom: 20px;
        text-decoration: none !important;
        a{
           color: white; 
        }
    }
    .m-tb-15{
        margin:15px 0px;
    }
    .f-s-14{
        font-size: 14pt;
    }
    .title{
        font-size: 14pt;
        margin:auto 0px;
    }
    
    .Map{
        .gm-bundled-control{
            bottom: 250px !important;
        }
        .open-info{
            padding-left: 16px;
            padding-right: 16px;
            // z-index: 1049;
        }
        .pc-info-window{
            position: absolute;
            top: 0px;
            left: 0px;
            background-color: white;
            height: 100%;
            
            .cancel-marker{
                position: absolute;
                top: 10px;
                right: 10px;
                height: 2em;
            }
            .colapse-info-block{
                position: absolute;
                top: 40px;
                right: -20px;
                height: 60px;
                width: 20px;
                background-color: white;
                display: flex;
                // z-index: 1049;
                div{
                    margin: auto;
                }
                svg{
                    height: 1.5em;
                }
            }
            height: 100% !important;
            .collapse{
                height: 100% !important;
            }

            .collapse-open-block{
                padding-top: 16px;
                width: 250px;
                -webkit-transition: width 0.5s; /* For Safari 3.1 to 6.0 */
                transition: width 0.5s;
                overflow-x: hidden;
                overflow-y: hidden;
            }
            .collapse-close-block{
                padding-top: 16px;
                width: 0px;
                -webkit-transition: width 0.5s; /* For Safari 3.1 to 6.0 */
                transition: width 0.5s;
                overflow-x: hidden;
                .marker-block{
                    -webkit-transition: display 0.5s; /* For Safari 3.1 to 6.0 */
                    transition: display 0.5s;
                }
            }
        }
        @media(min-width: 992px){
            .margin-b-66{
                margin-bottom: 66px;
            }
        }
        .top-panel{
            display: flex;
            position: relative;
            height: 121px;
            background-color: #1161be;
            background-image: url('/images/events-search-pattern.svg');
            justify-content: center;
            align-items: center;
            color: white;
            font-size: 44px;
        }
        @media(max-width: 767px) {
            .top-panel {
                height: auto;
                padding: 24px;
                margin-bottom: 0px;
            }
        }
        .map-title-block{
            height: 24px;
            font-family: Archivo;
            font-size: 24px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            margin: 0 auto;
        }
    .rigth-side{
        margin: auto 0 auto auto;
        .likes{
            margin: auto 0;
        }
    }
    .margin-auto{
        margin: auto;
    }
    .to-comapnies-btn{
        color: white;
        border-radius: 4px;
        border: #a1c0e4 solid 1px;
        background-color: transparent;
        margin: 4px;
        padding: 8px 24px;
        margin: 0;
        height: 40px;
    }
    .flex-display{
        vertical-align: middle;
        display: -webkit-inline-box;
        
    }
    .center-title{
        display: flex;
        justify-content: center;
        
    }
    .marker-img-big{
        height: 100px;
        width: 100px;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .marker-img{
        display: -webkit-inline-box;
        height: 50px;
        width: 50px;
        margin-right: 20px;
    }
    .striped-solid {
        border-top: 1px solid #cacccd;
        width: 90%;
        
        margin: 10px 5%;
    }
    .justify-center{
        display: flex;
        justify-content: center;
    }
    
    
    .middle{
        display: flex;
        margin: auto 0px;
        font-size: 10pt;
    }
    .height-margin{
        margin: auto 0px;
    }
    .text{
        width: 90%;
        
        margin: 10px 5%;
        margin-top: 10px;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #1f273c;
    }
    .container{
        position: relative;
    }
    .spinner-wrapper{
        display: flex;
        justify-content:center;
        align-items: center;
        position: absolute;
        // z-index: 1;
        width: 100%;
        height: 100%;
        background: #ffffff;
        opacity: 0.75;
    }
    .add-company-info{
        background-color: white;
        border: 1px solid #e2e6ee;
        border-radius: 6px;
        margin: 40px 0px 0px 0px;
        display: flex;
        flex-wrap: wrap;
        align-items: center !important;
        padding: 16px 0px;
        img{
            padding: 18px;
            border-radius: 4px;
            border: 1px solid #e2e6ee;
            margin-left: 24px;
            vertical-align: middle;
        }
        .add-company-btn{
            color: white;
        border-radius: 4px;
        background-color: #1b7beb;
        border-radius: 4px;
        background-color: #1b7beb;
        width: 170px;
        padding: 5px;
        line-height: 2.29;
        margin-right: 24px;
        text-decoration: none;
        text-align: center;
        }
    }
    @media screen and (min-width: 992px){
        .mobile-info-block{
            display: none !important;
        }
        
    }
    
    .map-block{
        height: calc(100vh - 104px) !important;
        // .gm-style{
        //     div{
        //         img{
        //             max-width: 40px;
        //             max-height: 40px;
        //         }     
        //     }
               
        // }
        
    }
    @media screen and (max-width: 991px){
        .map-block{
            padding: 0px;
        }
        .gm-style .gm-style-iw-t{
            display: none;
        }
    }
    .mobile-info-block{
        @media(max-width: 375px){
            .col-2{
                flex: 0 0 25%;
                max-width: 25%;
                display: flex;
                .likes{
                    margin: 0 0 0 auto;
                }
            }
        }
        
        .address-block{
            align-items: center;
            padding-top: 16px;

            .marker-logo{
                svg{
                    height: 2rem;
                }
            }
            .likes{
                display: flex;
                align-items: center;
            }
        }
        // .likes-block{
        //     padding: 0px;
        //     margin: 0 0 0 auto;
        //     display: flex;
        //     .likes{
        //         display: flex;
        //         align-items: center;
        //         margin: 0 0 0 auto;
        //     }
        // }
        .company-profile-btn{
            margin-top: 15px;
            display: flex;
            button{
                margin-left: auto;
                margin-right: auto;
                padding-left: 20px;
                padding-right: 20px;
            }
        }
        .pointer{
            cursor: pointer;
        }
        bottom: 0px;
        left: 0px;
        position: fixed;
        // z-index: 800;
        background-color: white;
        display: flex;
        width: 100%;
        .cancel-marker{
            display: flex;
            justify-content: end;
            margin: 5px 5px 0 auto;
            height: 2em;
        }
        .marker-block{
            margin-right: auto;
            margin-left: auto;
            width: 100%;
        }
    }
}