.Terms-Of-Service{
    .welcome-screen{
        height: 210px;
        background-color: #1161be;
        background-image: url('/images/search-field-pattern.svg');
        margin-bottom: 71px;
        .text-box{
            text-align: center;
            h1{
                font-family: 'Archivo';
                font-size: 34px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.41;
                letter-spacing: normal;
                color: #ffffff; 
            }
            p{
                font-size: 16px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 2;
                letter-spacing: normal;
                color: #cee1f7;
                &.date-range{
                    line-height: 1.5;
                    margin-top: 1em;
                    font-weight: bold;
                    color: #ffffff; 
                }
            }

        }
    }
    .p-tb-15{
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .content{
        background-color: #ffffff;
        h2{
            &::before{
                display: block;
                content: " ";
                margin-top: -67px;
                height: 67px;
                visibility: hidden;
            }
            
        }
    }


    
}

.Side-Menu-Contents{
    .menu-divider {
        height: 1px;  
        background-color: #e2e6ee;
    }
    .heading{
        text-align: center;  
        font-size: 16px;  
        font-weight: 600;
        padding-top: 24px;
    }
    border-radius: 6px;
    border: 1px solid #e2e2ee;
    background-color: #ffffff;
    .contents{
        padding: 22px 24px 24px;
        font-size: 16px;
        ol {
            counter-reset: item
          }
          li {
            display: block
          }
          li:before {
            content: counters(item, ".") ". ";
            counter-increment: item;
          }
    }
}