.Sector{
    .SmartCitiesEvents{
        margin-top: 32px;
        .img-size{
            width: 16px;
            height: 16px;
        }
        .main-img {
            width: 220px;
            max-height: 127px;
        }
    }
}