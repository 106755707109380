.ExhibitSide{
  margin-bottom: 15px;
    .margin-0-n15{
      margin: 0px -15px;  
    }
    .togglee{
      max-height: 100%;
      transition: .5s ease;
      display:block;
      &.hidden{
         .exhibitBlock:nth-child(n+10){   
              transition: .5s ease;
              display: none;
          }  
      }  
   }
   .exhibitBlock:hover{
    border-color: coral;
   }
}