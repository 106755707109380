.ListView{
    .blue-like{
        color: #007bff;
    }
    .react-select-container {
        input {
            opacity: 1 !important;
        }
    }
    .search-border{
        border: 1px solid #ced4da !important;
    }
    .form-control{
        border: 1px solid #e3e7ef;
    }
    .unset-shadow{
        box-shadow: unset;
    }
    //calendar
    .ClearButton-container{
        height: unset;
    }
    .small-date{
        .DateRangePickerInput {
            width: 100%;
            
            display: block;
            border: unset;
            .DateInput_1{
                width: 100%;
                border-bottom: 1px solid #e2e6ee; 
            }
            .DateRangePickerInput_arrow {
                display: none;
            }
        }


        display: block !important;
        .DayPicker_weekHeader {
            display: flex;
            width: 100%;
            margin-left: unset !important;
            left: 0px !important;
            .DayPicker_weekHeader_ul{
                margin-right: auto;
                margin-left: auto;
            }
        }
        .CalendarMonth_table{
            margin-left: auto;
            margin-right: auto;
        }
        .CalendarMonth{
            padding: 0 !important;
        }
        .DateRangePicker_picker {
            width: 100%;
            display: flex;
            top: 66px !important;
            .DayPicker {
                width: 80%;
                margin-right: auto;
                margin-left: auto;
            }
        }

        .DateInput_1{
            width: 100%;
        }
    }
    .tablet{
        .header{
            border: solid 1px #e3e7ef;
            border-radius: 4px;
        }
       .filter{
                margin: 0px;
                border: unset !important;
            }
            span{
                width: 100%;
                display: flex;
                img{
                    margin-right: 0px;
                    margin-left: auto;
                }
            } 
    }
    .tablet-date{
        
        
        .DayPicker_weekHeader {
            display: flex;
            width: 100%;
            margin-left: unset !important;
            left: 0px !important;
            .DayPicker_weekHeader_ul{
                margin-right: auto;
                margin-left: auto;
            }
        }
        .CalendarMonth_table{
            margin-left: auto;
            margin-right: auto;
        }
        .CalendarMonth{
            padding: 0 !important;
        }
        .DateRangePicker_picker {
            width: 100%;
            display: flex;
            top: 45px !important;
            .DayPicker {
                width: 80%;
                margin-right: auto;
                margin-left: auto;
            }
        }

        .DateInput_1{
            width: 100%;
        }
    }
    .location-filter{
        .css-bg1rzq-control{
            border: unset;
            border-radius: unset;
            border-bottom: 1px solid #e2e6ee;
        }    
    }
    
}