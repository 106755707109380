.ImageGallerySector{
    .title{
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        padding: 10px;
    }
    margin-bottom: 31px;
    position: relative;
    .color-black{
        color: black;
    }
    .description{
        background-color: white;
        border-left: solid 1px #e2e6ee;
        border-right: solid 1px #e2e6ee;
        border-bottom: solid 1px #e2e6ee;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        padding: 23px 24px 17px 24px;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #3f4d6e;
        img{
            display: flex;
            margin: auto;
            width: 200px;
            height: 125px;
            padding: 0px !important;
            border-radius: 8px;
        }
        
        .container{
            padding: 0px;
        }
        
    }
    @media(max-width: 767px){
        .description{
            padding: 16px;
        }
    }
    .not-login{
        -webkit-filter: blur(4px);
        filter: blur(4px);
    }
    
}
.sector-class{
    button:focus{
        outline: unset;
    }
    .react-multiple-carousel__arrow--right{
        z-index: 800;
        top: 20%; 
    }
    .react-multiple-carousel__arrow--left{
        z-index: 800;
        top: 20%;  
    }

    .react-multi-carousel-list {
        height: calc(100% - 60px);
        padding: 20px 0 0 0;
    }
    .react-multi-carousel-track {
        height: 100%;
    }
    .react-multi-carousel-item{
        height: 100%;
    }
    .sector-carousel{
        border-left: 1px solid #e2e6ee;
        border-right: 1px solid #e2e6ee;
        border-bottom: 1px solid #e2e6ee;
    }
    .react-multi-carousel-dot-list{
        bottom: unset;
        top: 120px;
        .react-multi-carousel-dot{
            button{
                background-color: white;
            }
        }
        .react-multi-carousel-dot--active{
            button{
                background-color: #080808;
            } 
        }
    }  
    @media(min-width: 768px){
        .react-multi-carousel-dot-list{
            display: none;
        }
    }  
}
