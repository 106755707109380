.Sector{
    .News{
        margin-top: 32px;
        .image-block {
            width: 223px;
            height: 100%;
            .img {
                width: 221px;
                height: 100%;
            }
		}
		.closed {
			height: 50px;
		}
		.open-content, .close-content{
			-webkit-transition: height 0.5s;
			transition: height 0.5s;
		}
		.open-content {
			height: fit-content;
		}
		.close-content {
			height: 80px;
		}
    }
}