.label { 
    margin: 0;
    margin-bottom: 15px;
    width: 100%;
    cursor: default;

    span:hover {
        color: #419BF9 !important;
        text-decoration: none !important;
    }
}

.datetime {
    display: flex;
    align-items: center;

    .date {
        text-align: center;
        padding: 10px 13px;
        border: 1px gray solid;
        border-radius: 5px;
        font-weight: 600;
        text-transform: uppercase;
        min-height: 55px;

        
    }
    .time {
        line-height: 28px;

        padding-left: 0;
    }
}