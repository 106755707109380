.Partner{
    .main-block{
        background-color: #dee2e636;
        padding: 50px 0px;
        border-bottom: 1px solid #e3e7ef;
    }
    .welcome-screen{
        height: 220px;
        background-color: #1161be;
        background-image: url('/images/search-field-pattern.svg');
        .text-box{
            text-align: center;
            font-family: 'Roboto';
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 2;
            font-size: 16px;
            letter-spacing: normal;
            color: #e9ecefd6;
            h1{
                
                font-size: 34px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.41;
                letter-spacing: normal;
                color: #ffffff; 
            } 
            p{
                font-size: 16px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 2;
                letter-spacing: normal;
                color: #cee1f7;
                &.date-range{
                    line-height: 1.5;
                    margin-top: 1em;
                    font-weight: bold;
                    color: #ffffff; 
                }
            }

        }
    }
}