.EventsItem{
    .organizer{
        margin: auto 10px auto 15px;
        color: grey;
    }
    .self-organizer{
        margin: auto 0px;
    }
    .AddtoCalendarDropdown{
        display: inline;
    }
    .event-calendar{
        display: inline-block;
        .AddtoCalendarDropdown{
            padding: 0px 10px;
        }
    }
    border-radius: 6px;
    border: solid 1px #e2e6ee;
    background-color: #ffffff;
    margin-bottom: 16px;
    &.featured {
        border: solid 1px #f5a623;
    }
    .featured-header .img-wrapper{
        border-radius: 6px 0 0 0;
    }
    .featured-header{
        display: flex;
        align-items: center;
        border-bottom: solid 1px #e2e6ee;
        .img-wrapper{
            display: flex;
            justify-content: center;
            align-content: center;
            width: 44px;
            height: 34px;
            background-color: #f5a623;
            img{
                width: 100%;
                max-width: 16px;
            }
            
        }
        .title{
            font-family: Roboto;
            font-size: 14px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.14;
            letter-spacing: normal;
            color: #1f273c;
            padding: 0 16px;
        }

    }
    .event-body{
        padding: 16px 19px;
    }
    .event-title{
        font-family: Roboto;
        line-height: 24px;
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #1b7beb;
    }
    .event-image{
        display: block;
        margin-right: 16px;
        height: 127px;
        width: 100%;
        max-width: 223px;
        border-radius: 8px;
        border: solid 1px #e2e6ee;
        img{
            min-width: 221px;
            border-radius: 8px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    @media(max-width: 767px){
        .event-image{
            margin: auto;
        }
    }
    .tag{
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        padding: 8px 16px;
        margin-right: 8px;
        border-radius: 4px;
        border: solid 1px #e2e6ee;
        background-color: #fbfbfc;
    }
    .more{
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: #43a7ed;
        padding: 8px 16px;
        border-radius: 4px;
        border: solid 1px #e2e6ee;
        background-color: #fbfbfc;  
    }
    .show-more-info{
        cursor: pointer;
        display: inline-block;
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: #1b7beb;
    }
    .general-info{
        > .row{
            margin-bottom: 8px;
        }
        .info-holder{
            img{
                margin-right: 1em;
            }
            padding-right: 1em;
        }
        .likes{
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: normal;
            justify-content: flex-end;
            margin: auto 0px;
        }
        .time-left{
            padding: 8px 0;
            display: inline-block;
            font-family: Roboto;
            font-size: 14px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.14;
            letter-spacing: normal;
            color: #3f4d6e;
        }
        .content{
            padding-top: 8px;
            font-family: Roboto;
            max-height: 32em;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 2;
            letter-spacing: normal;
            color: #3f4d6e;
            transition: max-height 0.5s ease 0s; 
            overflow: hidden;
            &.hidden{
                max-height: 0px;
                overflow: hidden; 
                transition: max-height 0.5s ease 0s; 
            }
        }
    }
    .tag-col {
        max-height: 35px;
        overflow: hidden;
        
    }
    .AddtoCalendarDropdown img {
        margin-bottom: 3px;
    }

    @media (min-width: 768px)  {
        .event-body {
            padding: 20px 16px 16px;
        }   
        .general-info {
            .row {
                margin-bottom: 2px;
            }
            .likes {
                padding-right: 0px;
            }
        }     
    }


    @media(max-width: 767.98px){
        .divider{
            height: 1px;
            border: solid 1px #e2e6ee; 
            width: 100%;
            margin: 8px 0px 16px;
        }
        .event-body{
            padding: 16px;
            
            .event-calendar{
                margin-bottom: 8px;
            }
            .info-holder{
                margin-bottom: 8px;
            }
        }
        .AddtoCalendarDropdown img {
            margin-bottom: 4px;
        }      
        .info-holder img {
            margin-bottom: 3px;
        }
        .tag-header {
            color: #f5a623;
        }
        .tag {
            border: none;
            background-color: transparent;
            color: #838da4;
            text-transform: capitalize;
            margin-bottom: 2px;
            padding: 0px 0px 0px 8px;
            margin-right: 0px;
        }
        .event-title{
            padding-top: 8px;
        }
    }

}
.tippy-content{
    ul{
       margin: 0px; 
    }
    
}