.Smart-Finder{
    /* Smart finder */

.welcome-screen{
    height: 433px;
    background-color: #1161be;
    background-image: url('/images/search-field-pattern.svg');
    margin-bottom: 32px;
    .text-box{
        h1{
            font-family: 'Archivo';
            font-size: 44px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.09;
            letter-spacing: normal;
            color: #ffffff; 
        }
        p{
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 2;
            letter-spacing: normal;
            color: #cee1f7;
        }
    }
}
.ad-full-w{
        height: 376px;
        border-radius: 6px;
        background-color: #efeff5;
        margin-bottom: 32px;
}
.ad-mini-w{
        height: 324px;
        border-radius: 6px;
        background-color: #efeff5;
        margin-bottom: 32px;
}

.Market-Trend{  
    background-color: white;
    margin-bottom: 32px;
    border: 1px solid #e2e6ee;
    border-radius: 6px;
    .content-header { 
        border-bottom: 1px solid #e2e6ee;
        .ranking {
            margin-left:-15px;
            display: inline-block;
            border-radius: 6px 0px 0px 0px;
            background-color: #ff4848;
            padding: 13px 13px 16px;
        }
        .title{       
            color: #1f273c;
            margin: 0px;
            font-size: 16px;
            line-height: 1.14;
            font-weight: bold;
            letter-spacing: normal;
            text-transform: none;
        }
    }
    .chart-content{
        min-height: 367px;
    }
}

.Company-Leaderboard  {
    margin-bottom: 32px;
    table{
        border-collapse: separate;
        border-radius:6px; 
        .table-header{
             tr{
                min-height: 0;
                height: auto;
             }
             th {
                padding: 0;
                padding-left:15px;
                padding-right:15px;
            }
            .title{
                font-style: normal;
                font-stretch: normal;
                letter-spacing: normal;
                color: #1f273c;
                margin:0px;
                font-size: 16px;
                line-height: 1.14;
                letter-spacing: normal;
                text-transform: none;
            };
            .g-chart-1{
                margin-left:-15px;
                border-radius: 6px 0px 0px 0px;
                background-color: #22c0c2;
                padding: 13px 13px 16px;
            }
        }
        tbody td {
            a{
                line-height: 2.29;
                color: #1b7beb;
            }
            span{
                line-height: 1.71;
                color: #3f4d6e;
            }
        }


    }
}
    .find-investments{
        background-color: white;
        margin-bottom: 32px;
        border: 1px solid #e2e6ee;
        border-radius: 6px;
        height: 112px;
        .globe{
            padding: 18px;
            border-radius: 4px;
            border: 1px solid #e2e6ee;
            margin-left:24px;
        }
        .title{
            margin: 0;
            font-family: 'Archivo';
            font-size: 18px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: #263049;
        }
        .text{
            font-family: 'Roboto';
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.71;
            letter-spacing: normal;
            color: #3f4d6e;
        }
        .find-btn{
            color: white;
            border-radius: 4px;
            background-color: #1b7beb;
            height: 48px;
            border-radius: 4px;
            background-color: #1b7beb;
            width: 170px;
            padding:5px;
            line-height: 2.29;
            margin-right:24px;
        }
    }
    .Featured-Use-Cases{
        background-color: white;
        margin-bottom: 32px;
        border: 1px solid #e2e6ee;
        border-radius: 6px;
        .content-header { 
            border-bottom: 1px solid #e2e6ee;    
            .cases {
                margin-left: -15px;
                display: inline-block;
                border-radius: 6px 0px 0px 0px;
                background-color: #eda643;
                padding: 13px 13px 16px;
                
            }
            .title{       
                color: #1f273c;
                margin: 0px;
                font-size: 16px;
                line-height: 1.14;
                font-weight: bold;
                letter-spacing: normal;
                text-transform: none;
            }
        }
        .examples {
            padding: 32px 24px;
        }
        .examples .case-img {
            background-image: url("/images/bitmap.jpg");
            height: 200px;
            margin-bottom: 30px;
        }
        .examples h5 {
            margin-bottom: 15px;
        }
        .examples p {
            line-height: 1.71;
        }
    }
    



.Smart-Cities-News{ 
    background-color: white;
    margin-bottom: 32px;
    border: 1px solid #e2e6ee;
    border-radius: 6px;
    margin-bottom: 30px;
    .content-header { 
        border-bottom: 1px solid #e2e6ee;    
        margin-bottom: 32px;   
        .document-icon {
            margin-left: -15px;
            display: inline-block;
            border-radius: 6px 0px 0px 0px;
            background-color: #43a7ed;
            padding: 13px 13px 16px;
        }
        .title{       
            color: #1f273c;
            margin: 0px;
            font-size: 16px;
            line-height: 1.14;
            font-weight: bold;
            letter-spacing: normal;
            text-transform: none;
        }
    }
    .news{
        padding: 0px 24px;
        margin-bottom: 32px;
        border-bottom: 1px solid  #e2e6ee;
        &:last-child{
          border:none;  
        }

        h5{
            font-family: 'Archivo';
            font-size: 16px;
            font-weight: 500;
            line-height: 2;
            color: #263049;
        }
        p{
            font-size: 14px;
            line-height: 1.71;
            color: #3f4d6e;
        }
    }
    .date{
        span{
            margin:1em;
            font-size: 12px;
            line-height: 2;
            color: #3f4d6e;
        }
    }
    .news-img {
        img{
           width: 100%; 
        }
        
    }

  


    
}

.Smart-Cities-Events{
    background-color: white;
    margin-bottom: 32px;
    border: 1px solid #e2e6ee;
    border-radius: 6px;
    margin-bottom: 30px;
.content-header { 
    border-bottom: 1px solid #e2e6ee;       
    .calendar-2-icon {
        margin-left: -15px;
        display: inline-block;
        border-radius: 6px 0px 0px 0px;
        background-color: #a485f3;
        padding: 13px 13px 16px;
        
    }
    .title{       
        color: #1f273c;
        margin: 0px;
        font-size: 16px;
        line-height: 1.14;
        font-weight: bold;
        letter-spacing: normal;
        text-transform: none;
    }
}
.events{
    padding: 32px 24px;
    border-bottom: 1px solid  #e2e6ee;
        &:last-child{
          border:none; 
          margin-bottom: 0; 
        }
    h5{
        font-family: 'Archivo';
        font-size: 16px;
        font-weight: 500;
        line-height: 2;
        color: #263049;
    }
    p{
        font-size: 14px;
        line-height: 1.71;
        color: #3f4d6e;
        margin-bottom:4px;
    }
}
.date{
    span{
        margin:1em;
        font-size: 12px;
        line-height: 2;
        color: #3f4d6e;
    }
}

.add-to-wrapper{
    margin-right:0.5em;
    line-height: 1.7;
    display: inline-block;
    text-align: center;
    width: 24px;
    height: 24px;
    border: 1px solid;
    border-radius:5em;
    margin-bottom: 32px;

    .plus{
        font-size: 14px;
    }
}
.events-img {
    img{
       width: 100%; 
    }
    
}

}
.Market-Research-Reports{
    background-color: white;
    margin-bottom: 32px;
    border: 1px solid #e2e6ee;
    border-radius: 6px;
    margin-bottom: 30px;
.content-header { 
    border-bottom: 1px solid #e2e6ee;    
    margin-bottom: 32px;   
    .single-content-03 {
        margin-left: -15px;
        display: inline-block;
        border-radius: 6px 0px 0px 0px;
        background-color: #65d12f;
        padding: 13px 13px 16px;
    }
    .title{       
        color: #1f273c;
        margin: 0px;
        font-size: 16px;
        line-height: 1.14;
        font-weight: bold;
        letter-spacing: normal;
        text-transform: none;
    }
}
.reports{
    padding: 0px 24px;
    margin-bottom: 32px;
    border-bottom: 1px solid  #e2e6ee;
        &:last-child{
          border:none; 
          margin-bottom: none; 
        }
    h5{
        font-family: 'Archivo';
        font-size: 16px;
        font-weight: 500;
        line-height: 2;
        color: #263049;
    }
    p{
        font-size: 14px;
        line-height: 1.71;
        color: #3f4d6e;
        
    }
}
.date{
    span{
        margin:1em;
        font-size: 12px;
        line-height: 2;
        color: #3f4d6e;
    }
}


.reports-img {
    img{
       width: 100%; 
    }
    
}

}

.subscribe {
    height: 317px;
    background-color: #1161be;
    background-image: url('/images/subscribe.svg');
    text-align: center;

    h2 {
      font-size: 34px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.18;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
    }

    p {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: center;
      color: #cee1f7;
    }
    a {
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 2.29;
        letter-spacing: normal;
        color: #263049;
        border-radius: 4px;
        box-shadow: 0 5px 10px 0 rgba(9, 52, 103, 0.13);
        background-color: #ffffff;
        padding: 8px 52px;
      }

    }
}
