.Filter{
    .react-select-container {
        input {
            opacity: 1 !important;
        }
    }
    @media(max-width: 767.98px) {
        .small-date .DayPicker__withBorder{
            margin: auto !important;
        }
        .small-date div{
            max-width: unset;
        }
        .location-filter{
            padding: 16px 16px;
            border-bottom: 1px solid #e2e6ee;
        }
        .location-options{
            border-bottom: 1px solid #e2e6ee;
            padding: 16px 20px;
            .grey-text{
                font-size: 10px;
                color: #8f939d;
                line-height: 1.8;
                margin-left: 5px;
            }
        }
    }
    .dark-blue{
        color: #1161be;
    }
    .update-mobile-checkbox{
        border: unset;
        margin: 0 -15px;
    }
    .filter{
        .event-type-images{
            margin-left: 20px;
        }
    }
    .pointer{
        cursor:pointer;
    }
    .row-pad {
        height: 55px;
    }
    .margin-b-20{
        margin-bottom: 20px;
    }
    .divider{
        height: 1px;
        background: #e2e6ee;
    }
    .custom-control{
        display: flex;
        padding: 0px;
        min-height: auto;
        width: 100%;
    }
    .custom-control-label {
        width: 100%;
    }
    .custom-control-label:before{
        left: 95%;
        width: 22px;
        height: 22px;
        border: solid 1px #e2e6ee;
        background-color: #fbfbfc;
        display: inline-block;
        background-color: transparent;
        background-image: none;
        top: 0px;
        border-radius: 2px;
        display: none !important;
        
    }
    .custom-checkbox .custom-control-input:checked~.custom-control-label::before{
        left: 95%;
        width: 22px;
        height: 22px;
        background-color: #ffffff;
        border: solid 1px #e2e6ee;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center right;
        top: 0px;
        border-radius: 2px;
        display: none !important;
    }
    .custom-checkbox .custom-control-input:checked~.custom-control-label::after{
        left: 95%;
        background-image:url('/images/check.svg');
        top: 0px;
        width: 22px;
        height: 22px;
        display: none !important;
    }
    .location-filter, .date-filter {
        margin-left: -15px;
        margin-right: -15px;
    }
}
// .CalendarMonthGrid_month__hidden{
//     visibility: visible !important;
// }
// .small-date .DateRangePicker_picker{
//     height: unset !important;
//     max-height: unset !important;
//     div{
//         height: unset !important;
//     max-height: unset !important;
//     }
// }