/* Search */
.search-grid{
    .no-data{
        margin: 50px auto;
        display: flex;
        font-size: 20px;
        font-family: Roboto;
        font-weight: 500;
        font-style: normal;
        @media(max-width: 767px){
            font-size: 16px;
        }
    }
    .search-field {
        position: relative;
        height: 121px;
        background-color: #1161be;
        background-image: url('/images/search-field-pattern.svg');
        z-index: 1;
    }
    .top-panel{
        display: flex;
        position: relative;
        height: 121px;
        background-color: #1161be;
        background-image: url('/images/events-search-pattern.svg');
        align-items: center;
    }
    @media(max-width: 767px) {
        .top-panel {
            height: auto;
            padding: 24px;
            margin-bottom: 0px;
        }
    }
    .companies-title-block{
        height: 24px;
        font-family: Archivo;
        font-size: 24px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        margin: 0 auto;
    }
    .search-box {
        border-radius: 4px;
        box-shadow: 0 5px 10px 0 rgba(9, 52, 103, 0.13);
        height: 40px;
    
        .search {
            color: white;
            border-radius: 4px;
            background-color: #1b7beb;
            margin: 4px;
            padding: 0 24px;
            height: 34px;
        }
    
        input, input:focus {
            margin-left: 30px;
            border: none;
            box-shadow: none;
            width: 100%;
        }
    
        .image-wrapper {
            width: 100%;
        }
    
        .image-wrapper::before {
            content: url('/images/zoom.svg');
            position: absolute;
            top: 12px;
            left: 24px;
        }
    
        .button-wrapper {
            text-align: right;
            padding-right: 0px;
        }
    }

    .full-profile-toggle {
        margin-top: 5px;
        display: flex;
        align-items: center;
        color: white;
        padding: 0px;
        justify-content: space-between;

        label {
            margin: 0px 5px;
        }
    }
    
    .filter-buttons {
        text-align: right;
    
        .btn {
            color: white;
            border-radius: 4px;
            border: #a1c0e4 solid 1px;
            background-color: transparent;
            margin: 4px;
            padding: 8px 19px;
            margin: 0;
            height: 40px;
        }
    
    
        .btn.left {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    
        .btn.left.active {
            border-color: white;
            border-right: 1px solid;
        }
    
        .btn.right {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
        }
    
        .btn.right.active {
            border-color: white;
        }
    }
    
    .advanced-form {
        padding: 14px 0px;
        background-color: white;

        .filters-trigger {
            position: absolute;
            top: 0%;
            margin: 0 auto;
            left: 50%;
            transform: translate(-50%, -50%);
            background: white;
            border: 1px solid #e3e7ef;
            border-radius: 5px;;
        }
    
        .control-filters {
            text-align: left;
        }
    
        input, select {
            border: 1px solid #e3e7ef;
        }
    
        input:focus, select:focus {
            box-shadow: none;
        }

        h6 {
            margin-left: 8px;
        }
    }
    
    .break {
    
        ::after {
            content: ' ';
            width: 8px;
            height: 2px;
            background-color: #e3e7ef;
            display: block;
            position: absolute;
            top: 18px;
            right: -4px;
        }
    
        :last-child::after {
            width: 0px;
            height: 0px;
        }
    
    }
}