.CalendarItem{
    border-radius: 6px;
    box-shadow: 0 10px 15px 0 rgba(41, 74, 111, 0.07);
    border: solid 1px #e2e6ee;
    background-color: #ffffff;
    margin-bottom: 24px;
    .calendar-month{
        padding: 15px 60px 18px 60px;
        border-radius:6px 6px 0 0;
        background-image: linear-gradient(to right, #384afe, #1614ad);
        font-family: Quicksand;
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.89;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
    }
    .calendar-content{
        padding: 25px;
        padding-bottom: 15px;
        .calendar-image{
            height: 90px;
            width: 100%;
            text-align: center;
            margin:0 auto 25px auto;
            img{
                max-width: 160px;
                max-height: 100%;
            }
        }
        .calendar-events{
            padding: 2px 0;
            border-radius: 4px;
            background-color: #682cff;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.71;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            margin-bottom: 6px;
        }
        .calendar-webinars{
            padding: 2px 0;
            border-radius: 4px;
            background-color: #22c0c2;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.71;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
        }
        .view-all{
            font-family: Roboto;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: center;
            color: #1b7beb;
            margin-top: 15px;
            text-align: center;
        }
        
    }
    .white-color{
        color: white;
    }
    @media(max-width: 414px) {
        .calendar-content{
            .calendar-image{
                margin: auto;
            }
        }
    }
}