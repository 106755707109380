.Article {
  // @import 'https://community.iotnation.com/wp-content/themes/flatsome/css/foundation.css?ver=2.8.1';
  .main-image {
    width: 100%;
    max-width: 690px;
    object-fit: contain;
  }
  .Information {
    padding-top: 24px;
    padding-bottom: 4px;
    .max-width {
      max-width: 690px;
    }
    .porster-img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .poster-name {
      font-family: Archivo;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #263049;
    }
    .poster-date {
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: #3f4d6e;
    }
    .main-title {
      width: 720px;
      font-family: Archivo;
      font-size: 32px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      color: #263049;
      margin-bottom: 27px;
    }
    .poster-title {
      font-family: Archivo;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #1f273c;
      margin-top: 24px;
      margin-bottom: 24px;
    }
    .context {
      @import "./components/wordpressStyle.scss";
      @import "./components/a.scss";
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      max-width: 690px;
    }
  }
  .Quote {
    padding: 24px 0;
    background-color: #ffffff;
    .quote-img {
      margin-top: 40px;
      margin-bottom: 32px;
      height: 38px;
      width: 44px;
    }
    .main-quote {
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: italic;
      line-height: 2;
      letter-spacing: normal;
      text-align: center;
      color: #3f4d6e;
      margin-left: 59px;
      margin-right: 59px;
    }
    .author {
      font-family: Archivo;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #263049;
      margin-top: 32px;
      margin-bottom: 34px;
      text-align: center;
    }
  }
  .RelatedArticles {
    margin-bottom: 40px;
    .title {
      padding-top: 24px;
      font-family: Archivo;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #1f273c;
    }
    .articles-row {
      max-width: 720px;
      .articles-item {
        margin-top: 50px;
        .image {
          .no-image {
            width: 134px;
            height: 134px;
          }
          img {
            height: 134px;
            width: 134px;
            object-fit: cover;
          }
          margin-right: 24px;
        }

        .description-article {
          position: relative;
          width: calc(100% - 156px);
          .text {
            height: 96px;
            overflow-y: hidden;
            overflow-x: hidden;
            font-family: Archivo;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: #263049;
          }
          .date {
            opacity: 0.5;
            font-family: Archivo;
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: normal;
            color: #263049;
            position: absolute;
            bottom: 0px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .Information {
      padding-top: 16px;
      .main-title {
        width: unset;
        font-family: Archivo;
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        color: #1f273c;
        margin-bottom: 27px;
      }
      .context {
        width: unset;
        font-size: 14px;
      }
    }
    .RelatedArticles {
      .title {
        font-size: 20px;
      }
      .articles-row {
        .articles-item {
          margin-top: 24px;
          .image {
            .no-image {
              height: 89px;
              width: 89px;
            }
            img {
              height: 89px;
              width: 89px;
              object-fit: cover;
            }
          }

          .description-article {
            .text {
              height: unset;
              font-size: 15px;
            }
            .date {
              position: unset;
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
}
