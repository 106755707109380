.popoverAdvanced{
    top: 0px !important;
    left: 15px !important;
}
.results{
    .text-align-end{
        text-align: end;
    }
    
     th:first-child {
      padding: 17px 23px;
      cursor: pointer;
    }    

    .tags{
        color:#007bff;
        font-family: 'Roboto';
        font-size: 14px;
        line-height: 1.14;

    }


    .pagination {
        display: flex;
        margin-top:1em;
        align-items: center;
        justify-content: center;
        .rc-pagination-disabled {
            cursor: not-allowed;
        }

        .rc-pagination-item {
            background-color: transparent;
            cursor: pointer;
            border: none;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 600;
            color: #3f4d6e;
            padding: 8px;
            margin-right:0.25em;
            &:focus{
                outline: none;
            }
            &.rc-pagination-item-active a{
                color: #2983ec;
                background-color: transparent
            }
        }
        .rc-pagination-jump-next, .rc-pagination-jump-prev{
            cursor: pointer;
            color: #666;
            font-size: 1em;
            border-radius: 6px;
            list-style: none;
            min-width: 28px;
            height: 28px;
            line-height: 28px;
            float: left;
            text-align: center;
            &:after{
                content: "\2022\2022\2022";
                display: block;
                letter-spacing: 2px;
                color: #ccc;
                font-size: 12px;
                margin-top: 1px;
            }
        }
        .rc-pagination-jump-next:hover:after {
            content: "\BB";
        }
        .rc-pagination-jump-prev:hover:after {
            content: "\AB";
        }
        .rc-pagination-jump-prev:hover:after, .rc-pagination-jump-next:hover:after {
            font-size: 1em;
            color: #2983ec;
        }

        .rc-pagination-options-quick-jumper{
            margin-left:1em;
            display: flex;
            align-items: center;
             input{
                display: inline-block;
                width: 4rem;
                height: calc(2rem + 2px);
                padding-left:0.25em; 
                font-size: 1rem;
                line-height: 1.5;
                color: #495057;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                &:focus{
                    outline: none;
                }

            
            }
            span{
                display: inline-block;
                button{
                    cursor: pointer;
                    color: white;
                    border-radius: 4px;
                    background-color: #1b7beb;
                    padding: 0 26px;
                    height: 34px;
                    border:none;
                    &:focus{
                        outline: none;
                    }
                }
            }
            

        }
        .pagination-button {
          height: 7px;
          width: 11px;
          padding: 19px;
          border: 1px solid #e2e6ee;
          background-color: white;
          border-radius: 4px;
        }
        .rc-pagination-prev:focus, .rc-pagination-next:focus{
            outline: none;
        }
        .pagination-button.previous{
            cursor: pointer;
            background-image: url('/images/previous.svg');
            background-repeat: no-repeat;
            background-position: 50% 50%;
            &:focus{
                outline: none;
            }
          }
          
        .pagination-button.next {
            cursor: pointer;
            background-image: url('/images/next.svg');
            background-repeat: no-repeat;
            background-position: 50% 50%;
            
            &:focus{
                outline: none;
            }
        }
    }
}

td.ellipse {
    overflow: hidden;
    text-overflow: ellipsis;
}