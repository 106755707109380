.Tags{
    margin-bottom: 31px;
    .description{
        background-color: white;
        border-left: solid 1px #e2e6ee;
        border-right: solid 1px #e2e6ee;
        border-bottom: solid 1px #e2e6ee;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .tag{
        padding: 8px 16px;
        border-radius: 4px;
        border: solid 1px #e2e6ee;
        background-color: #fbfbfc;
        margin-right: 8px;
        margin-bottom: 8px;
    }
    .Verticals{
        padding: 24px 24px 16px 24px;
        border-bottom: solid 1px #e2e6ee;
    }
    .Technologies{
        padding: 24px 24px 16px 24px;
    }
}