.icons{
    height: 25px !important;
    width: 25px !important;
    margin: 4px;
    color:orange !important;
    .social-svg-mask{
        fill: 	rgb(255,165,0) !important;
    }
}
@media (min-width: 992px){
    .flex-1-of-5{
        flex: 0 0 20%;
        max-width: 20%;
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }
}

.last-p {
    line-height: 1rem;
}

.linkedin-icon{
    margin-left: 0px;
}

.youtube-icon{
    margin-right: 0px;
}
footer {
    .container {
        padding: 64px 135px 0px;
        .caret-style{
            margin: auto 0 auto 10px;
            height: 1.5em;
        }
    }
    @media (max-width: 992px){
        .container{
            padding: 64px 24px 0px;
            .col-2, .col-md-3{
                padding-left: 4px;
                padding-right: 4px;
            }
        }
    }
    @media (max-width: 768px){
        .container {
            padding: 24px;
            .caret-style{
                margin: auto 0 auto 10px;
                height: 1.5em;
            }
        }
    }
    h5 {
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #263049;
        font-weight: 600;
        margin: 0px;

        a {
            color: #263049;
        }
    }

    ul {
        margin: 0 0 16px 0;
        padding: 0px;

        li {
            display: block;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.71;
            letter-spacing: normal;

            a.grey {
                color: #3f4d6e;
            }
           
        }
    }

    .footnote {
        border-top: 1px solid #e2e6ee;
        .container {
            padding: 45px 135px 64px 135px;
        }
        @media (max-width: 768px){
            .container {
                padding: 45px 64px 64px 64px;
            }
        }
        

        .copyright {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.71;
            letter-spacing: normal;
            color: #3f4d6e;
        }

        .data {
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 2;
            letter-spacing: normal;
            color: #3f4d6e;
        }

        .legal {
            text-align: center;
        }

        ul {
            margin: 0px;

            li {
                margin: 0px 12px;
                display: inline-block;
            }
        }
    }
    @media(max-width: 767px){
        .footnote .container{
            padding: 24px 9px 0px;
        }
    }
}
.horizontal-line {
    height: 18px;
    width: 2px;
    background-color: #e2e6ee;
}
.fit-content {
    width: fit-content;
}