.half_mark{
    width: 16px;
    height: 16px;
    border-radius: 50%;
}
.selected-visitor-mark{
    background: linear-gradient( -90deg, white 50%, #01b869 50% );
    border: 1px solid #01b869;
}
.selected-registered-mark{
    background: linear-gradient( -90deg, white 50%, #ffb24e 50% );
    border: 1px solid #ffb24e;
}
.selected-pro-mark{
    background: linear-gradient( -90deg, white 50%, #ff6d01 50% );
    border: 1px solid #ff6d01;
}
.selected-enterprise-mark{
    background: linear-gradient( -90deg, white 50%, #5f31d8 50% );
    border: 1px solid #5f31d8;
}
.list{
    list-style: none;
}
