.iot-rank {
    color: #a485f3;
}

.iot-rank-line {
    margin-left: 8px;
    background-color: #a485f3;
    height: 6px;
    max-width: 100%;
    display: inline-block;
    border-top-right-radius: 3.5px;
    border-bottom-right-radius: 3.5px;
    position: relative;
    top: -1px;
}