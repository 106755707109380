.Gallery{
    .gallery-title{
        padding-top: 32px;
        padding-bottom: 20px;
        text-transform: capitalize;
        font-family: Archivo;
        font-size: 26px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.18;
        letter-spacing: normal;
        color: #263049;
    }
    img, .fake-img{
        width: calc(100% - 20px);
        height: 223px;
    }
    
    .margin-l-10{
        margin-left: 10px;
    }
    .margin-l-20{
        margin-left: 20px;
    }
    .image-description{
        width: calc(100% - 20px);
        a:hover{
            color: black;
        }
    }
    .gallery-image{
        margin-bottom: 47px;
        .description-title{
            margin-top: 16px;
            margin-bottom: 8px;
            font-family: Archivo;
            font-size: 18px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.78;
            letter-spacing: normal;
            color: #263049;
        }
        .description-text{
            font-family: Roboto;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            height: 67px;
            overflow-y: hidden;
            overflow-x: hidden;
        }
        .description-date{
            text-transform: uppercase;
            margin-top: 16px;
            font-family: Archivo;
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: normal;
        }
    }
}
.Blog-gallery{
    // .react-multi-carousel-track {
    //     transform: none !important;
    // }
    height: calc(100% - 60px);
    padding: 0;
    .react-multiple-carousel__arrow--right{
        top: 100px;      
    }
    .react-multiple-carousel__arrow--left{
        top: 100px;
    }
    button:focus{
        outline: unset;
    }
}