.Companies-involved{
    margin-bottom: 31px;
    position: relative;

    .row{
        padding-bottom: 30px;
    }
    .col-3{
        margin: auto 0;
    }
    .description{
        background-color: white;
        border-left: solid 1px #e2e6ee;
        border-right: solid 1px #e2e6ee;
        border-bottom: solid 1px #e2e6ee;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        padding: 24px 26px 0px 26px;
    }
    .company-block{
        border: solid 1px #e2e6ee;
        border-radius: 6px;
        width: 192px;
        padding: 8px 0px;
        .image{
            border: solid 1px #e2e6ee;
            border-radius: 6px;
            height: 50px;
            width: 50px;
            margin: 0px 16px 0px 8px;
            display: flex;
            img{
                margin: auto;
                height: 48px;
                width: 48px;
            }
        }
        .text{
            display: flex;
            margin: auto 0px;
            width: 90px;
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.71;
            letter-spacing: normal;
            color: #1f273c;
        }
    }
    .not-login{
        -webkit-filter: blur(4px);
        filter: blur(4px);
    }
}