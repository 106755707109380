.SpeakerSide{
    margin-bottom: 15px;
    .boder-bottom{
        border-bottom: solid 1px #e2e6ee;
    }
    .img-wrap{
        width: 48px;
        height: 48px;
        margin-right: 15px;
        img{
            object-fit: cover;
            width: 100%;
            height: 100%; 
        }
    }
    .person-name{
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: #1f273c;
        margin-bottom: 10px;
        font-weight: bold;
    }
    .profesion{
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.50;
        letter-spacing: normal;
        color: #3f4d6e;
        margin-bottom: 7px;
    }
    .p-24{
        padding: 24px;
    }
    .block-padding{
        padding: 24px 24px 10px 24px;
        
    }

    .togglee{
        max-height: 100%;
        transition: .5s ease;
        display:block;
        &.hidden{
           .speakerBlock:nth-child(n+4){   
                transition: .5s ease;
                display: none;
            }  
        }  
     }
}