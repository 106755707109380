.Contacts{
    margin-bottom: 31px;
    .description{
        background-color: white;
        table{
            width:100%;
            table-layout: fixed;
            thead{

                tr:first-child{
                    border-top:0!important;
                }
            }
            tr{
                margin: 0px;
                border-top: 1px solid #e2e6ee!important; 
                td{
                    word-wrap: break-word;
                    font-size: 14px;
                    font-weight: 400;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.71;
                    letter-spacing: normal;
                    color: #1f273c;
                    padding-left: 24px;
                }  
            }
        }
    }
    
    .rectangle{
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        
        border: 1px solid #e4e6ed;
        .single-02-3 {
            padding: 12px 14px 12px 12px;
            object-fit: contain;
          }
    }
}