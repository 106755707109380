.ReportDownload{
    .info{
        padding-top: 30px;
        padding-bottom: 10px;
        .title{
            font-size: 32px;
            font-weight: 600;
            padding-bottom: 20px;
        }
        .medium-title{
            font-size: 22px;
            font-weight: 600;
        }
        .small-title{
            font-size: 18px;
        }
        .small-title-2{
            font-size: 16px;
        }
        .small-title, .small-title-2{
            padding-top: 10px;
            padding-bottom: 10px;
            a{
                font-weight: 600;
                color: black;
            }
        }    
    }
    .navigation-block{
        background-color: #007bff;
        padding-top: 40px;
        padding-bottom: 76px;
        .title{
            color: white;
            font-size: 28px;
            margin-top: 10px;
            font-weight: 600;
        }
        .navigation{
            img{
                display: flex;
                margin-left: auto;
                margin-right: auto;
                margin-top: 10px;
                margin-bottom: 10px;
                height: 100px;
                width: 100px;
            }
            a {
                text-decoration: unset;
                button{
                    background-color: orange;
                    color: white;
                    padding: 0.375rem 1.75rem;
                    display: flex;
                    margin-left: auto;
                    margin-right: auto;
                }  
            }
            
        }
    }
}