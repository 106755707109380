.CompanyBlock{
  border-radius: 6px;
  border: solid 1px #e2e6ee;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  padding:  5px;
  margin-bottom: 24px;
  min-height: 55px;
    min-width: 48px;
  img{
      height: 22px;
      opacity: 0.7;
      display: block;
      margin: 0 auto;
  }
  .width-75{
    width: 75%;
  }
  @media(max-width: 736px) {
    padding: 4px;
  }
  .width-75{
    width: 100%;
    height: auto;
  }
}
.CompanyBlock:hover{
  border-color: #1b7beb;
  img{
    opacity: 1.5; 
  }
}