.Competitions{
    @import './react-dates_style_overrides.scss'; 
    @import './react-select_styles_overrides.scss'; 
    .events-title-block{
        height: 24px;
        font-family: Archivo;
        font-size: 24px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        margin: 0 auto;
    }
    .post-block{
        margin-left: auto;
        margin-right: 0;
        padding-right: 15px;
        padding-left: 15px;
    }
    .hide{
        display:none;
    }

    .add-line{
        .DateRangePickerInput_arrow{
            display: block;
        }
    }
    .tag {
        margin-bottom: 5px;
    }

    .DateInput__small{
        min-width: 150px;
    }
    .CustomCheckBox{
        margin-right: 12px;
    }
    .CheckBoxSelect{
        .CustomCheckBox{
            margin-right: 16px;
        }
    }
    .top-panel{
        display: flex;
        position: relative;
        height: 121px;
        margin-bottom: 32px;
        background-color: #1161be;
        background-image: url('/images/events-search-pattern.svg');
        align-items: center;
    }
    .search-box {
        border-radius: 4px;
        box-shadow: 0 5px 10px 0 rgba(9, 52, 103, 0.13);
        height: 40px;
        .input-group {
            position: relative;
            .search-icon{
                width: 100%;
            }            
            input[type=search] {
                border: none;
                width: 100%;
                border-radius: 0.25rem;
                height: 40px;
                margin-left: 0px;
                padding-left: 40px;
                padding-right: 140px;
            }
            .input-group-prepend {
                position: absolute;
                right: 0px;
                margin: auto;
                z-index:9;
                .btn{
                    border-radius: 0.25rem;
                }
            }
        }        
        
        .search {
            color: white;
            border-radius: 4px;
            background-color: #1b7beb;
            margin: 3px;
            padding: 0 26px;
            height: 34px;
        }
    
        input, input:focus {
            margin-left: 30px;
            border: none;
            box-shadow: none;
            width: 100%;
        }
    
        .image-wrapper {
            width: 100%;
        }
    
        .image-wrapper::before {
            content: url('/images/zoom.svg');
            position: absolute;
            top: 12px;
            right: 24px;
        }
    
        .button-wrapper {
            text-align: right;
            padding-right: 0px;
        }
        .btn .search{
            cursor: pointer;
        }
    }
    @media(max-width: 767.98px) {
        .top-panel {
            height: auto;
            padding: 24px;
            margin-bottom: 0px;
        }
        .search-box {
            .input-group {
                input[type=search] {
                    padding-left: 10px;
                    padding-right: 55px;
                }
                .input-group-prepend {
                    .btn{
                        background-image: url('/images/zoom-2.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                        padding: 0px 20px;
                    }
                }
            }
        }

        .event-btn{
            width: 100%;
            .post-event{
                width: 100%;
            }
        }
        .filter-block {
            padding: 0px 16px;
            a {
                color: #1f273c;
                text-decoration: none;
            }
            a:active{
                text-decoration: none;
            }
            .badge{
                color: #fff;
                background-color: #1b7beb;
                font-weight: 500;
                padding: 0.4em;
                border-radius: 0.85em;
                margin-left: 13px;
                vertical-align: bottom;
                width: 1.9em;
                height: 1.75em;
            }
        }
        .mobile-filter{
            height: 50px;
            background: #fff;
            margin-bottom: 24px;
            border-bottom: 1px solid #e2e6ee;
            display: flex;
            align-items: center;
            .filter, .search-mobile{
                display: flex;
                border-right: 1px solid #e2e6ee;                
            }
            .listing-view {
                display: flex;
                div {
                    margin: auto;
                    img {
                        height: 20px;
                        width: 20px;
                    }
                }
            }
            .view {
                display: flex;
            }
            .pointer {
                margin: auto 8px;
                img {
                    padding-right: 14px;
                }
            }
            .search-mobile {
                border-right: 1px solid #e2e6ee;
            }
        }
        .top-screen{
            position: absolute;
            top: 0px;
            background-color: white;
            z-index: 1100;
            width: 100%;
            border-bottom: 1px solid #e2e6ee;
            height: 100vh;
            .pointer{
                cursor:pointer;
            }
            .row-pad {
                height: 55px;
                align-content: center;
            }
            .divider{
                height: 1px;
                background: #e2e6ee;
            }
            .custom-control{
                min-height: auto;
            }
            .custom-control-label:before{
                width: 16px;
                height: 16px;
                @media(max-width: 767px){
                    width: 22px;
                    height: 22px;
                }
                border: solid 1px #e2e6ee;
                background-color: #fbfbfc;
                display: inline-block;
                background-color: transparent;
                background-image: none;
                top: 0px;
                border-radius: 2px;
            }
            .custom-checkbox .custom-control-input:checked~.custom-control-label::before{
                width: 16px;
                height: 16px;
                @media(max-width: 767px){
                    width: 22px;
                    height: 22px;
                }
                background-color: #ffffff;
                border: solid 1px #e2e6ee;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center right;
                top: 0px;
                border-radius: 2px;
            }
            .custom-checkbox .custom-control-input:checked~.custom-control-label::after{
                background-image:url('/images/check.svg');
                top: 0px;
                @media(max-width: 767px){
                    width: 22px;
                    height: 22px;
                }
            }
            @media(max-width: 767px){
                .custom-control-label{
                    width: 100% !important;
                }
                .custom-control-label::before{
                    left: unset !important;
                    right: 0px !important;
                }
                .custom-control-label::after{
                    left: unset !important;
                    right: 0px !important;
                }
            }
            .location-filter, .date-filter {
                margin-left: -16px;
                margin-right: -16px;
                
            }
            .date-filter .col-md-4{
                padding: 0px;
                
            }
            .pc-calendar-select{
              .small-date .DayPicker_weekHeader {
                left: -9px !important;
                }
                .CalendarMonth {
                    padding: 0 0 100px 0px !important;
                }  
            }

            
        }
        .calendar-view {
            padding-top: 24px;
        }
        .ScrollUpButton__Container.ScrollUpButton__Toggled {
            display: none;
        }
    }

    .post-event{
        display: inline-block;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        height: 41px;
        border-radius: 6px;
        background-color: #f5a623;

    }

    .calendar-sidebar{
        border-radius: 6px;
        border: solid 1px #e2e6ee;
        background-color: #ffffff;
        border-radius: 6px;
        border: solid 1px #e2e6ee;
        background-color: #ffffff;
        .sidebar-header{
            display: flex;
            align-items: center;
            border-bottom: solid 1px #e2e6ee;
            .img-wrapper{
                display: flex;
                justify-content: center;
                align-content: center;
                width: 48px;
                height: 48px;
                border-radius: 6px 0 0 0;
                background-color: #f5a623;
                img{
                    width: 100%;
                    max-width: 24px;
                }
                
            }
            .title{
                font-family: Roboto;
                font-size: 16px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 1;
                letter-spacing: normal;
                color: #1f273c;
                padding: 0 16px;
            }

        }
        .event{
            display: flex;
            border-bottom: solid 1px #e2e6ee;
            padding: 17px 0;
            .img-wrapper{
                display: block;
                max-width: 48px;
                min-width: 48px;
                text-align: center;
                margin-top: 7px;
                img{
                    width: 24px;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .event-info{
              padding: 0 17px;
            }
            .event-title{
                font-family: Roboto;
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.14;
                letter-spacing: normal;
                color: #1f273c;
                margin-bottom: 8px;
            }
            .event-date{
                font-family: Roboto;
                font-size: 12px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.33;
                letter-spacing: normal;
                color: #3f4d6e;
            }
            &:last-child{
                border-bottom: 0px;
            }
        }
    }
    .main{
   
    }
    .filters-panel{
        margin-bottom: 16px;
        .filter{
            display: flex;
            align-items: center;
            height: 40px;
            border-radius: 4px;
            box-shadow: 0 1px 2px 0 rgba(101, 114, 148, 0.08);
            border: solid 1px #e3e7ef;
            background-color: #ffffff;
            margin-right: 24px;
            padding: 0 16px;
            color: #3f4d6e;
            
            &:last-child{
                margin-right:0px;
            }
            &.active{
                border-radius: 4px 4px 0 0;
            }
            &.like, &.more{
                padding: 0 16px;
                justify-content: center;
            }
            &.like.active{
                border: solid 1px #1b7beb;
                background-color: #ffffff;
                border-radius: 4px;
                
            }
        }
        .more-filters{
            z-index:2147483001;
            position: fixed;
            top: 0;
            right:0;
            width:(100% / 6);
            height: 100vh;
            box-shadow: -6px 1px 10px 0 rgba(101, 114, 148, 0.08);
            border: solid 1px #e3e7ef;
            background-color: #ffffff;
            .filter.open{
                width: 100%;
                padding: 20px 16px;
                height: unset;
                border-radius: 0;
                border: 0;
                border-bottom: solid 1px #e3e7ef;
            }
            .filters-container{
                padding: 24px 16px;
                .filter{
                    width: 100%;
                    margin-bottom: 8px;
                }
            }
            .actions{
                position: absolute;
                width: 100%;
                bottom: 0;
                padding: 16px;
                display: flex;
                border-top: solid 1px #e3e7ef;
                .btn{
                    width: 100%; 
                }
                .close-btn{
                    border-radius: 6px;
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: bold;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.71;
                    letter-spacing: normal;
                    text-align: center;
                    color: #222a3f;
                    border: solid 1px #e2e6ee;
                    margin-right: 11px;
                }
                .apply-btn{
                    border-radius: 6px;
                    background-color: #1b7beb;
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: bold;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.71;
                    letter-spacing: normal;
                    text-align: center;
                    color: #ffffff;
                }
            }
        }
    }
    .righter{
        .ad{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 24px;
            height: 250px;
            border-radius: 6px;
            background-color: #efeff5;

        }

    }
    .subscribe {
        
        background-color: #1161be;
        background-image: url('/images/subscribe.svg');
        text-align: center;
        margin: 0px;
        h2 {
          font-size: 34px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.18;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
          margin-bottom: 18px;
        }
  
        p {
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 2;
          letter-spacing: normal;
          text-align: center;
          color: #cee1f7;
        }
  
        .btn {
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.29;
          letter-spacing: normal;
          border-radius: 4px;
          box-shadow: 0 5px 10px 0 rgba(9, 52, 103, 0.13);
          background-color: #ffffff;
          padding: 8px 52px;
          margin-top: 40px;
          color: #263049;
          
        }
    }

    .CalendarDay__blocked_out_of_range {
        opacity: .3;
    }
  
    .DayPicker__withBorder div {
        max-width: 447px;
    }
    .DayPicker_weekHeader:last-child {
        left: 213px !important;
    }
    .small-date{
        .ClearButton{
            svg{
                height: 40px;
                width:  40px;
            }
        }
        .DateRangePicker_picker{
            width: 100% !important;
        }
        .DayPicker__horizontal{
            width: 100% !important;
        }
        .DateInput__small{
            width: 100% !important;
            &:first-child{
                border-bottom: 1px solid #e2e6ee;
            }
        }
        .DayPicker__withBorder{
            margin: 0 -15px;
        }
        .DateInput_input__small{
            padding: 16px 0 16px 16px;
            border: unset;
        }
        .DateRangePickerInput_arrow{
            display: none;
        }
        .DayPicker_transitionContainer{
           height: 465px !important; 
        }
        .DayPicker__withBorder div{
            margin: 0px !important;
            width: 100% !important;
            // margin: 0 -15px;
            .prev{
                width: unset !important;
            }
            .next{
                width: unset !important;
            }
        }
        .DayPicker_transitionContainer {
            width: 100% !important;
        }
        .DayPicker_weekHeader{
            left: 0px !important;
            display: flex !important;
            ul{
                margin: 0 auto !important;
            }
        }
        .DateRangePickerInput{
            margin: 0 -15px;
            display: block !important;
            height: unset !important;
        }
        .CalendarMonth_table{
            display: flex !important;
            tbody{
                margin: 0 auto !important; 
            }
        }
        .CalendarMonth{
            padding: 0px !important;
        }
        
    }
    .CalendarMonthGrid_month__horizontal{
        height: 250px;
    }

}