.CustomSelect{
    position: relative;
    padding: 0!important;
    .header{
       padding: 0 16px;
       display: flex;
       align-items: center;
       width: 100%;
       height: 100%;
    }
    .options{
        display: none;
        background-color: #ffffff;
        position: absolute;
        left: -1px;
        right: -1px;
        top: calc(100% + 1px);
        z-index: 1;
        border-radius: 0 0 4px 4px;
        box-shadow: 0 1px 2px 0 rgba(101, 114, 148, 0.08);
        border: solid 1px #e3e7ef;
        border-top: 0;
        .option{
            display: flex;
            padding: 8px 16px;
            &:first-child{
                padding-top: 16px;
            }
            &:last-child{
                padding-bottom: 16px;
            }
        }
    }
    &.active{
        .options{
            display: block;
        }
    }
}