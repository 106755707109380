.Results-Table-Eco-Stats{
    margin-bottom: 40px;
    color:#1b7beb;      
    max-width: 930px;
    .weekly-ch, .memberships{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size:14px;
        border-radius: 4px;
        margin-right:1em;
        border: 1px solid #e2e6ee;
        color:#3f4d6e;
        min-width: 48px;
        min-height: 32px; 
        &.positive{
            color: #65d12f;
            border: 1px solid #65d12f; 
        }
        &.negative{
            color:#ff4848;
            border: 1px solid #ff4848; 
        }

    }
    .dots-3{
        height: 30px;
        width: 30px;;
    }
    .pagination {
        margin: 30px;
    
        li {
            background-color: transparent;
            border: none;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 600;
            color: #3f4d6e;
            padding: 8px;
        }
        a {
            cursor: pointer;
        }
        a:hover {
            text-decoration: none;
        }
        a:focus {
            outline: none;
        }
        .active a {
            color: #2983ec;
            background-color: transparent
        }
        .pagination-button {
          height: 7px;
          width: 11px;
          padding: 19px;
          border: 1px solid #e2e6ee;
          background-color: white;
          border-radius: 4px;
        }
    
        .pagination-button.previous {
            background-image: url('/images/previous.svg');
            background-repeat: no-repeat;
            background-position: 50% 50%;
          }
          
        .pagination-button.next {
            background-image: url('/images/next.svg');
            background-repeat: no-repeat;
            background-position: 50% 50%;
        }

    }
}