.Speaker{
    .content{
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.6;
        letter-spacing: normal;
        color: #3f4d6e;
    }
    
     .m-0-4{
         margin: 0px 4px;
     }
    .speaker-image{
        padding-left: 15px;
        img{
            
            width: 80px;
            height: 80px;
        }
    }
    .speaker-info{
        margin-left: 10px;
    }
    .margin-right-10{
        margin-left: auto;
        margin-right: 10px;
    }
}
     