
    .modal{
        height: unset !important;
        top: 40%;
        border: 5px solid orange;
        justify-content: center;
        display: flex !important;
        left: 25%;
            width: 50%;
        min-height: unset;
        overflow-x: unset !important;
        overflow-y: unset !important; 
        background-color: white;

    }
    @media (max-width: 800px) {
        .modal {
            left: 10%;
            width: 80%;
        }
    }
    @media (max-width: 500px) {
        .modal {
            left: 5%;
            width: 90%;
        }
    }
    .text-underline{
        text-decoration: underline;
    }
    .margin-top-10{
        margin-top:10px;
    }
    .modal-dialog-centered::before {
        height: unset !important;
    }
    .modal-title{
        width: 100%;
        padding-top: 1rem;
    }
    .modal-header{
        padding: 0px 0px 0px 13px;
        border-bottom: unset !important;
        button{
            
            padding: 15px 17px 0px 0px !important;
        }
    }
    .modal-body{
        padding:  1rem 0px;
    }
    .modal-dialog{
        min-height: unset !important;
        margin: 0px  !important;
        width: 100% !important;
        max-width: unset !important;
    }
    .modal-content{
        height: 100% !important;
        border:none;
        .close:focus {
            outline: 0;
        }
    }
