.ActivationCode{
    margin-top: 50px;
    margin-bottom: 50px;
    .col-2{
        text-align: center;
    }
    .col-1{
        text-align: center;
    }
    .main-table{
        .row:first-child{
            padding-bottom: 15px;
        }
        .row{
            padding-bottom: 5px;
        }    
    }
}