@media(max-width: 767.98px) {
    .top_panel {
        height: 72px;
    }
    .title {
        font-size: 24px;
        line-height: 1;  
    }
}
.image_block {
    height: 122px;
    width: 220px;
}
.image {
    height: 100px;
}