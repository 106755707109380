.Sector{
    .CompanyLeaderboard{
        margin-top: 32px;
        position: relative;
        .description{
            background-color: white;
            border-left: solid 1px #e2e6ee;
            border-right: solid 1px #e2e6ee;
            border-bottom: solid 1px #e2e6ee;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.71;
            letter-spacing: normal;
            color: #3f4d6e;
            .block-company:last-child{
                border-bottom: unset;
            }
            .block-company{
                padding: 15px 24px 15px 24px;
                border-bottom: solid 1px #e2e6ee;
                .vertical-merge{
                margin: auto 0;
                }
                .link{
                    margin: auto;
                    width: 30px;
                }
                @media(max-width: 767px){
                    .link{
                        width: unset;
                        width: calc(100% - 95px);
                    }
                }
                .col-3{
                    padding-right: 0px; 
                }
                .vertical-merge-end{
                    margin: auto 0 auto auto;
                }
                .rank-img{
                    margin-left: 8px;
                    width: 10px;
                    height: 7px;
                }

                .text{
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.71;
                    letter-spacing: normal;
                    color: #3f4d6e; 
                    
                    max-width: 597px;
                }
                .company-img-block{
                    height: 48px;
                    width: 48px;
                    min-width:48px;
                    display: flex;
                    border: 1px solid #e2e6ee;
                    border-radius: 4px;
                    margin-left: 34px;
                    .company-image{
                        margin: auto;
                        height: 24px;
                        width: 24px;
                    }    
                }
                @media(max-width: 991px){
                    .company-img-block{
                        margin-left: 10px;
                        margin-right: 10px;
                    }
                }
            }
            @media(max-width: 767px){
                .block-company{
                    padding: 15px 16px;
                }
            }
            
        }
    }
}