.Events {
  @import "./react-dates_style_overrides.scss";
  @import "./react-select_styles_overrides.scss";

  .small-date {
    .ClearButton {
      svg {
        height: 40px;
        width: 40px;
      }
    }
    .DateRangePicker_picker {
      width: 100% !important;
    }
    .DayPicker__horizontal {
      width: 100% !important;
    }
    .DateInput__small {
      width: 100% !important;
      &:first-child {
        border-bottom: 1px solid #e2e6ee;
      }
    }
    .DayPicker__withBorder {
      margin: 0 -15px;
    }
    .DateInput_input__small {
      padding: 16px 0 16px 16px;
      border: unset;
    }
    .DateRangePickerInput_arrow {
      display: none;
    }
    .DayPicker_transitionContainer {
      height: 465px !important;
    }
    .DayPicker__withBorder div {
      margin: 0px !important;
      width: 100% !important;
      // margin: 0 -15px;
      .prev {
        width: unset !important;
      }
      .next {
        width: unset !important;
      }
    }
    .DayPicker_transitionContainer {
      width: 100% !important;
    }
    .DayPicker_weekHeader {
      left: 0px !important;
      display: flex !important;
      ul {
        margin: 0 auto !important;
      }
    }
    .DateRangePickerInput {
      margin: 0 -15px;
      display: block !important;
      height: unset !important;
    }
    .CalendarMonth_table {
      display: flex !important;
      tbody {
        margin: 0 auto !important;
      }
    }
    .CalendarMonth {
      padding: 0px !important;
    }
  }
  .add-line {
    .DateRangePickerInput_arrow {
      display: block;
    }
  }

  .DateInput__small {
    min-width: 150px;
    @media (max-width: 1199px) {
      min-width: 120px;
    }
  }
  .CustomCheckBox {
    margin-right: 12px;
    @media (min-width: 992px) and (max-width: 1199px) {
      margin-right: 8px;
    }
  }
  .CheckBoxSelect {
    .CustomCheckBox {
      margin-right: 16px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      margin-right: 8px;
    }
  }

  .search-box {
    .input-group {
      position: relative;
      input[type="search"] {
        border: none;
        width: 100%;
        border-radius: 0.25rem;
        height: 40px;
        margin-left: 0px;
        padding-left: 40px;
        padding-right: 140px;
      }
      .input-group-prepend {
        position: absolute;
        right: 3px;
        top: 3px;
        z-index: 9;
      }
    }
  }
  .view-controls {
    .view {
      width: 144px;
      height: 40px;
      line-height: 1.71;
      color: #a1c0e4;
      box-shadow: 0 5px 10px 0 rgba(9, 52, 103, 0.13);
      background-color: #1161be;
      border: solid 1px #a1c0e4;
      &:first-child {
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
      }
      &.active {
        margin: -1px;
        border: solid 1px #ffffff;
        color: #ffffff;
        & + .view {
          border-left: solid 1px #ffffff;
          margin-right: -1px;
        }
      }
    }
  }
  .post-event {
    line-height: 1.71;
    height: 40px;
    width: 144px;
  }
  .filters-sidebar {
    .filter {
      align-items: center;

      @media (min-width: 768px) {
        height: 40px;
        display: flex;
        position: relative;
      }

      border-bottom: solid 1px #e2e6ee;
      span {
        display: inline-block;
        width: 100%;
        @media (min-width: 768px) {
          img {
            right: 15px;
          }
        }
        @media (min-width: 1200px) {
          img {
            right: 20px;
          }
        }
        img {
          max-width: 25px;
          top: 10px;
          transform: translateX(50%);
          position: absolute;
        }
      }
      &:last-child {
        border-bottom: 0px;
      }
    }
  }
  .calendar-sidebar {
    .sidebar-header {
      border-bottom: solid 1px #e2e6ee;
      .img-wrapper {
        width: 48px;
        height: 48px;
        border-radius: 4px 0 0 0;
        background-color: #f5a623;
        img {
          max-width: 24px;
        }
      }
    }
    .event {
      border-bottom: solid 1px #e2e6ee;
      .img-wrapper {
        max-width: 48px;
        min-width: 48px;
        img {
          width: 24px;
          max-width: 100%;
          max-height: 100%;
        }
      }
      &:last-child {
        border-bottom: 0px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        border-bottom: unset;
        .event-info {
          border-right: solid 1px #e3e7ef;
          min-width: 150px;
        }
      }
    }
  }
  .filters-panel {
    .filter {
      display: flex;
      align-items: center;
      height: 40px;
      border-radius: 4px;
      box-shadow: 0 1px 2px 0 rgba(101, 114, 148, 0.08);
      border: solid 1px #e3e7ef;
      background-color: #ffffff;
      margin-right: 24px;
      padding: 0 16px;
      color: #3f4d6e;

      &:last-child {
        margin-right: 0px;
      }
      &.active {
        border-radius: 4px 4px 0 0;
      }
      &.like,
      &.more {
        padding: 0 16px;
        justify-content: center;
      }
      &.like.active {
        border: solid 1px #1b7beb;
        background-color: #ffffff;
        border-radius: 4px;
      }
    }
    .more-filters {
      z-index: 2147483001;
      position: fixed;
      top: 0;
      right: 0;
      width: (100% / 6);
      height: 100vh;
      box-shadow: -6px 1px 10px 0 rgba(101, 114, 148, 0.08);
      border: solid 1px #e3e7ef;
      background-color: #ffffff;
      .filter.open {
        width: 100%;
        padding: 20px 16px;
        height: unset;
        border-radius: 0;
        border: 0;
        border-bottom: solid 1px #e3e7ef;
      }
      .filters-container {
        padding: 24px 16px;
        .filter {
          width: 100%;
          margin-bottom: 8px;
        }
      }
      .actions {
        position: absolute;
        width: 100%;
        bottom: 0;
        padding: 16px;
        display: flex;
        border-top: solid 1px #e3e7ef;
        .btn {
          width: 100%;
        }
        .close-btn {
          border-radius: 6px;
          font-family: Roboto;
          font-size: 14px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.71;
          letter-spacing: normal;
          text-align: center;
          color: #222a3f;
          border: solid 1px #e2e6ee;
          margin-right: 11px;
        }
        .apply-btn {
          border-radius: 6px;
          background-color: #1b7beb;
          font-family: Roboto;
          font-size: 14px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.71;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
        }
      }
    }
  }
  .righter {
    .ad {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;
      height: 250px;
      border-radius: 6px;
      background-color: #efeff5;
    }
    .now-listing {
      padding: 19px;
      border-radius: 6px;
      border: solid 1px #e2e6ee;
      background-color: #ffffff;
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.81;
      letter-spacing: normal;
      text-align: center;
      color: #3f4d6e;
      margin-bottom: 24px;
      .amount {
        font-size: 20px;
        font-weight: 900;
        color: #1b7beb;
      }
    }
  }

  .CalendarDay__blocked_out_of_range {
    opacity: 0.3;
  }
  .results-count {
    padding: 0px;
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    .special-row {
      width: auto;
      margin-left: -15px;
    }
    .calendar-cards {
      padding-top: 24px;
    }
  }
  @media (max-width: 767.98px) {
    .search-box {
      .input-group {
        input[type="search"] {
          padding-left: 10px;
          padding-right: 55px;
        }
      }
    }
    .view-controls {
      .view {
        width: 100%;
      }
    }
    .event-btn {
      width: 100%;
      .post-event {
        width: 100%;
      }
    }
    .filter-block {
      padding: 0px 16px;
      a {
        color: #1f273c;
        text-decoration: none;
      }
      a:active {
        text-decoration: none;
      }
      .badge {
        color: #fff;
        background-color: #1b7beb;
        font-weight: 500;
        padding: 0.4em;
        border-radius: 0.85em;
        margin-left: 5px;
        vertical-align: bottom;
        width: 1.9em;
        height: 1.75em;
      }
    }
    .mobile-filter {
      height: 50px;
      background: #fff;
      margin-bottom: 24px;
      border-bottom: 1px solid #e2e6ee;
      display: flex;
      align-items: center;
      .filter,
      .search-mobile {
        display: flex;
        border-right: 1px solid #e2e6ee;
      }
      .listing-view {
        display: flex;
        div {
          margin: auto;
          img {
            height: 20px;
            width: 20px;
          }
        }
      }
      .view {
        display: flex;
      }
      .pointer {
        margin: auto;
        img {
          padding-right: 11px;
        }
      }
      .search-mobile {
        border-right: 1px solid #e2e6ee;
      }
    }
    .top-screen {
      position: fixed;
      overflow-y: auto;
      top: 0px;
      background-color: white;
      z-index: 1100;
      width: 100%;
      border-bottom: 1px solid #e2e6ee;
      height: 100vh;
      .row-pad {
        height: 55px;
        align-content: center;
      }
      .divider {
        height: 1px;
        background: #e2e6ee;
      }
      .custom-control {
        min-height: auto;
      }
      .custom-control-label:before {
        width: 16px;
        height: 16px;
        @media (max-width: 767px) {
          width: 22px;
          height: 22px;
        }
        border: solid 1px #e2e6ee;
        background-color: #fbfbfc;
        display: inline-block;
        background-color: transparent;
        background-image: none;
        top: 0px;
        border-radius: 2px;
      }
      .custom-checkbox
        .custom-control-input:checked
        ~ .custom-control-label::before {
        left: 95%;
        width: 22px;
        height: 22px;
        background-color: #ffffff;
        border: solid 1px #e2e6ee;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center right;
        top: 0px;
        border-radius: 2px;
      }
      .custom-checkbox
        .custom-control-input:checked
        ~ .custom-control-label::after {
        left: 95%;
        background-image: url("/images/check.svg");
        top: 0px;
        width: 22px;
        height: 22px;
      }
      .location-filter,
      .date-filter {
        margin-left: -15px;
        margin-right: -15px;
      }
      .date-filter .col-md-4 {
        padding: 0px;
      }
      @media (max-width: 767px) {
        .custom-control-label {
          width: 100% !important;
        }
        .custom-control-label::before {
          left: unset !important;
          right: 0px !important;
        }
        .custom-control-label::after {
          left: unset !important;
          right: 0px !important;
        }
      }
      .location-filter,
      .date-filter {
        margin-left: -16px;
        margin-right: -16px;
      }
      .date-filter .col-md-4 {
        padding: 0px;
      }
      .pc-calendar-select {
        .small-date .DayPicker_weekHeader {
          left: -9px !important;
        }
        .CalendarMonth {
          padding: 0 0 100px 0px !important;
        }
      }
    }
    .calendar-view {
      padding-top: 24px;
    }
    .ScrollUpButton__Container.ScrollUpButton__Toggled {
      display: none;
    }
  }
}
