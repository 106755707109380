body {
  font-size: 14px;
  background-color: #fafafc;
  font-family: "Roboto";
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #1f273c;
}
a,
button {
  outline: unset !important;
}
//new update style
.custom-link {
  color: black;
}
.custom-link:hover {
  color: black !important;
}

.custom-background {
  background-image: url("/images/subscribe.svg");
}
.custom-background-2 {
  background-image: url(/images/search-field-pattern.svg);
}
.background-blue {
  background-color: #1b7beb;
}
.background-blue-2 {
  background-color: #1161be;
}
.background-orange {
  background-color: orange;
}
.background-white {
  background-color: #fafafc;
}
.background-white-2 {
  background-color: #fbfbfc;
}
.font-archivo {
  font-family: "Archivo";
}
.font-roboto {
  font-family: "Roboto";
}
.custom-button {
  color: white;
  text-decoration: unset !important;
  font-weight: 600;
  border: unset;
}
.custom-button:hover {
  color: black;
  text-decoration: unset !important;
}
.custom-blue-button {
  border: 1px #1b7beb solid;
  color: #1b7beb;
  border-radius: 6px;
  background-color: white;
}
.custom-blue-button:hover {
  background-color: #1b7beb;
  color: white;
}
.color-orange {
  color: orange;
}
.color-black {
  color: black;
}
.color-dark {
  color: #3f4d6e;
}
.color-dark-2 {
  color: #263049 !important;
}
.color-blue {
  color: #1b7beb;
}
.color-blue-2 {
  color: #22c0c2;
}
.pointer {
  cursor: pointer;
}

.mt-32 {
  margin-top: 32px;
}
.mb-32 {
  margin-bottom: 32px;
}
.line-h-15 {
  line-height: 1.5;
}
.line-h-17 {
  line-height: 1.7;
}
.line-h-20 {
  line-height: 2;
}
.rounded-2 {
  border-radius: 2px;
}
.rounded-6 {
  border-radius: 6px;
}
.rounded-8 {
  border-radius: 8px;
}
.rounded-t-0 {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.grid-h1 {
  font-size: 2.5rem;
}
@media (max-width: 1199px) {
  .grid-h1 {
    font-size: 2rem;
  }
}
@media (max-width: 575px) {
  .grid-h1 {
    font-size: 1.5rem;
  }
}
.font-s-18 {
  font-size: 18px;
}
.font-s-14 {
  font-size: 14px;
}
.font-s-12 {
  font-size: 12px;
}
.font-s-28 {
  font-size: 28px;
}
.font-s-30 {
  font-size: 30px;
}
.font-s-34 {
  font-size: 34px;
}
.font-weight-500 {
  font-weight: 500;
}
.border {
  border: 1px solid #e2e6ee !important;
}
.black-border {
  border: 1px solid black !important;
}
.border-top {
  border-top: 1px solid #e2e6ee !important;
}
.border-botom {
  border-bottom: 1px solid #e2e6ee !important;
}
.border-left {
  border-left: 1px solid #e2e6ee !important;
}
.border-right {
  border-right: 1px solid #e2e6ee !important;
}
.border-noTop {
  border-top: unset !important;
}
.capitalize {
  text-transform: capitalize;
}
.opacity-6 {
  opacity: 0.6;
}

.line {
  height: 1px;
  border: solid 1px #e2e6ee;
  width: 100%;
}
.show-content {
  padding-top: 8px;
  font-family: Roboto;
  max-height: 32em;
  font-size: 14px;
  color: #3f4d6e;
  transition: max-height 0.3s ease 0s;
  overflow: hidden;
  line-height: 2;
  &.hidden {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.3s ease 0s;
  }
}
.search-btn {
  background-image: url("/images/zoom-2.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.btn.add {
  color: #1b7beb;
}
.btn.add::before {
  background-image: url(/images/add.svg);
  border: 1px solid #1b7beb;
}
.btn.add,
.btn.clear {
  display: inline-flex;
  align-items: center;
}
.btn.clear::before {
  background-image: url(/images/remove.svg);
  border: 1px solid #e2e6ee;
}
.btn.add::before,
.btn.clear::before {
  content: " ";
  position: relative;
  left: -5px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.form-control {
  padding: 0.375rem 1rem 0.375rem 0.75rem;
}
.custom-list-dot {
  min-width: 7px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: black;
}
.custom-tag {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  padding: 8px 16px;
  border: solid 1px #e2e6ee;
  background-color: #fbfbfc;
}
.custom-label {
  min-width: 75px;
  align-self: flex-end;
  font-family: Roboto;
  padding: 4px 11px;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #20cf8c;
  border-radius: 4px;
  border: solid 1px #20cf8c;
  background-color: rgba(32, 207, 140, 0.05);
  margin: 0px;

  text-transform: capitalize;
  span {
    margin: auto;
  }
  &.conference {
    color: #22c0c2;
    border: solid 1px #22c0c2;
    background-color: rgba(34, 192, 194, 0.05);

    span:hover {
      color: #22c0c2 !important;
    }
  }
  &.exhibition {
    color: #a486f8;
    border: solid 1px #a486f8;
    background-color: rgba(164, 134, 248, 0.05);

    span:hover {
      color: #a486f8 !important;
    }
  }
  &.panels {
    color: #20cf8c;
    border: solid 1px #20cf8c;
    background-color: rgba(32, 207, 140, 0.05);

    span:hover {
      color: #20cf8c !important;
    }
  }
  &.webinar {
    color: #419bf9;
    border: solid 1px #419bf9;
    background-color: rgba(65, 155, 249, 0.05);

    span:hover {
      color: #419bf9 !important;
    }
  }
  &.networking,
  &.hackathon {
    color: #d73899;
    border: solid 1px #d73899;
    background-color: rgba(215, 56, 153, 0.05);

    span:hover {
      color: #d73899 !important;
    }
  }

  &.course {
    color: #eda643;
    border: solid 1px #eda643;
    background-color: rgba(237, 166, 67, 0.05);

    span:hover {
      color: #eda643 !important;
    }
  }
  &.fair {
    color: #ff4848;
    border: solid 1px #ff4848;
    background-color: rgba(255, 72, 72, 0.05);

    span:hover {
      color: #ff4848 !important;
    }
  }

  &.startup {
    color: #7b47ff;
    border: solid 1px #7b47ff;
    background-color: rgba(123, 71, 255, 0.05);

    span:hover {
      color: #7b47ff !important;
    }
  }
  &.competition {
    color: #b18c1c;
    border: solid 2px #ecb309;
    background-color: #f9e3a0;

    span:hover {
      color: #b18c1c !important;
    }
  }
}
@media (max-width: 767.98px) {
  .custom-tag-header {
    color: #f5a623;
  }
  .custom-tag {
    border: none;
    background-color: transparent;
    color: #838da4;
    text-transform: capitalize;
    padding: 0px;
  }
}

// universal css
.top-panel {
  display: flex;
  position: relative;
  height: 121px;
  background-color: #1161be;
  background-image: url("/images/events-search-pattern.svg");
  align-items: center;
  z-index: 1;
  .title {
    font-family: Archivo;
    font-size: 44px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
}
.new-line {
  white-space: pre-line;
}
.black-link {
  color: #1f273c !important;
  text-decoration: none !important;
}
.black-link-hover:hover {
  color: #1f273c !important;
  text-decoration: none !important;
}
.white-link {
  color: white !important;
  text-decoration: none !important;
}
.white-link-hover:hover {
  color: white !important;
  text-decoration: none !important;
}
.pl-24 {
  padding-left: 24px;
}
.pr-24 {
  padding-right: 24px;
}
.pt-24 {
  padding-top: 24px;
}
.pb-24 {
  padding-bottom: 24px;
}
.pb-12 {
  padding-bottom: 12px;
}
.opacity-3 {
  opacity: 0.3;
}
.badge {
  color: #fff;
  background-color: #1b7beb;
  font-weight: 500;
  padding: 0.4em;
  border-radius: 0.85em;
  margin-left: 8px;
  vertical-align: bottom;
  width: 1.9em;
  height: 1.75em;
}
.capitalize {
  text-transform: capitalize;
}
.tag-companies {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  padding: 0px;
  margin: 0px;
  border-radius: 4px;
  border: solid 1px #e2e6ee;
  background-color: #fbfbfc;
  height: 50px;
  width: 50px;
}
.margin-right {
  margin-left: auto;
  margin-right: 0;
}
.blue-color2 {
  color: #43a7ed;
}
.btn:focus {
  box-shadow: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Archivo";
}
.subscriber-content {
  @media (max-width: 457px) {
    transform: translateX(-35%) !important;
  }
  @media (max-width: 350px) {
    transform: translateX(-40%) !important;
  }
}
.blue-check {
  color: #1b7beb;
  height: 1.25em;
  width: 1.25em;
  position: absolute;
  top: 0px;
  right: 0px;
}
.display-flex {
  display: flex;
  flex-wrap: wrap;
}
.grey-border-t {
  border-top: 1px solid #e3e7ef;
}
.grey-border-r {
  border-right: 1px solid #e3e7ef;
}
.grey-border {
  border: 1px solid #e3e7ef;
}
.vertical-align {
  margin-top: auto;
  margin-bottom: auto;
}
.horizontal-align {
  margin-left: auto;
  margin-right: auto;
}
.position-relitive {
  position: relative;
}
.margin-bottom-16 {
  margin-bottom: 16px;
}
.margin-bottom-8 {
  margin-bottom: 8px;
}
.margin-bottom-4 {
  margin-bottom: 4px;
}
.margin-l-5 {
  margin-left: 5px;
}
.margin-l-10 {
  margin-left: 10px;
}
.margin-right-10 {
  margin-right: 10px;
}
.margin-left {
  margin-left: 0px;
  margin-right: auto;
}
.margin-0 {
  margin: 0px !important;
}
.margin-0-sm {
  @media (max-width: 767px) {
    margin: 0px !important;
  }
}
.radius-4 {
  border-radius: 4px;
}
.padding-lr-5 {
  padding-left: 5px;
  padding-right: 5px;
}
.padding-lr-7 {
  padding-left: 7px;
  padding-right: 7px;
}
.padding-lr-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.grey-border-b {
  border-bottom: 1px solid #e3e7ef;
}
.grey-border-l {
  border-left: 1px solid #e3e7ef;
}
.grey-border-2 {
  border: solid 1px #e2e6ee;
}
.unset-border {
  border: unset;
}
.w-100vh {
  width: 100vh;
}
.overflow-x-auto {
  overflow-x: auto;
}
.likes-block {
  display: flex;
  .likes {
    margin: auto 0;
  }
  button {
    padding: 0.1rem 0rem 0.4rem 0.75rem;
  }
}
.add-block-filter {
  width: 34px;
  height: 34px;
  border-radius: 4px;
  border: solid 1px #e2e6ee;
  background-color: #fbfbfc;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .add-sign-horizontal {
    top: 7px;
    left: 16px;
    position: absolute;
    width: 2px;
    height: 18px;
    transform: rotate(-270deg);
    border-radius: 1px;
    background-color: #8a9bc3;
  }
  .add-sign-vertical {
    top: 7px;
    left: 16px;
    position: absolute;
    width: 2px;
    height: 18px;
    border-radius: 1px;
    background-color: #8a9bc3;
  }
}

.custom-scroll {
  overflow-x: auto;
  white-space: nowrap;
}
.custom-scroll::-webkit-scrollbar {
  width: 1em;
}

.custom-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: grey;
  outline: unset;
}
// end universal css
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/images/select.svg") 96.5% / 0.5em no-repeat #eee;
}

.select-icon {
  background: url("/images/select.svg") 96.5% / 0.5em no-repeat #eee;
  &.active {
    background: url("/images/select-active.svg") 96.5% / 0.5em no-repeat #eee;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .special-row {
    width: calc(100% + 60px);
  }
}

.tippy-backdrop {
  display: none !important;
}

.tippy-tooltip.iot-theme {
  border: 1px solid #e2e6ee;
  background-color: #fff !important;
  color: #1b7beb;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  padding: 16px;

  .tippy-content {
    width: 285px;
  }

  ul {
    max-height: 290px;
    overflow-x: auto;
    padding: 0;

    li {
      list-style-type: none;
      padding: 4px 24px;
      cursor: pointer;
    }
  }
}

.results {
  text-align: left;

  .result-count {
    margin: 16px 0px;
    @media (max-width: 767px) {
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }
}

table {
  background-color: #ffffff;
  border: 1px solid #e2e6ee;
  border-radius: 6px;
  thead {
    color: #3f4d6e;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    tr.row {
      align-items: center;
    }
    tr {
      min-height: 56px;
    }
    th {
      cursor: pointer;
    }

    .header {
      margin-right: -10px;
    }

    .header::after {
      content: url("/images/select.svg");
      position: relative;
      top: 2px;
      right: -10px;
      display: inline-block;
    }

    .header.asc::after {
      content: url("/images/select-asc.svg");
    }

    .header.desc::after {
      content: url("/images/select-asc.svg");
      top: 0px;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }

  tbody {
    tr.row {
      align-items: center;
    }
    tr {
      border-top: 1px solid #e2e6ee;
      td {
        padding: 8px 10px;
      }
      td:first-child {
        font-size: 14px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #1f273c;
        padding-left: 24px;
      }
    }
  }
  .rank.stable::after {
    content: " ";
    width: 11px;
    height: 2px;
    background-color: #8a9bc3;
    position: relative;
    top: -3px;
    right: -10px;
    display: inline-block;
  }

  .rank.up::after {
    content: url("/images/rank-up.svg");
    position: relative;
    top: -2px;
    right: -10px;
    display: inline-block;
  }

  .rank.down::after {
    content: url("/images/rank-down.svg");
    position: relative;
    top: -2px;
    right: -10px;
    display: inline-block;
  }

  .company-logo {
    height: 40px;
    width: 40px;
    opacity: 0.7;
    border-radius: 4px;
    border: 1px solid #e2e6ee;

    img {
      margin: 0 auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
.Results-Table-Group-Stats {
  .header::after {
    right: -5px;
  }
}
.results.companies table td:first-child {
  font-weight: 700;
}

.menu-links {
  text-align: center;
  display: inline-flex;
  width: 100%;
}

.icon-btn {
  padding: 15px;
}

.icon-btn:hover {
  padding-bottom: 13px;
  border-bottom: 2px solid;
}

.icon-btn.company-link {
  border-bottom-color: #90dfe0;
}
.icon-btn.tags-link {
  border-bottom-color: #46a8ed;
}
.icon-btn.activity-link {
  border-bottom-color: #65d12f;
}
.icon-btn.cases-link {
  border-bottom-color: #eda643;
}
.icon-btn.financial-link {
  border-bottom-color: #ff5a5a;
}
.icon-btn.contacts-link {
  border-bottom-color: #6f42c180;
}
.icon-btn.twitter-link {
  border-bottom-color: #1dcaff;
}

.grey-out {
  opacity: 0.3;
  cursor: not-allowed;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
}

.faked-data {
  color: transparent;
  text-shadow: rgba(183, 183, 183, 0.75) 0px 0px 8px;
  pointer-events: none;
  user-select: none;
}

.card-holder {
  position: relative;
}

.blurred-box {
  filter: blur(4px);
  pointer-events: none;
  user-select: none;
}

.subscriber-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 998;
  font-size: 24px;
  display: flex;
  @media (max-width: 767px) {
    font-size: 22px;
  }
}

.subscriber-disclaimer {
  padding: 10px 25px;
  display: block;
  background-color: #ffa500;
  width: 100%;
  z-index: 997;
  color: white;
  font-weight: 600;
  font-size: 14px;
  @media (max-width: 767px) {
    font-size: 18px;
  }

  a {
    margin: 0px 5px;
    text-decoration: underline !important;
    color: white !important;
  }
}

// .subscriber-disclaimer.orange {
//   color: #ffa500;
//   border: 1px solid #ffa500;
//   a{
//     color: #ffa500 !important;
//   }
// }

// .subscriber-disclaimer.blue {
//   color: #46A8ED;
//   border: 1px solid #46A8ED;
//   a{
//     color: #46A8ED !important;
//   }
// }

// .subscriber-disclaimer.green {
//   color: #65D12F;
//   border: 1px solid #65D12F;
//   a{
//     color: #65D12F !important;
//   }
// }

.filters-toggle {
  width: 170px;
  position: absolute;
  color: #1161be;
  left: 50%;
  bottom: -15px;
  transform: translate(-50%, 0);
  z-index: 999;
  background: white;
  border: 1px solid #1161be;
  padding: 7px;
  border-radius: 3px;
}

.iti-flag {
  background-image: url("/img/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti-flag {
    background-image: url("/img/flags@2x.png");
  }
}

.intl-tel-input {
  width: 100%;
}

.btn.link {
  color: #1b7beb;
  padding: 0;
  text-decoration: underline;
}

input[disabled].btn.link {
  color: gray;
  padding: 0;
  text-decoration: underline;
}

.validation-error {
  color: red;
  margin-top: 10px;

  span {
    margin-right: 10px;
  }
}

.ReactModal__Overlay {
  z-index: 999;
  top: 58px !important;
  @media (max-width: 767px) {
    width: 100% !important;
    .ReactModal__Content {
      width: 100% !important;
    }
  }
}
.upgrade {
  background-color: rgb(255, 165, 0) !important;
  color: white !important;
  border-radius: 4px !important;
  padding: 8px 24px;
  border: none;
}
.sign-up {
  background-color: rgb(255, 165, 0) !important;
  color: white !important;
  border-radius: 4px !important;
  padding: 8px 24px;
  border: none;
  display: inline-block;
}

.btn.clear::before {
  background-image: url(/images/remove.svg);
  border: 1px solid #e2e6ee;
}

.control-filters {
  .btn {
    font-size: 14px;
    font-weight: 500;
    color: #3f4d6e;
    display: inline-flex;
    align-items: center;
  }

  .btn.add {
    color: #1b7beb;
  }
}

.filter-entry {
  margin: 5px 0;
  align-items: center;
}

.slider {
  .slick-slide {
    height: auto; //rewrite
    width: 268px;
    margin-left: 20px;
  }
  h2 {
    font-size: 26px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: #263049;
    margin: 64px 0 40px;
  }
}

.subscribe {
  height: 317px;
  background-color: #1161be;
  background-image: url("/images/subscribe.svg");
  text-align: center;

  h2 {
    font-size: 34px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
  @media (max-width: 991px) {
    h2 {
      font-size: 30px;
    }
  }
  @media (max-width: 767px) {
    h2 {
      font-size: 26px;
    }
  }

  p {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #cee1f7;
  }

  a {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.29;
    letter-spacing: normal;
    color: #263049;
    border-radius: 4px;
    box-shadow: 0 5px 10px 0 rgba(9, 52, 103, 0.13);
    background-color: #ffffff;
    padding: 8px 52px;
  }
}
.dropdown-container {
  width: 100px;
}

@media (max-width: 768px) {
  .tag {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    padding: 8px 16px;
    margin-right: 8px;
    border-radius: 4px;
    border: solid 1px #e2e6ee;
    background-color: #fbfbfc;
  }
}
