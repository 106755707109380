    .tippy-tooltip.iot-theme ul li{
       padding: 4px 50px; 
    }
    .title-popup{
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: #1f273c;
        text-align: center;
    }
    .prize-list{
        display: flex;
        justify-content: center;
        p{
            display: flex;
            padding: 0px 5px;
            margin: 0px;
        }
        .prize-title{
            margin: 0 auto 0 0;
        }
        .prize-description{
            margin: 0 0 0 auto;
        }
    }
    .Competitions .EventsItem{
    
    border-radius: 6px;
    border: solid 1px #e2e6ee;
    background-color: #ffffff;
    margin-bottom: 16px;
    .p-8{
       padding-bottom: 8px; 
    }
    .margin-tb{
        margin-top: 8px;
        margin-bottom: 16px;
    }
    &.featured {
        border: solid 1px #f5a623;
    }
    .featured-header .img-wrapper{
        border-radius: 6px 0 0 0;
    }
    .register-block{
        padding-top: 1rem;
    }
    .height-24{
        height: 24px;
    }
    .prize-block{
        background-color: #25893c;
        display: flex;
        padding: 3px 0px 3px 10px;
        
        .price-text{
            text-decoration-color: white;
            font-size: 16px;
            margin: auto 0;
            color: white;
        }
        a{
            text-decoration: none;
        }
        a:hover{
            color:#cacccd;
        }
        .price-btn{
            display: inline;
            cursor: pointer;
            color: white;
            padding: 0px 5px;
        }
        .price-btn:hover{
            color:#cacccd;
        }
    }
    .featured-header{
        display: flex;
        align-items: center;
        border-bottom: solid 1px #e2e6ee;
        .img-wrapper{
            display: flex;
            justify-content: center;
            align-content: center;
            width: 44px;
            height: 34px;
            background-color: #f5a623;
            img{
                width: 100%;
                max-width: 16px;
            }
            
        }
        .title{
            font-family: Roboto;
            font-size: 14px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.14;
            letter-spacing: normal;
            color: #1f273c;
            padding: 0 16px;
        }

    }
    .event-body{
        padding: 16px 19px;
    }
    .event-title{
        font-family: Roboto;
        line-height: 24px;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #1b7beb;
    }
    .event-image{
        display: block;
        margin-right: 24px;
        height: 104px;
        width: 100%;
        border-radius: 4px;
        border: solid 1px #e2e6ee;
        img{
            min-width: 221px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    .tag{
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        padding: 8px 16px;
        margin-right: 8px;
        border-radius: 4px;
        border: solid 1px #e2e6ee;
        background-color: #fbfbfc;
    }
    @media(max-width: 767px){
		.event-image{
			margin-left: auto;
			margin-right: auto;
		}
        .tag-header {
            color: #f5a623;
        }
        .tag {
            border: none;
            background-color: transparent;
            color: #838da4;
            text-transform: capitalize;
            margin-bottom: 2px;
            padding: 0px 0px 0px 8px;
            margin-right: 0px;
        }
    }
    .more{
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: #43a7ed;
        padding: 8px 16px;
        border-radius: 4px;
        border: solid 1px #e2e6ee;
        background-color: #fbfbfc;  
    }
    .show-more-info{
        cursor: pointer;
        display: inline-block;
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: #1b7beb;
    }
    .general-info{
        > .row{
            margin-bottom: 8px;
        }
        .info-holder{
            img{
                margin-right: 0.5em;
            }
            padding-right: 1em;
        }
        
        .time-left{
            padding: 8px 0;
            display: inline-block;
            font-family: Roboto;
            font-size: 14px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.14;
            letter-spacing: normal;
            color: #3f4d6e;
        }
        .content{
            padding-top: 8px;
            font-family: Roboto;
            max-height: 32em;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 2;
            letter-spacing: normal;
            color: #3f4d6e;
            transition: max-height 0.5s ease 0s; 
            overflow: hidden;
            &.hidden{
                max-height: 0px;
                overflow: hidden; 
                transition: max-height 0.5s ease 0s; 
            }
        }
    }
    .tag-col {
        max-height: 35px;
        overflow: hidden;
        
    }

}
.tippy-content{
    ul{
       margin: 0px; 
    }
}