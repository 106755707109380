.dot {
    min-width: 7px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: black;
}
.empty_dot {
    max-width: 7px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    border: 1px solid black;
}
.image {
    object-fit: cover;
}