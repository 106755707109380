.Campaign-card{
    margin-bottom: 32px;
    background-color: #ffffff;
    .card-images{
        height: 200px;
        width: 200px;
        object-fit: cover;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .company-image{
        height: 14px;
        width: 14px;
        border-radius: 50%;
        background-color: blue;
        margin: auto 9px auto 0;
    }
    .card-information{
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        width: calc(100% - 200px);
        // padding-bottom: 20px;
        // padding-left: 26px;
        // padding-right: 26px;
        // padding-top: 36px;
        padding: 12px 26px 11px 26px;
        border-top: 1px solid #e3e7ef;
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #263049;
        .user{
            font-family: Archivo;
            font-size: 18px;
            line-height: 1.78;
        }
        .social-links{
            .twitter-icon{
                color: #2CAAE1;
            }
            .linkedin-icon{
                color:#007BB6;
                margin-left: 0px;
                margin-right: 15px;
            }
        }
        .email{
            margin-top: 10px;
            margin-bottom: 10px;
            svg{
                color: #08c0c3;
                margin: auto 9px auto 0;
            }
        }
        .location{
            margin-top: 10px;
            margin-bottom: 10px;
            svg{
                width: 24px;
                height: 22px;
                color: #efa736;
                margin: 0 9px auto 0;
            }
        }
        .company{
            img{
                height: 14px;
                width: 14px;
                margin: 5px 9px auto 0;
            }
        }
        .expertise{
            margin-top: 12px;
            margin-bottom: 10px;
            b{
                margin-right: 10px;
            }
            .copy-button{
                display: flex;
                margin: auto auto auto 20px;
                button{
                    border-radius: 4px;
                    background-color: #1b7beb;
                    border: 1px solid #1b7beb;
                    color: white;
                }
            }
        }
        .view-bio{
            color: #1b7beb;
        }
        .view-bio:hover{
            text-decoration: underline;
        }
    }
    .card-description{
        width: 100%;
        padding: 30px 16px 10px 16px; 
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #263049;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        .d-flex{
            color: #1b7beb;
        }
        .d-flex:hover{
            text-decoration: underline;
        }
    }
    @media(min-width: 768px) and (max-width: 1199px){
        .card-images{
            width: 200px;
        }
        .card-information{
            width: calc(100% - 200px);
            padding-left: 23px;
            padding-right: 22px;
            padding-top: 14px;
            .location{
                svg{
                    width: 30px;
                }
            }
        }
    }
    @media(max-width: 767px){
        .card-images{
            width: 100%;
            border-top-right-radius: 4px;
            border-bottom-left-radius: unset;
        }
        .card-information{
            width: 100%;
            padding-left: 23px;
            padding-right: 22px;
            padding-top: 26px;
            border-left: 1px solid #e3e7ef;
            border-top: unset;
            border-top-right-radius: unset;
            border-bottom-left-radius: 4px;
            .location{
                svg{
                    width: 18px;
                }
            }
        }
    }
}