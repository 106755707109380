.SpeakersMiddle{

    
    margin-top: 24px;
    .main-padding{
        padding: 24px 42px 0px 42px;
    }
    .person-name{
        font-family: Roboto;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.89;
        letter-spacing: normal;
        color: #1f273c;
        margin-bottom: 10px;
        font-weight: bold;
    }
    .profesion{
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #3f4d6e;
        margin-bottom: 8px;
    }
    @media(max-width: 736px) {
        .person-name {
            font-size: 14px;
        }
        .main-padding{
            padding: 24px 16px;
        }
        .float-l {
            margin-left: -15px;
        }
        .float-r {
            margin-top: -5px;
        }
        .linkedin-icon {
            height: 20px;
            width: 20px;
        }
        .side-block-border img{
            height: 80px;
            width: 80px;
            border-radius: 4px;
        }
    }
    @media(max-width: 320px) {
        .float-l {
            width: 115px;
        }
    }

}
.Speaker:last-child hr {
    visibility: hidden;
}
