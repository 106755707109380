.Map{
    .hq .map {
        background-size: 100%;
        background-repeat: no-repeat;
        height: 163px;
        margin: 20px 0px;
       
    }
    .adress{
        p{
            font-weight: 400!important
        }
    }
    .view-on-map{
        button:hover{
            opacity: 0.8;
            font-weight: bold;
        }

        .view-on-map-btn{
            color: white;
            background-color: #FFA500;
            border-color: #FFA500;
            padding: 0.375rem 1.5rem;
            border-radius: 0.5rem;
            text-align: center;
            vertical-align: middle;
            display: inline-block;
            font-weight: 700;
            text-align: center;
            vertical-align: middle;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            border: 1px solid transparent;
            font-size: 14px;
            line-height: 1.5;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;    
        }   
    }
    
}