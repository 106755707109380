.AddCode{
    margin-top:20px;
    padding: 20px 0;
    .add-code-button{
        height: 100%;
        width: 50%
    }
    .addCode-title{
        padding-bottom: 5px;
    }
    .react-datepicker-wrapper{
        height: 100%;
        .react-datepicker__input-container{
            height: 100%;
            input{
                height: 100%;
            }
        } 
    }
    .react-datepicker-popper{
        top: 14px;
    }
}