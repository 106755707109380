.Button{
    .button{
        width: 100%;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        margin-bottom: 10px;
        border-radius: 6px;
        box-shadow: none !important;
        border: unset;
        color: white;
    }
    .font-size-12{
        font-size: 12px;
    }

    .con-selected-visitor{
        border: 1px solid #01b869;
        background-color: #01b869;
    }

    .con-selected-registered{
        border: 1px solid #ffb24e;
        background-color: #ffb24e;
    }

    .con-selected-pro{
        border: 1px solid #ff7900;
        background-color: #ff7900;
    }

    .con-selected-enterprise{
        border: 1px solid #5e34de;
        background-color: #5e34de;
    }

}