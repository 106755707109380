.ExhibitMiddle{
    margin-top: 24px;
    .main-padding{
        padding: 24px 42px 0px 42px;
    }
    @media(max-width: 736px) {
        .main-padding{
            padding: 24px 32px  0px;
        }
    } 
}