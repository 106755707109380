.root {
    :global {
        .height-121 {
            height: 121px;
        }
        .search-box {
            box-shadow: 0 5px 10px 0 rgba(9, 52, 103, 0.13);
            height: 40px;
        }
        .image-wrapper::before {
            content: url('/images/zoom.svg');
            position: absolute;
            top: 12px;
            left: 24px;
        }
        .form-control{
            margin-left: 30px;
            border: none;
            box-shadow: none;
        }
        .form-control:focus{
            border: unset;
            outline: 0;
            box-shadow: none;
        }
        .search{
            height: 34px;
            margin: 3px;
        }
        .tag {
            border: 1px solid #a1c0e4;
            background-color: initial;
            height: 40px;
        }
    }
}