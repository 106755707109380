.PurpuleTag{
    display: flex;
    margin-right: 8px;
    margin-top: 8px;
    .tag{
        border-radius: 4px;
        border: solid 1px #682cff;
        background-color: rgba(67, 167, 237, 0.05);
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #682cff;
        padding: 4px 10px;
  
    }
}