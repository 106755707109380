.arrow{
    
    svg{
        margin-top: -10px;
        height: 2em;
    }
}
.UseCaseList .EventsItem{
    .flex-end{
        justify-content: flex-end;
    }
    .pl-36{
        padding-left: 36px;
    }
    .tag-companies{
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        padding: 0px;
        margin: 0px;
        border-radius: 4px;
        border: solid 1px #e2e6ee;
        background-color: #fbfbfc;
        height: 50px;
        width: 50px;
        img{
            height: 47px;
            width: 47px; 
        }
    }
    .mr-10{
        margin-right: 10px;
    }
    .toggle{
        p{
            margin: 0px;
            max-height: 500px;
            transition: max-height 1s ease-in;
            overflow: hidden;
        }
         &.hidden{
             p{
                 margin: 0px;
                max-height: 48px;
                transition: max-height 1s ease-in;
                overflow: hidden;
             }
         }
     }
    span:hover{
        text-decoration: underline;
        color: blue;
    }
     .text-decoration{
        margin-top: 16px;
        color: #1b7beb;
     }
     .pointer{
         margin: 0px;
        cursor: pointer;
    }
    .organizer{
        margin: auto 10px auto 10px;
        color: grey;
    }
    .self-organizer{
        margin: auto 0px;
    }
    .event-calendar{
        .AddtoCalendarDropdown{
            padding: 0px 10px;
        }
    }
    border-radius: 6px;
    border: solid 1px #e2e6ee;
    background-color: #ffffff;
    margin-bottom: 16px;
    &.featured {
        border: solid 1px #f5a623;
    }
    .featured-header .img-wrapper{
        border-radius: 6px 0 0 0;
    }
    .featured-header{
        display: flex;
        align-items: center;
        border-bottom: solid 1px #e2e6ee;
        .img-wrapper{
            display: flex;
            justify-content: center;
            align-content: center;
            width: 44px;
            height: 34px;
            background-color: #f5a623;
            img{
                width: 100%;
                max-width: 16px;
            }
            
        }
        .title{
            font-family: Roboto;
            font-size: 14px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.14;
            letter-spacing: normal;
            color: #1f273c;
            padding: 0 16px;
        }

    }
    .event-body{
        padding: 16px 19px;
    }
    .event-title{
        font-family: Roboto;
        line-height: 24px;
        font-size: 16pt;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #1b7beb;
        @media(max-width: 767px){
            text-align: center;
        }
    }
    .event-image{
        display: block;
        margin-right: 16px;
        height: 127px;
        min-width: 221px;
        width: 100%;
        max-width: 223px;
        @media(max-width: 767px){
            height: 125px;
            max-width: 221px;
            margin: auto;
        }
        border-radius: 8px;
        border: solid 1px #e2e6ee;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    .tag{
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        padding: 8px 16px;
        margin-right: 8px;
        border-radius: 4px;
        border: solid 1px #e2e6ee;
        background-color: #fbfbfc;
    }
    .more{
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: #43a7ed;
        padding: 8px 16px;
        border-radius: 4px;
        border: solid 1px #e2e6ee;
        background-color: #fbfbfc;  
    }
    .show-more-info{
        cursor: pointer;
        display: inline-block;
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: #1b7beb;
    }
    .general-info{
        > .row{
            margin-bottom: 8px;
        }
        .info-holder{
            img{
                margin-right: 0.5em;
            }
            padding-right: 1em;
        }
        .pdf{
            svg{
                
                height:30px;  
                width:30px;
                
            }
        }
        .likes{
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: normal;
            justify-content: flex-end;
            @media(max-width: 767px){
               margin: auto 0 auto auto; 
            }
        }
        .time-left{
            padding: 8px 0;
            display: inline-block;
            font-family: Roboto;
            font-size: 14px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.14;
            letter-spacing: normal;
            color: #3f4d6e;
        }
        .content{
            padding-top: 8px;
            font-family: Roboto;
            max-height: 32em;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 2;
            letter-spacing: normal;
            color: #3f4d6e;
            transition: max-height 0.5s ease 0s; 
            overflow: hidden;
            &.hidden{
                max-height: 0px;
                overflow: hidden; 
                transition: max-height 0.5s ease 0s; 
            }
        }
    }
    .tag-col {
        max-height: 40px;
        overflow: hidden;
        padding-top: 5px;
        margin: 0 0 auto auto;
    }
    @media(max-width: 768px) {
        .event-image img{
            border-radius: 8px;
        }
        .event-title {
            font-size: 16px;
            padding-top: 0px;
        }
        .tag {
            color: #1f273c;
        }
        .tag.more {
            color: #43a7ed;
        }
        .likes.btn {
            margin: 0px;
            padding-top: 3px;
        }
        .company-icon a {
            float: right;
        }
    }

}
.tippy-content{
    ul{
       margin: 0px; 
    }
}