.Outcome{
    margin-bottom: 31px;
    position: relative;
    .description{
        background-color: white;
        border-left: solid 1px #e2e6ee;
        border-right: solid 1px #e2e6ee;
        border-bottom: solid 1px #e2e6ee;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        padding: 23px 48px 17px 24px;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #3f4d6e;
    }
    .not-login{
        -webkit-filter: blur(4px);
        filter: blur(4px);
    }
}