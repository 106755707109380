.Corp-Group-Stats{
    .welcome-screen{
        height: 121px;
        background-color: #1161be;
        background-image: url('/images/search-field-pattern.svg');
        margin-bottom: 21px;
        .text-box{
            text-align: center;
            h1{
                font-family: 'Archivo';
                font-size: 34px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.41;
                letter-spacing: normal;
                color: #ffffff; 
            }
            p{
                font-size: 16px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 2;
                letter-spacing: normal;
                color: #cee1f7;
                &.date-range{
                    line-height: 1.5;
                    margin-top: 1em;
                    font-weight: bold;
                    color: #ffffff; 
                }
            }

        }
    }

    .inquire-eco-systems{
        background-color: white;
        border: 1px solid #e2e6ee;
        border-radius: 6px;
        padding-top: 10px;
        padding-bottom: 10px;
        .networking-2{
            padding: 18px;
            border-radius: 4px;
            border: 1px solid #e2e6ee;
            margin-left:24px;
        }
        .title{
            margin: 0;
            font-family: 'Archivo';
            font-size: 18px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: #263049;
        }
        .text{
            font-family: 'Roboto';
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.71;
            letter-spacing: normal;
            color: #3f4d6e;
        }

        .inquire-btn{
            color: white;
            border-radius: 4px;
            background-color: #1b7beb;
            border-radius: 4px;
            background-color: #1b7beb;
            width: 110px;
            padding:5px;
            line-height: 2.29;
            margin-right:24px;
            text-decoration: none;
            text-align: center;
        }
        @media(max-width: 767px){
            .inquire-btn{
                margin-right: auto;
                margin-left: auto;
            } 
        }
        
    }
    .range-change{
        padding: 0;
        margin-bottom: 1em;
        .form-control:focus{
                border:1px solid #ced4da;
                outline: 0;
                box-shadow: none;
        }
    }
    
}