/* Navigation */
.user-type-block{
	padding: 0.15rem 0.5rem;
	border: 2px solid black;
	width: 56.2px;
	.user-type{
		color: black;
	}
}
.full-height{
    height: 100vh;
}
.close-nav-block{
    height: 59px;
   .close-nav{
        height: 1.5em;
        width: 1.5em;
    }
}
.index-1050{
    z-index: 1500 !important;
}
.Case-studies-block{
    display: none;
    position: absolute;
    background-color:#fafafc;
    min-width: 195px;
    left: 40px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1049;
}
.navbar-light.navbar {
    z-index: 1049;
    background-color: #FAFAFC;
    .mobile-block{
        background-color: #FAFAFC !important;
    }
    .selected {
        font-weight: 600;
    }
    .nav-link {
        color: #1f273c;
    }
    .nav-link:hover{
        font-weight: 600;
        color: #1f273c;
    }
    .collapse{
        background-color: #FAFAFC;
    }
    .collapse-block{
        position: fixed;
        top: 0px;
        right: 0px;
        width: 70%;
        .collapse-small{
            background-color: #FAFAFC;
        }
    }
    .nav-item{
        &:hover{
            .Hover-Nav{
                display: block !important;
            }
        }
    }
    .dropdown-item{
        position: relative;  
        background-color: #FAFAFC !important;
    }
    .Hover-Nave-open{
        display: block!important;
        @media(min-width: 992px){
            position: absolute;
            left: 110px;
            top: -46px;
            z-index: 2000000;
        }
        background: #FAFAFC;
    }
    .logo {
        height: 32px;
        width: 149px;
    }
    .dropdown{
        div{
            background-color: #FAFAFC;
        }
    }
  }
.Hover-Nav{
    display: none;
    position: absolute;
    background-color:#fafafc;
    min-width: 195px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1049;
}
.Hover-Nave{
    display: none;
    position: absolute;
    background-color:#fafafc;
    min-width: 195px;
    left: 40px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1049;
}

.navbar.blue-navbar {
    background-color: #1161BE !important;
    .mobile-block{
        background-color: #1161be!important;
    }
    .Case-studies-block{
        background-color:#1161BE !important;
    }
	.user-type-block{
		border: 2px solid white;
		.user-type{
			color: white;
		}
	}
	a, div {
		color: white !important;
	}
	.dropdown-menu.show{
		background-color: #1161BE !important;
	}
	button:hover{
		background-color: #1161BE;
	}
	.nav-link:hover{
		color: orange !important;
	}

	.dropdown-item{
		background-color: #1161be!important;
	}
	.dropdown-menu{
		background-color: #1161be!important;
		div{
			background-color: #1161be!important;
		}
	}
	.collapse{
		background-color: #1161BE !important;  
	}
	.collapse-small{
		background-color: #1161BE;
	}
	button {
		cursor: pointer;
	}
	.selected {
		color: orange !important;
    }
    .sign-up.selected {
        color: white !important;
    }
    .sign-up.nav-link:hover {
        color: white !important;
    }
	.Hover-Nav, .Hover-Nave, .Hover-Nave-open{
		background-color:#1161BE;
	}
}

.small-nav-item:first-child{
    border-top: 1px solid #e9ebf0;
    border-bottom: 1px solid #e9ebf0;
}  
.small-nav-item{
    padding: 20px 41px;
    border-bottom: 1px solid #e9ebf0;
}
.mobile-nav-item:first-child{
    border-top: 1px solid #e9ebf0;
    border-bottom: 1px solid #e9ebf0;
}  
.mobile-nav-item{
    padding: 10px 41px;
    border-bottom: 1px solid #e9ebf0;
}
.small-login-block{
    @media (min-width: 501px){
      position: absolute;
        bottom: 20px;  
    }
    @media (max-width: 500px){
        margin: 20px; 
    }

    width: 100%;
    display: flex;
    justify-content: center;
    .nav-link{
        width: 170px;
        text-align: center;  
    }
    .border-white{
        border: 1px solid white;
    }
    .border-blue{
        border: 1px solid #1161BE;
    }
    .first-button{
        padding: 15px 0;
        background-color: #FAFAFC;
        border-radius: 4px;
        .nav-link{
            padding: 0px;
            color: black !important; 
        }
    }
    .second-button{
        padding: 15px 0;
        border: 1px solid orange;
        background-color: orange;
        border-radius: 4px;
        .nav-link{
            padding: 0px;
            color: white !important; 
        }
    }
}
.pc-block{
    .nav-link{
        font-size: 17px;
    }
}

.mobile-block{
    // background-color: #1161be!important;
    .nav-link{
        font-size: 16px !important;
    }
    @media(max-width: 575px){
        .nav-link{
            font-size: 14px !important;
        }
    }
    .first-button{
        margin: 0 0 10px 0;
    }
    .second-button{
        margin: 0 0 10px 0;
    }
}
.dropdown-menu.show{
    position: unset !important;
    will-change: none!important;
    top: 0px;
    left: 0px;
    transform: none!important;
    border: unset;
}
.mobile.dropdown{
    padding: 10px 41px;
    border-bottom: 1px solid #e9ebf0;
}
.tablet.dropdown{
    padding: 20px 41px;
    border-bottom: 1px solid #e9ebf0;
}
.first-button{
    padding: 19px 0;
}
@media screen and (max-height: 700px) {
    .small-login-block {
        .first-button{
            padding: 10px 0;
        }
        .second-button{
            padding: 10px 0;
        }    
    }
    
}
@media(max-width: 767px){
    .tablet.dropdown{
        padding: 5px 41px;
    }
    .dropdown-item{
        padding: 0.25rem 0.5rem;
        .nav-link{
            padding: 0.25rem 0.5rem;
        }
    }
}
@media(max-width: 375px){
    .tablet.dropdown{
        padding: 5px 30px;
    }
    
}
@media screen and (max-height: 600px) {
    .mobile-nav-item{
        padding: 5px 41px;
        border-bottom: 1px solid #e9ebf0;
    }
    .small-login-block {
        .first-button{
            padding: 7px 0;
        }
        .second-button{
            padding: 7px 0;
        }    
    }
    
}
.selected-black:hover{
    color: black !important;
    font-weight: 600;
}
@media(max-width: 767px){
	.navbar-brand{
		margin-right: 0px !important;
	}
}
.second-link:hover{
    .Case-studies-block{
        display: block !important;
        @media(min-width: 992px){
            position: absolute;
            left: 110px;
            top: -30px;
            z-index: 2;
        }
    }
}
.dropdown-toggle::after{
    vertical-align: 0.1em;
}