.Sector{
    img{
        max-width: 100%;
    }
    @media(max-width: 767.98px) {
        .top-panel {
            height: 72px;
            .title{
                font-size: 24px;
                line-height: 1;  
            }
        }
    }
}