.About{ 
    .icons{
        height: 33px !important;
        width: 33px !important;
        margin: 4px;
        color: orange !important;
    }
    .about-info{
        background-color: #ffffff;
        .text-block{
            margin-top: 129px;
            margin-bottom: 60px;
            .title{
                font-family: Archivo;
                font-size: 34px;
                font-weight: 500;
                line-height: 1.18;
                letter-spacing: normal;
                margin-bottom: 22px;
            }
            .description{
                font-size: 16px;
                line-height: 2;
                width: 449px;
            }    
        }
        img{
            margin-bottom: 60px;
            margin-top: 49px;
            height: 320px;
            width: 503px;
            object-fit: cover;
            border-radius: 4px;
        } 
    }
    .team-block{
        margin-top: 80px;
        .team-highlights{
            font-family: Archivo;
            font-size: 12px;
            font-weight: 600;
            line-height: 2;
            color: #ae61f9;
            border-radius: 2px;
            background-color: #ae61f91c;
            height: 24px;
            border-radius: 4px;
            border: 1px solid #ae61f91c;
            padding: 0 10px;
        }
        .title{
            font-family: Archivo;
            font-size: 34px;
            font-weight: 600;
            line-height: 1.41;
            text-align: center;
            margin-top: 8px;
            margin-bottom: 79px;
        }
    }
    .contacts-block{
        width: 353px;
        margin-top: 80px;
        .title{
            font-family: Archivo;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.71;
            color: #414f70;
            margin-bottom: 17px;
        }
        .information{
            opacity: 0.99;
            font-size: 14px;
            line-height: 1.71;
            color: #263049;
            svg{
                height: 18px;
                width: 18px;
            }
            .email{
                margin-top: 10px;
                margin-bottom: 10px;
                svg{
                    color: #08c0c3;
                    margin: auto 15px auto 0;
                }
            }
            .mobile{
                margin-top: 10px;
                margin-bottom: 10px;
                svg{
                    color: #62d31a;
                    margin: auto 15px auto 0;
                }
            }
            .location{
                margin-top: 10px;
                svg{
                    color: #efa736;
                    margin: auto 15px auto 0;
                }
            }
        }
        hr{
            margin:31px 0;
        }
    }
    .map-block{
        height: 320px;
        width: 503px;
        margin-top: 60px;
        margin-bottom: 40px;
    }
    .white-background{
        background-color: white;
    }
    @media(max-width: 767px){
        .about-info{
            margin-top: 40px;
            margin-bottom: 40px;
            .text-block{
                margin-top: 40px;
                .title{
                    text-align: center;
                }
                .description{
                    width: 100%;
                }
            }
            img{
                margin-bottom: 50px;
                width: 100%;
                height: 220px;
            }
        }
        .team-block{
            margin-top: 40px;
            .title{
                margin-bottom: 40px;
                font-size: 30px;
            }
        }
        .contacts-block{
            margin-top: 30px;
        }
    }
}
