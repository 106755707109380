.TOS-Modal-signup{
    height: 100%;
    .confirm-title{
        font-size: 1.25rem;
        font-weight: 600;
    }
    .text{
        margin-bottom: 0.5rem;
        max-width: 500px;
    }
    .checkbox-label{
        font-size: 18px;
        .confirm-input{
            height: 16px;
            width: 16px;
            margin: auto auto auto 10px;
        }
        @media(max-width: 767px){
            .confirm-input{
                height: 24px;
                width: 24px;
            }
        }
    }
    .terms-description{
        height: 70%;
        .row{
            height: 100%;
            .col{
                height: 100%;
                .tos{
                    z-index: 999;
                    height: 100%;
                    overflow-y:auto;
                    border: 1px solid #e2e2ee;
                    border-radius:6px;
                    background-color: #ffffff;
                }
            }

        }
    }
    .agreement{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 31px;
        @media(max-width: 767px){
            height: unset;
        }
        button{
            margin-left:1em;
            outline:none;
            cursor: pointer;
            background-color: #1b7beb;
            color: white;
            border-radius: 4px;
            padding: 8px 24px;
            border: none;
            &.negative{
                background-color: red; 
            }
        }
        button:disabled {
            opacity: .7;
        }
        .disabled{
            opacity: .7;
            background-color: grey;
        }
    }

    .form-group {
        label {
            margin-left: 0px;
            margin-top: 5px;
            padding: 0 0.75em;
        }
    }

    .tos-sign {
        label.header {
            width: 100%;
            padding: 5px;
            color: white;
            background-color: #1b7beb;
        }
    }

    input {
        background-color: white !important;
        border-radius: 0;
        border-top: none;
        border-left: none;
        border-right: none;
    }
    
}
.tooltip-box{

    .arrow::before{
        border-top-color: #dee2e6 !important;
    }
}
.tooltip-inner-box{
    background-color: #dee2e6 !important;
    color: black;
}
