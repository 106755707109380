.popoverAdvanced{
    top: 0px !important;
    left: 15px !important;
}
.AdvancedFilters{
    position: relative;
    .filter-entry{
        margin: 0px;
    }
    @media(max-width: 991px){
    }
    .w-150{
        width: 150px;
    }
    .margin-r-20{
        margin-left: auto;
        margin-right: 20px;
    }
    .advanced-form{
        z-index: 900;
        background-color: white;
        padding: 14px 0px;
    }
    .margin-r-8{
        margin-right: 8px;
        margin-top: 6px;
        margin-bottom: 6px;
    }
    .bigger-padding{
        select{
            padding: 0.375rem 1.75rem 0.375rem 0.75rem;
        }
    }
    .opened-filter{
        overflow:hidden;
        transition:max-height 0.9s ease-out; // note that we're transitioning max-height, not height!
        height:auto;
        max-height:600px;
    }
    .closed-filter{
        
        .select{
            display: none;
        }
        .control-filters{
            display: none;
        }
        max-height:0;
        div{
            max-height: 0px;
        }
        .row{
            max-height: 0px;
            button{
                display: none;
            }
            .col-2{
                display: none;
            }
        }
    }
    .position-absolute{
        position: absolute;
    }
}