.Sector{
    .MarketResearchReports{
        margin-top: 32px;
        .description{
            background-color: white;
            border-left: solid 1px #e2e6ee;
            border-right: solid 1px #e2e6ee;
            border-bottom: solid 1px #e2e6ee;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            padding: 24px 24px 0px 24px;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.71;
            letter-spacing: normal;
            color: #3f4d6e;
            img{
                width: 100%
            }
            .text-block{
                width: 80%;
                .text-title{
                    padding-top: 24px;
                    padding-bottom: 7px;
                    font-family: Archivo;
                    font-size: 16px;
                    font-weight: 600;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.78;
                    letter-spacing: normal;
                    color: #263049;
                }
                .text-description{
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.71;
                    letter-spacing: normal;
                    color: #3f4d6e;
                    padding-bottom: 26px;
                }
            }
        }
    }
}